import React, { useEffect, useState } from "react";
import { Col, Row, Container, Form } from "react-bootstrap";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Dialog, DialogActions, DialogContent, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SubModalGeneral from "./SubModalGeneral";

const ModalUploadDocs = ({
  message,
  agreeText,
  disagreeText,
  handleAgree,
  onClose,
  open,
  documentData,
  setDocumentData,
}) => {
  const [uploadDoc, setUploadDoc] = useState({});

  const schema = yup.object().shape({
    file: yup
      .mixed()
      .test("Required", "Debe subir un documento", (value) => value[0])
      .test(
        "fileSize",
        "Debe ser menor a 4 MB",
        (value) => value?.[0] && value[0].size <= 4000000
      )
      .test(
        "fileFormat",
        "Formato incorrecto",
        (value) =>
          value[0] &&
          (value[0].type === "application/pdf" ||
            value[0].type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    onClose();
  };
  const handleUploadDocs = (data) => {
    setDocumentData(data.file[0]);
    onClose();
  };

  useEffect(() => {
    !documentData.name && setUploadDoc({});
  }, [documentData]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      open={open}
      aria-labelledby="customized-dialog-title"
    >
      <SubModalGeneral onClose={handleClose}>
        <label className="heading__primary-color">
          Selecciona documento para subir
        </label>
      </SubModalGeneral>
      <Form onSubmit={handleSubmit(handleUploadDocs)}>
        <DialogContent className="caption" dividers style={{ height: "30rem" }}>
          <Form.Group
            controlId="formFile"
            className="mb-3"
            style={{ height: "70%", flexDirection: "column" }}
          >
            <div className="inputFileModificado ">
              <Container className="inputParaMostrar">
                <Row
                  className="justify-content-md-center"
                  style={{ height: "40%", alignContent: "flex-end" }}
                >
                  <Col md={3}>
                    {uploadDoc?.type ? (
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 50 }}
                        className="iconUpload"
                      />
                    ) : (
                      <ArrowCircleUpIcon
                        sx={{ fontSize: 50 }}
                        className="iconUpload"
                      />
                    )}
                  </Col>
                </Row>
                <Row
                  className="justify-content-md-center"
                  style={{ height: "30%" }}
                >
                  <Col md={10} className="mt-1">
                    <Button
                      variant="text"
                      className="custom-input__button__secondary-color"
                    >
                      {uploadDoc?.name
                        ? "Documento cargado"
                        : "Agregar documento"}
                    </Button>
                  </Col>
                </Row>
                <Row
                  className="justify-content-md-center"
                  style={{ height: "20%" }}
                >
                  {uploadDoc?.name || "o arrastra el documento para cargar"}
                </Row>
              </Container>

              <Form.Control
                {...register("file")}
                type="file"
                bsPrefix={
                  "input-group-container__no-icon label inputFileOculto"
                }
                onChange={(e) => {
                  setUploadDoc(e.target.files[0]);
                }}
              />
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.file?.message}
              </div>
            </div>
            <p
              className="caption"
              style={{
                padding: "3% 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {message}
            </p>
          </Form.Group>
        </DialogContent>
        <DialogActions>
          <Row>
            <Col>
              <Button
                variant="text"
                className="custom-input__button__secondary-color__forced"
                onClick={() => {
                  setUploadDoc({});
                  onClose();
                }}
              >
                {disagreeText}
              </Button>
            </Col>
            <Col>
              <Button
                variant="contained"
                type="submit"
                className="custom-input__button__primary-color__forced"
              >
                {agreeText}
              </Button>
            </Col>
          </Row>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ModalUploadDocs;
