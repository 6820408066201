import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { saveAs } from "file-saver";
import { MDBDataTableV5 } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import DiscussionBoard from "../../components/ExternalRequest/DiscussionBoard";
import LoadingContent from "../../components/LoadingContent";
import ModalInfo from "../../components/Modals/ModalInfo";
import SectionTitle from "../../components/sectionTitle";
import { CustomToolTip } from "../../components/Tooltip/Tooltip";
import Breadcrum from "../../pages/parts/breadcrum";
import {
  setStatusRequest,
  setUpdateOneRequest,
} from "../../reducers/requestSlice";
import { getCompanyPlan } from "../../reducers/storageSlice";
import { dateNormal } from "../../resources/getDate";
import { getFileDocument } from "../../services/Approvals/ApprovalServices";
import {
  ChangeStateCarvajal,
  findOneDataExternalRequest,
  SendfInfoToLawyerCarvajal,
} from "../../services/CarvServices/CarvajalServices";
import { ChangeState } from "../../services/ExternalUsers/ExternalRequest";
import { CODES } from "../../utils/codes";
import { Mixpanel } from "../../utils/mixPanel";
import {
  handleStyleStatusRequest,
  STATUS_REQUESTS,
} from "../../utils/statusExtenalRequest";
import { Button } from "@mui/material";
import { checkCERLRequest } from "../../services/parts/partsServices";
export const validateCompleteSigns = (data, document) => {
  const info = data.find((sign) => sign._id === document.signature);
  if (!info) return false;
  const totalSigns = info.signatoriesInfo.length;
  const totalOkSigns = info.signatoriesInfo.filter(
    (sign) => sign.hasSigned
  ).length;
  return totalOkSigns === totalSigns;
};

const ViewRequestStandard = () => {
  const { consecutive } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isHideDiscussion, setIsHideDiscussion] = useState(true);
  const [
    showModalUploadDocumentPrincipal,
    setShowModalUploadDocumentPrincipal,
  ] = useState(false);
  const companyPlan = useSelector(getCompanyPlan);
  const [responseDataError, setResponseDataError] = useState({});
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [fileSelectedPrincipal, setFileSelectedPrincipal] = useState();
  const [isHideBtnUpDocPrincipal, setIsHideBtnUpDocPrincipal] = useState(false);
  const [isNewComment, setIsNewComment] = useState(false);
  const [showModalCancelRequest, setShowModalCancelRequest] = useState(false);
  const [isLoadingCancelRequest, setIsLoadingCancelRequest] = useState(false);
  const [showModalRequestResend, setShowModalRequestResend] = useState(false);
  const [isLoadingRequestResend, setIsLoadingRequestResend] = useState(false);
  const [requiredDocumentsTable, setRequiredDocumentsTable] = useState({});
  const [principalDocComment, setPrincipalDocComment] = useState("");
  const [disableSign, setDisableSign] = useState(false);
  const [disableUpload, setDisableUpload] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [disableStorage, setDisableStorage] = useState(false);
  const dispatch = useDispatch();
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const [dataRequest, setDataRequest] = useState({
    _id: "",
    stateRequest: "",
    color: "",
    applicant: "",
    attorneyInCharge: "",
    requestSubject: "",
    requestType: "",
    requestSubType: "",
    responseLimitDate: "",
    requestDetails: [],
    documentsAttachments: [],
    documentsOtherAttorney: [],
    documentsOtherApplicant: [],
    documentPrincipal: [],
    comments: [],
  });
  const [signPermission, setSignPermission] = useState(false);

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState({});

  //Variables
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Seguimiento
        </label>,
      ],
      field: "folloup",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Descripción
        </label>,
      ],
      field: "description",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Creado por
        </label>,
      ],
      field: "createdBy",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Dirigido a
        </label>,
      ],
      field: "assignedTo",
    },
    {
      label: [
        <label aria-hidden="true" key="5">
          Fecha de creación
        </label>,
      ],
      field: "createdAt",
    },
    {
      label: [
        <label aria-hidden="true" key="6">
          Documento Adjunto
        </label>,
      ],
      field: "originalFilename",
    },
  ];
  const columnsDocuments = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Documento a subir
        </label>,
      ],
      field: "document",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Relevancia
        </label>,
      ],
      field: "required",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Archivo
        </label>,
      ],
      field: "file",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];
  const MAX_SIZE_FILE = 78643200;
  const SIZE_BYTE_MEGABYTE = 1048576;
  const principalDocSchema = yup.object().shape({
    comment: yup.string().required("*Este campo es obligatorio"),
    file: yup
      .mixed()
      .required("*Este campo es obligatorio")
      .test("fileName", "*Este campo es obligatorio", (value) => {
        return value?.[0]?.name;
      })
      .test(
        "fileSize",
        `Máximo ${
          MAX_SIZE_FILE / SIZE_BYTE_MEGABYTE
        }MB por archivo, inténtalo de nuevo`,
        (value) => value?.[0] && value[0].size <= MAX_SIZE_FILE
      )
      .test(
        "fileFormat",
        "Formato incorrecto",
        (value) =>
          value[0] &&
          (value[0].type === "application/pdf" ||
            value[0].type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      ),
  });
  const {
    register: registerUploadDocuments,
    handleSubmit: handleSubmitUploadDocuments,
    formState: { errors: errorsUploadDocuments },
    control: controlUploadDocuments,
  } = useForm({
    resolver: yupResolver(principalDocSchema),
  });
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: "otherDocuments",
  });
  const handleClose = () => {
    setShowModalCancelRequest(false);
    setIsLoadingCancelRequest(false);
    setShowModalRequestResend(false);
    setShowModalUploadDocumentPrincipal(false);
  };

  const handleClickDownloadNoExtension = async (document) => {
    try {
      setIsLoading(true);
      const file = await getFileDocument(
        document.filenameInBucket,
        document.bucketName,
        false
      );
      const bf = file.data.responseMessage.buffer.data;
      const blob = new Blob([new Uint8Array(bf).buffer]);

      let fileName = file.data.responseMessage.filename;
      
      const extension = fileName.split(".").pop().toLowerCase();

      if (!["docx","doc","docm","pdf"].includes(extension) || fileName.split(".").length <= 1) {

        const contentType = file.data.responseMessage.contentType;

        const WORD_TYPES = ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
        const isWord = WORD_TYPES.includes(contentType);
        const isPdf = ["application/pdf"].includes(contentType);
        const isExcel = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(contentType);

        if (isWord) fileName += ".docx";
        if (isExcel) fileName += ".xlsx";
        if (isPdf) fileName += ".pdf";

      }
      
      saveAs(blob, fileName);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleCancelRequestConfirm = async () => {
    try {
      setIsLoading(true);
      setShowModalCancelRequest(false);
      let obj;
      obj = {
        requestId: dataRequest._id,
        state: "Cancelada",
        isActive: false,
      };
      const changeStateRequest = await ChangeState(obj);
      if (changeStateRequest.status) {
        if (changeStateRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
          navigate("/external/home");
          dispatch(setStatusRequest({ status: "fetch" }));
        } else {
          setResponseDataError(changeStateRequest);
          setIsOpenModalError(true);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSendToLawyerState = async () => {
    setIsLoadingCancelRequest(true);
    try {
      let obj;
      obj = {
        requestId: dataRequest._id,
        state: "Cancelada",
        message: "Solicitud cancelada por el usuario.",
      };
      const changeStateRequest = await ChangeStateCarvajal(obj);
      if (changeStateRequest.status) {
        if (changeStateRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
          setIsLoadingCancelRequest(true);
          setShowModalCancelRequest(false);
          navigate("/external/home");
        } else {
          setResponseDataError(changeStateRequest);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingCancelRequest(false);
    }
  };
  const handleUploadDocument = (data) => {
    setShowModalUploadDocumentPrincipal(false);
    setPrincipalDocComment(data.comment);
  };
  const buildMessageDocuments = ({
    requiredDocuments,
    mainDocuments,
    otherApplicantDocs,
  }) => {
    const documents = [];
    if (otherApplicantDocs.length > 0) {
      documents.push(...otherApplicantDocs);
    }
    if (mainDocuments) {
      documents.push(mainDocuments);
    }
    if (requiredDocuments?.length > 0) {
      return `Se han modificado los documentos: ${requiredDocuments[0].file.name} que estaban cargados previamente en la solicitud.`;
    }
    if (!documents.length) return "";
    return `${
      documents.length > 1
        ? "Se han cargado los documentos: "
        : "Se ha cargado el documento: "
    } ${[...documents].map((doc) => doc.name).join(", ")}`;
  };

  const handleReloadDocuments = async (data) => {
    try {
      setIsLoading(true);

      Mixpanel.track("Solicitante Btn Enviar abogado", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      const companyId = localStorage.getItem("company");
      const corporateUnit = JSON.parse(
        localStorage.getItem("corporateUnit")
      )._id;

      const { userId } = payloadToken;
      let otherApplicantDocs = [];
      const arrayOthersDocs = data?.otherDocuments || [];
      for (const file of arrayOthersDocs) {
        if (file?.[0]) {
          otherApplicantDocs = [...otherApplicantDocs, file[0]];
        }
      }
      let requiredDocumentsObj = [];
      const arrayRequiredDocs = data?.requiredDocs || [];
      for (const doc of arrayRequiredDocs) {
        if (doc?.file && doc?.file[0]) {
          requiredDocumentsObj = [
            ...requiredDocumentsObj,
            { name: doc.name, file: doc.file[0] },
          ];
        }
      }
      const obje = {
        applicantId: userId,
        consecutive: consecutive,
        comment: principalDocComment,
        attornetName: dataRequest.attorneyInCharge,
        applicantName: dataRequest.applicant,
        applicantDocuments: otherApplicantDocs,
        requiredDocuments: requiredDocumentsObj,
        mainDocuments: fileSelectedPrincipal,
        companyId,
        corporateUnit,
      };

      const cerlFile = requiredDocumentsObj?.find(
        (document) =>
          document.name ===
          "Certificado de existencia y representacion de la otra parte o su equivalente"
      );

      if (cerlFile?.name) {
        const companyId = localStorage.getItem("company");

        const checkObject = {
          file: cerlFile.file,
          associatedCorporateUnits: [corporateUnit],
          company: companyId,
        };
        const serviceValidation = await checkCERLRequest(checkObject);
        if (serviceValidation.status) {
          if (serviceValidation.status === CODES.COD_RESPONSE_HTTP_OK) {
            const service = await SendfInfoToLawyerCarvajal(obje);
            if (service?.status === CODES.COD_RESPONSE_HTTP_OK) {
              let obj;
              const formatedMessage = buildMessageDocuments({
                otherApplicantDocs,
                mainDocuments: obje?.mainDocuments || null,
                requiredDocuments: obje?.requiredDocuments || [],
              });
              obj = {
                requestId: dataRequest._id,
                state: STATUS_REQUESTS.REVIEW_ATTORNEY.name,
                message: formatedMessage,
              };
              const serviceChangeState = await ChangeStateCarvajal(obj);
              if (serviceChangeState?.status === CODES.COD_RESPONSE_HTTP_OK) {
                const data = serviceChangeState.data.responseMessage;
                dispatch(
                  setUpdateOneRequest({
                    id: data?._id,
                    requestData: {
                      ...data,
                      createdAt: moment(data?.createdAt).format("YYYY/MM/DD"),
                      responseLimitDate: moment(data?.responseLimitDate).format(
                        "YYYY/MM/DD"
                      ),
                    },
                  })
                );
                navigate("/external/home");
                return;
              }
              setResponseDataError(serviceChangeState);
              setIsOpenModalError(true);
            } else if (
              service.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
              service.data.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
            ) {
              setResponseDataUnauthorized(service);
              setIsOpenModalUnauthorized(true);
            }
          } else {
            setResponseDataError(serviceValidation);
            setIsOpenModalError(true);
            dispatch(setStatusRequest({ status: "fetch" }));
          }
        }
      } else {
        const service = await SendfInfoToLawyerCarvajal(obje);
        if (service?.status === CODES.COD_RESPONSE_HTTP_OK) {
          let obj;
          const formatedMessage = buildMessageDocuments({
            otherApplicantDocs,
            mainDocuments: obje?.mainDocuments || null,
            requiredDocuments: obje?.requiredDocuments || [],
          });
          obj = {
            requestId: dataRequest._id,
            state: STATUS_REQUESTS.REVIEW_ATTORNEY.name,
            message: formatedMessage,
          };
          const serviceChangeState = await ChangeStateCarvajal(obj);
          if (serviceChangeState?.status === CODES.COD_RESPONSE_HTTP_OK) {
            const data = serviceChangeState.data.responseMessage;
            dispatch(
              setUpdateOneRequest({
                id: data?._id,
                requestData: {
                  ...data,
                  createdAt: moment(data?.createdAt).format("YYYY/MM/DD"),
                  responseLimitDate: moment(data?.responseLimitDate).format(
                    "YYYY/MM/DD"
                  ),
                },
              })
            );
            navigate("/external/home");
            return;
          }
          setResponseDataError(serviceChangeState);
          setIsOpenModalError(true);
        } else if (
          service.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
          service.data.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
        ) {
          setResponseDataUnauthorized(service);
          setIsOpenModalUnauthorized(true);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setIsLoadingRequestResend(false);
      setIsLoading(false);
    }
  };
  const handleClickUploadOtherDocument = () => {
    append({});
    Mixpanel.track("Solicitante Btn Cargar Otro Documento", {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  };
  const handleDeleteOtherDocument = (index) => {
    remove(index);
  };
  const handleUploadDocumentPrincipal = () => {
    Mixpanel.track("Solicitante Btn Cargar Documento", {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
    setShowModalUploadDocumentPrincipal(true);
  };
  const handleBack = () => {
    navigate("/external/home");
  };
  const handleShowModalCancelRequest = () => {
    setShowModalCancelRequest(true);
    Mixpanel.track("Solicitante Btn Cancelar Solicitud", {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  };

  const getTitle = (item) => {
    if (item?.isSignatureFinish) return item?.tracking || "";
    if (item?.isApprovedWithComments)
      return " Documento aprobado con comentarios";
    if (item?.userRole === "EXTERNAL") return " Respuesta Solicitante";
    if (item?.userRole === "SERVICE") return " Respuesta Abogado";
    return " Documento devuelto con comentarios";
  };

  const isAllowToUpdateOtherDocuments = () => {
    if (!dataRequest?.requestSubType?.originalFormId) return true;
    return dataRequest?.requestSubType?.allowUpdateOtherDocumentsToTrace;
  }

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { company, userId } = JSON.parse(
        localStorage.getItem("payloadToken")
      );
      const { _id } = JSON.parse(localStorage.getItem("corporateUnit"));
      const obj = {
        consecutive: consecutive,
        company: company,
        corporateUnitId: _id,
        userId: userId,
      };
      const GetDataRequest = await findOneDataExternalRequest(obj);
      if (GetDataRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (GetDataRequest.data.success) {
          const data = GetDataRequest.data.responseMessage;
          //Actualiza la información de la solicitud en el estado.
          dispatch(setUpdateOneRequest({ id: data?._id, requestData: data }));
          const moduleSign = data?.applicant?.permissions?.find(
            ({ module }) => module === "Requests"
          );
          const subModuleSign = moduleSign?.subModules?.find(
            ({ module }) => module === "Signatures"
          );
          const documentsPrincipal = data?.documents?.mainDocuments.filter(
            (doc) =>
              !(
                !doc.isRejected &&
                !validateCompleteSigns(data.signatures, doc) &&
                doc.subtype === "Signature"
              )
          );

          setSignPermission(subModuleSign?.actions?.requestSignatures || false);
          const dataFilter = {
            _id: data?._id,
            stateRequest: data?.requestStatus?.name,
            color: handleStyleStatusRequest(data?.requestStatus?.colorId),
            applicant: `${data?.applicant?.firstName || ""} ${
              data?.applicant?.secondName || ""
            } ${data?.applicant?.firstSurname || ""} ${
              data?.applicant?.secondSurname || ""
            }`,
            attorneyInCharge: `${data?.attorneyInCharge?.firstName || ""} ${
              data?.attorneyInCharge?.secondName || ""
            } ${data?.attorneyInCharge?.firstSurname || ""} ${
              data?.attorneyInCharge?.secondSurname || ""
            }`,
            requestSubject: data?.requestSubject,
            requestType: data?.requestType,
            requestSubType: data?.requestSubtype,
            requestTypeRedaction: data?.requestTypeRedaction,
            responseLimitDate: data?.responseLimitDate,
            requestDetails: data?.requestDetails,
            documentsOtherAttorney: data?.documents?.attorneyDocuments,
            documentsAttachments: data?.documents?.requiredDocuments,
            documentsOtherApplicant: data?.documents?.applicantDocuments,
            applicantCompany: data?.applicantCompany,
            isRequestActive: data?.isActive,
            documentsAtTable: data?.documents?.requiredDocuments.concat(
              data?.documents?.applicantDocuments
            ), //Documentos requeridos y documentos del solicitante que se mapean en la tabla
            documentPrincipal: documentsPrincipal.map((item, index) => {
              return {
                description: item.description,
                createdAt: dateNormal(item.createdAt),
                createdBy: item.createdBy,
                assignedTo: item.assignedTo,
                folloup: (
                  <label className="caption">{`#${index + 1} ${getTitle(
                    item
                  )}`}</label>
                ),
                originalFilename: (
                  <label
                    onClick={() => {
                      handleClickDownloadNoExtension(item);
                    }}
                    style={{
                      color: "#17B4BC",
                      cursor: "pointer",
                      fontSize: "1.5rem",
                      borderRadius: "0.8rem",
                      padding: "0.4rem",
                    }}
                  >
                    {item.originalFilename}
                  </label>
                ),
              };
            }),
            comments: data?.comments,
          };
          setDataRequest(dataFilter);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [isNewComment]);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isSignatureEnable) {
        setDisableSign(true);
      }
      if (!companyPlan.isStorageEnable) {
        setDisableUpload(true);
      }
    }
  }, [companyPlan]);

  const buildRowsDocuments = (data) => {
    if (!data?.length) return [];
    return data.map((item, index) => ({
      document: (
        <Form.Label
          {...register(`requiredDocs.${index}.name`, {
            value: item.originalFilename,
          })}
        >
          {item.originalFilename}
        </Form.Label>
      ),
      required: item.isRequired ? "Obligatorio*" : "Opcional",
      file: item.originalFilename
        ? fileNames[index] || "Documento cargado"
        : "---",
      actions:
        item.type === "requiredDocuments" ? (
          <>
            <Form.Control
              id={`requiredDocs.${index}.file`}
              className="input-upload"
              {...register(`requiredDocs.${index}.file`)}
              onChange={(e) => {
                if (e.target.files[0]) {
                  const newArray = [...fileNames];
                  newArray[index] = e.target.files[0].name;
                  setFileNames(newArray);
                } else {
                  const newArray = [...fileNames];
                  newArray[index] = "";
                  setFileNames(newArray);
                }
              }}
              type="file"
            />
            <Form.Label
              className="input-upload__button_primary_color"
              onClick={() => handleClickDownloadNoExtension(item)}
              style={{ marginRight: "1rem" }}
            >
              <DownloadIcon fontSize="large" />
            </Form.Label>
            <Form.Label
              className={"input-upload__button"}
              htmlFor={`requiredDocs.${index}.file`}
            >
              <CloudUploadOutlinedIcon fontSize="large" />
              {" Cargar"}
            </Form.Label>
          </>
        ) : (
          <>
            <Form.Label
              className="input-upload__button_primary_color"
              onClick={() => handleClickDownloadNoExtension(item)}
              style={{ marginRight: "1rem" }}
            >
              <DownloadIcon fontSize="large" />
            </Form.Label>
          </>
        ),
    }));
  };

  useEffect(() => {
    if (dataRequest?.documentsAttachments?.length > 0) {
      const { documentsAtTable } = dataRequest;
      setRequiredDocumentsTable({
        columns: columnsDocuments,
        rows: buildRowsDocuments(documentsAtTable),
      });
    }
  }, [fileNames, dataRequest]);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setDisableStorage(true);
      }
    }
  }, [companyPlan]);

  const handleDownloadRecentDocument = () => {
    const file = fileSelectedPrincipal;
    if (file) {
      const blob = new Blob([file]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    }
  };
  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      {/* Headers */}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Solicitud"} />
        </Col>
        <Col>
          <Row style={{ justifyContent: "end" }}>
            <Col xs={"auto"}>
              <button
                className="btn_white_background"
                onClick={() => setIsHideDiscussion(false)}
                style={{
                  alignItems: "center",
                  marginRight: "1rem",
                  padding: "0.3rem",
                }}
              >
                <ArticleOutlinedIcon fontSize="large" />
                Mensajes
              </button>
            </Col>
            <Col xs={"auto"}>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#17B4BC",
                  alignItems: "center",
                  marginRight: "1rem",
                  padding: "0.3rem",
                }}
              >
                <NotificationsNoneOutlinedIcon fontSize="large" />
                {dataRequest.comments ? dataRequest.comments.length : 0}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Body */}
      <br />
      <div>
        <Row
          style={{
            color: "#00374F",
          }}
        >
          <h1 className="label__title">Detalles de la solicitud</h1>
        </Row>
        <Row style={{ alignItems: "center", marginLeft: "1rem" }}>
          <Col xs={2}>
            <h2 className="label__subtitle">Estado de la solicitud: </h2>
          </Col>
          <Col xs={"auto"}>
            <h2
              style={{
                backgroundColor: dataRequest.color,
                fontSize: "1.5rem",
                borderRadius: "0.8rem",
                padding: "0.4rem",
              }}
              className="label__description"
            >
              {dataRequest.stateRequest}
            </h2>
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginLeft: "1rem" }}>
          <Col xs={2}>
            <h2 className="label__subtitle">Solicitante: </h2>
          </Col>
          <Col xs={"auto"}>
            <h2 className="label__description">{dataRequest.applicant}</h2>
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginLeft: "1rem" }}>
          <Col xs={2}>
            <h2 className="label__subtitle">Usuario de servicios: </h2>
          </Col>
          <Col xs={"auto"}>
            <h2 className="label__description">
              {dataRequest.attorneyInCharge}
            </h2>
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginLeft: "1rem" }}>
          <Col xs={2}>
            <h2 className="label__subtitle">Empresa: </h2>
          </Col>
          <Col xs={"auto"}>
            <h2 className="label__description">
              {dataRequest.applicantCompany}
            </h2>
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginLeft: "1rem" }}>
          <Col xs={2}>
            <h2 className="label__subtitle">Asunto de la solicitud: </h2>
          </Col>
          <Col xs={"auto"}>
            <h2 className="label__description">{dataRequest.requestSubject}</h2>
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginLeft: "1rem" }}>
          <Col xs={2}>
            <h2 className="label__subtitle">Tipo de solicitud: </h2>
          </Col>
          <Col xs={"auto"}>
            <h2 className="label__description">
              {dataRequest?.requestTypeRedaction ?? "No aplica"}
            </h2>
          </Col>
        </Row>
        {dataRequest?.requestTypeRedaction?.toLowerCase() !==
          "consulta jurídica" && (
          <>
            <Row style={{ alignItems: "center", marginLeft: "1rem" }}>
              <Col xs={2}>
                <h2 className="label__subtitle">Tipo de documento: </h2>
              </Col>
              <Col xs={"auto"}>
                <h2 className="label__description">
                  {dataRequest?.requestType?.group ?? "No aplica"}
                </h2>
              </Col>
            </Row>
            {dataRequest?.requestType?.group?.toLowerCase() !== "otrosí" &&
              dataRequest.requestSubType !== "" && (
                <Row style={{ alignItems: "center", marginLeft: "1rem" }}>
                  <Col xs={2}>
                    <h2 className="label__subtitle">Subtipo de documento: </h2>
                  </Col>
                  <Col xs={"auto"}>
                    <h2 className="label__description">
                      {dataRequest?.requestSubType?.name ?? "No aplica"}
                    </h2>
                  </Col>
                </Row>
              )}
          </>
        )}
        <Row style={{ alignItems: "center", marginLeft: "1rem" }}>
          <Col xs={2}>
            <h2 className="label__subtitle">Fecha límite de respuesta: </h2>
          </Col>
          <Col xs={"auto"}>
            <h2 className="label__description">
              {dataRequest?.responseLimitDate || "No aplica"}
            </h2>
          </Col>
        </Row>
        <br />
        <Form onSubmit={handleSubmit(handleReloadDocuments)}>
          {/* Información de la Solicitud */}
          <Row
            style={{
              color: "#00374F",
            }}
          >
            <h1 className="label__title">Información para la solicitud</h1>
          </Row>
          <br />
          <br />
          <Row style={{ marginLeft: "1rem" }}>
            {dataRequest?.requestDetails?.map((item) => {
              return (
                <Row key={item.question}>
                  {item.answer != undefined && item.answer !== "" && (
                    <>
                      <h2 className="label__subtitle">{item.question}</h2>
                      <h2 className="label__description">{item.answer}</h2>
                    </>
                  )}
                </Row>
              );
            })}
          </Row>
          <br />
          {/* Tabla de documentos requeridos y opcionales */}
          {dataRequest?.documentsAttachments?.length > 0 && (
            <Row>
              <Row style={{ color: "#00374F" }}>
                <h1 className="label__title">Documentos anexos</h1>
              </Row>
              <Row className="data-table dt-list-items">
                <MDBDataTableV5
                  hover
                  pagingTop
                  searchBottom={false}
                  entries={6}
                  data={requiredDocumentsTable}
                  entriesLabel=""
                  infoLabel={["Mostrando", "a", "de", "solicitudes"]}
                  fullPagination
                  noRecordsFoundLabel="No se encontraron resultados"
                />
              </Row>
            </Row>
          )}
          {/* Otros documentos cargados por el solicitante */}
          <Row>
            <Row style={{ color: "#00374F" }}>
              <h1 className="label__title">Cargar un documento adicional</h1>
            </Row>
            <br />
            <Col>
              {dataRequest?.documentsOtherApplicant?.length ? (
                dataRequest.documentsOtherApplicant.map((item) => {
                  return (
                    <Row
                      key={item?.originalFilename}
                      style={{
                        color: "#00374F",
                        height: "max-content",
                        width: "40%",
                        margin: "1rem 0rem",
                        boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                        borderRadius: "8px",
                      }}
                    >
                      <Col style={{ margin: "2rem 1.5rem" }} md={7}>
                        <Row className="label__title">
                          {item.originalFilename}
                        </Row>
                      </Col>
                      <Col style={{ margin: "1.5rem" }}>
                        <Row style={{ justifyContent: "end" }}>
                          <Col
                            md={3}
                            className="col_width"
                            style={{ alignSelf: "end" }}
                          >
                            <label
                              onClick={() => {
                                handleClickDownloadNoExtension(item);
                              }}
                              className="btn_blue_background"
                              style={{
                                backgroundColor: "#00374F",
                                marginRight: "1rem",
                                padding: "7px",
                                cursor: "pointer",
                              }}
                            >
                              <DownloadIcon fontSize="large" />
                            </label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <label className="label__description">
                  El solicitante no ha cargado otros documentos todavía
                </label>
              )}
            </Col>
            <Row>
              {fields.map((item, index) => {
                const file =
                  watch(`otherDocuments.${index}`) &&
                  watch(`otherDocuments.${index}`)[0];
                return (
                  <div key={item.id}>
                    <Row>
                      <Col xs={"auto"}>
                        <Form.Control
                          id={`otherDocuments.${index}`}
                          className="input-upload"
                          {...register(`otherDocuments.${index}`, {
                            validate: {
                              acceptedFormats: (value) => {
                                if (!value[0]) return "*Debe cargar un archivo";
                                const acceptedFormats = [
                                  ".pdf",
                                  ".docx",
                                  ".doc",
                                ];
                                const validFormat = acceptedFormats.some(
                                  (format) => value[0].name.endsWith(format)
                                );
                                if (!validFormat)
                                  return "*Formato de archivo no válido";
                              },
                              maxFileSize: (value) => {
                                if (!value[0]) return "*Debe cargar un archivo";
                                const isMaxFileSizeAllow =
                                  value[0].size <= MAX_SIZE_FILE;
                                if (!isMaxFileSizeAllow)
                                  return `*El archivo debe tener un tamaño máximo de ${
                                    MAX_SIZE_FILE / 1024 / 1024
                                  }MB`;
                              },
                            },
                          })}
                          accept=".pdf, .docx, .doc"
                          type="file"
                        />
                        <Form.Label
                          className={"input-upload__button_primary_color"}
                          htmlFor={`otherDocuments.${index}`}
                        >
                          <CloudUploadOutlinedIcon fontSize="large" />
                          {file ? ` ${file.name}` : " adjuntar documento"}
                        </Form.Label>
                        <Form.Label
                          className="input-upload__button"
                          onClick={() => handleDeleteOtherDocument(index)}
                          style={{ marginLeft: "1rem" }}
                        >
                          <DeleteIcon fontSize="large" />
                        </Form.Label>
                      </Col>

                      <span className="caption custom-input__error">
                        {errors?.otherDocuments?.length > 0 &&
                          errors?.otherDocuments[index] &&
                          errors?.otherDocuments[index]?.message}
                      </span>
                    </Row>
                    <br />
                  </div>
                );
              })}
            </Row>
            {dataRequest.stateRequest !== "Solucionada" &&
              dataRequest.stateRequest !== "Cancelada" && (
                <Col xs={"auto"}>
                  {disableUpload ? (
                    <CustomToolTip
                      direction="top"
                      message="La compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el usuario administrador"
                    >
                      <Button
                        type="button"
                        disabled
                        variant="contained"
                        startIcon={<AddIcon fontSize="large" />}
                        className="custom-input__button__primary-color__disabled"
                      >
                        Cargar otro documento
                      </Button>
                    </CustomToolTip>
                  ) : (
                    <Button
                    disabled={!isAllowToUpdateOtherDocuments()}
                      type="button"
                      variant="contained"
                      startIcon={<AddIcon fontSize="large" />}
                      className={
                        isAllowToUpdateOtherDocuments()
                          ? "custom-input__button__secondary-color"
                          : "btn_disable"
                      }
                      onClick={handleClickUploadOtherDocument}
                    >
                      Cargar otro documento
                    </Button>
                  )}
                </Col>
              )}
          </Row>
          {/* Otros documentos cargados por el abogado - Documento Solicitado*/}
          {dataRequest.isRequestActive && (
            <>
              <hr />
              {/* Otros documentos cargados por el abogado*/}
              <Row>
                <Row style={{ color: "#00374F" }}>
                  <h1 className="label__title">
                    Otros documentos cargados por el abogado
                  </h1>
                </Row>
                <Col>
                  {dataRequest.documentsOtherAttorney.length ? (
                    dataRequest.documentsOtherAttorney.map((item, index) => (
                      <Row
                        key={item?.originalFilename}
                        style={{
                          color: "#00374F",
                          height: "max-content",
                          width: "40%",
                          margin: "1rem 0rem",
                          boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                          borderRadius: "8px",
                        }}
                      >
                        <Col style={{ margin: "2rem 1.5rem" }} md={7}>
                          <Row className="label__title">
                            {item.originalFilename}
                          </Row>
                        </Col>
                        <Col style={{ margin: "1.5rem" }}>
                          <Row style={{ justifyContent: "end" }}>
                            <Col
                              md={3}
                              className="col_width"
                              style={{ alignSelf: "end" }}
                            >
                              <label
                                onClick={() => {
                                  handleClickDownloadNoExtension(item);
                                }}
                                className="btn_blue_background"
                                style={{
                                  backgroundColor: "#00374F",
                                  marginRight: "1rem",
                                  padding: "7px",
                                  cursor: "pointer",
                                }}
                              >
                                <DownloadIcon fontSize="large" />
                              </label>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <label className="label__description">
                      El abogado no ha cargado otros documentos todavía
                    </label>
                  )}
                </Col>
              </Row>
              <hr />
              {/* Documento Solicitado*/}
              <Row>
                <Row style={{ color: "#00374F" }}>
                  <h1 className="label__title">Documento solicitado</h1>
                </Row>
                <Row>
                  <label className="label__description">
                    {fileSelectedPrincipal?.name ? (
                      <Col
                        md={5}
                        className="label__title"
                        style={{
                          color: "#00374F",
                          boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                          borderRadius: "8px",
                          padding: "2rem 1.5rem",
                        }}
                      >
                        {fileSelectedPrincipal?.name}
                      </Col>
                    ) : (
                      "Aún no se ha cargado una versión del documento principal"
                    )}
                  </label>
                </Row>
                {dataRequest.isRequestActive &&
                  dataRequest.stateRequest !== "Solucionada" && (
                    <Col xs={"auto"}>
                      {disableUpload ? (
                        <CustomToolTip
                          direction="top"
                          message="La compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el usuario administrador"
                        >
                          <Button
                            type="button"
                            disabled
                            variant="contained"
                            startIcon={<AddIcon fontSize="large" />}
                            className="custom-input__button__primary-color__disabled"
                          >
                            {dataRequest.requestType?.type === "Confidencialidad"
                              ? `Cargar contrato de ${dataRequest.requestType.type}`
                              : `Cargar documento solicitado`}
                          </Button>
                        </CustomToolTip>
                      ) : (
                        <Button
                          type="button"
                          variant="contained"
                          startIcon={<AddIcon fontSize="large" />}
                          className="custom-input__button__secondary-color"
                          onClick={handleUploadDocumentPrincipal}
                        >
                          {dataRequest.requestType?.type === "Confidencialidad"
                            ? `Cargar contrato de ${dataRequest.requestType.type}`
                            : `Cargar documento solicitado`}
                          {/* : `Cargar ${dataRequest.requestType.type}`} */}
                        </Button>
                      )}
                    </Col>
                  )}
                <Row>
                  <MDBDataTableV5
                    hover
                    pagingTop
                    searchBottom={false}
                    entries={6}
                    data={{
                      columns: columns,
                      rows: dataRequest.documentPrincipal,
                    }}
                    entriesLabel=""
                    infoLabel={["Mostrando", "a", "de", "solicitudes"]}
                    fullPagination
                    noRecordsFoundLabel="No se encontraron resultados"
                  />
                </Row>
              </Row>
            </>
          )}
          <br />
          <hr />
          <br />
          {/* Botones para las solicitudes */}
          
          <Row style={{ alignItems: "center" }}>
            {/* Button visible only is active request*/}
            {!["Solucionada", "Cancelada"].includes(dataRequest.stateRequest) && (
                <Col xs={"auto"}>
                  <Button
                    type="button"
                    className="custom-input__button__secondary-color"
                    variant="contained"
                    style={{ padding: "12px 24px", marginRight: "2rem" }}
                    onClick={() => {
                      navigate("approvals");
                    }}
                  >
                    {"Vistos buenos"}
                  </Button>
                </Col>
              )}
            {[
              STATUS_REQUESTS.REVIEW_APPLICANT.name,
              STATUS_REQUESTS.REVIEW_ATTORNEY.name,
            ].includes(dataRequest.stateRequest) &&
              signPermission && (
                <Col xs={"auto"}>
                  {disableSign ? (
                    <CustomToolTip
                      direction="top"
                      message="Sin firmas disponibles en el plan, contacta al Usuario Administrador"
                    >
                      <button
                        disabled
                        type="button"
                        className="btn_disable"
                        style={{ padding: "12px 24px", marginRight: "2rem" }}
                      >
                        Firmar
                      </button>
                    </CustomToolTip>
                  ) : (
                    <Button
                      type="button"
                      className="custom-input__button__secondary-color"
                      variant="contained"
                      style={{ padding: "12px 24px", marginRight: "2rem" }}
                      onClick={() => {
                        navigate(`/external/requests/${consecutive}/sign`);
                      }}
                    >
                      Firmar
                    </Button>
                  )}
                </Col>
              )}
            <Col xs={"auto"}>
              <Button
                type="button"
                className="custom-input__button__secondary-color"
                variant="contained"
                style={{ padding: "12px 24px", marginRight: "2rem" }}
                onClick={handleBack}
              >
                Regresar
              </Button>
            </Col>
            <Col>
              <Row style={{ justifyContent: "end" }}>
                {dataRequest.stateRequest !== "Solucionada" &&
                  dataRequest.stateRequest !== "Cancelada" && (
                    <>
                      <Col xs={"auto"}>
                        <Button
                          type="button"
                          className="custom-input__button__primary-color"
                          variant="contained"
                          style={{ padding: "12px 24px", marginRight: "2rem" }}
                          onClick={handleShowModalCancelRequest}
                        >
                          Cancelar solicitud
                        </Button>
                      </Col>
                      <Col xs={"auto"}>
                        <Button
                          type="submit"
                          className="custom-input__button__primary-color"
                          variant="contained"
                          style={{ padding: "12px 24px" }}
                        >
                          Enviar abogado
                        </Button>
                      </Col>
                    </>
                  )}
              </Row>
            </Col>
          </Row>
        </Form>
        <br />
      </div>
      {/*Modal Cancelar solicitud*/}
      <Modal
        size="md"
        centered
        backdrop="static"
        keyboard={false}
        show={showModalCancelRequest}
        onHide={handleClose}
      >
        <Modal.Header closeButton style={{ padding: "1rem" }}>
          <Modal.Title
            className="label__title"
            style={{
              display: "flex",
              color: "#F9600B",
              alignItems: "center",
            }}
          >
            <InfoOutlinedIcon
              sx={{ marginRight: "1rem" }}
              color="warning"
              fontSize="large"
            />
            Cancelar solicitud
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label
            style={{
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "normal",
              color: "#393b53",
              marginBottom: "1.5rem",
            }}
          >
            Al terminar la solicitud usted esta cerrando el proceso y no se
            continuará con la solicitud ¿Desea terminarla?
          </label>
        </Modal.Body>
        <Modal.Footer style={{ padding: "1rem" }}>
          <button
            className="btn_white_background"
            style={{ padding: "12px 24px" }}
            onClick={handleClose}
          >
            Cancelar
          </button>
          <button
            onClick={handleCancelRequestConfirm}
            type="submit"
            className="btn_blue_background"
            style={{ padding: "12px 24px" }}
          >
            {isLoadingCancelRequest ? (
              <Spinner
                animation="border"
                className="spinner__white-background"
              />
            ) : (
              "Aceptar"
            )}
          </button>
        </Modal.Footer>
      </Modal>
      {/*Modal Solicitud Editada*/}
      <Modal
        size="md"
        centered
        backdrop="static"
        keyboard={false}
        show={showModalRequestResend}
        onHide={handleClose}
      >
        <Modal.Header closeButton style={{ padding: "1rem" }}>
          <Modal.Title
            className="label__title"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Enviado al abogado
          </Modal.Title>
        </Modal.Header>
        {isLoadingRequestResend ? (
          <Modal.Body style={{ alignSelf: "center" }}>
            <Spinner
              animation="border"
              size="30px"
              className="spinner__blue-background"
            />
          </Modal.Body>
        ) : (
          <>
            <Modal.Body>
              <label
                style={{
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  color: "#393b53",
                  marginBottom: "1.5rem",
                }}
              >
                Los cambios de su solicitud se han guardado con éxito y se han
                enviado al abogado para su próxima revisión.
              </label>
            </Modal.Body>
            <Modal.Footer style={{ padding: "1rem" }}>
              <button
                onClick={handleSendToLawyerState}
                type="submit"
                className="btn_blue_background"
                style={{ padding: "12px 24px" }}
              >
                Aceptar
              </button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      {/*Modal Subir Documento Principal*/}
      <Modal
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
        show={showModalUploadDocumentPrincipal}
        onHide={handleClose}
      >
        <Form onSubmit={handleSubmitUploadDocuments(handleUploadDocument)}>
          <Modal.Header style={{ padding: "1rem" }}>
            <Modal.Title
              className="label__title"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Detalles del documento
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label className="label__description">
              Descripción o comentarios
            </Form.Label>
            <Form.Control
              as="textarea"
              {...registerUploadDocuments("comment")}
              placeholder="Escribe un nuevo comentario..."
              style={{
                width: "100%",
                fontSize: "1.4rem",
                fontWeight: "100",
                lineHeight: "2.4rem",
                borderRadius: "0.5rem",
                border: "0.1rem solid #c5c7d0",
                padding: "1rem",
              }}
            />
            <br />
            <Row>
              {errorsUploadDocuments.comment && (
                <span className="caption custom-input__error">
                  *Obligatorio agregar un comentario
                </span>
              )}
            </Row>
            {!fileSelectedPrincipal?.name ? (
              <></>
            ) : (
              <Row
                htmlFor="uploadFile"
                style={{
                  color: "#00374F",
                  height: "max-content",
                  margin: "1rem 0rem",
                  boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                  borderRadius: "8px",
                }}
              >
                <Col style={{ margin: "1.5rem" }} md={7}>
                  <Row className="label__title">
                    Archivo de documento principal
                  </Row>
                  <Row>{fileSelectedPrincipal?.name}</Row>
                </Col>
                <Col style={{ margin: "1.5rem" }} md={3}>
                  <Row>
                    <Col md={6}>
                      <Form.Label
                        onClick={handleDownloadRecentDocument}
                        className="btn_blue_background"
                        style={{
                          backgroundColor: "#00374F",
                          marginRight: "1rem",
                          padding: "7px",
                          cursor: "pointer",
                        }}
                      >
                        <DownloadIcon fontSize="large" />
                      </Form.Label>
                    </Col>
                    <Col md={6}>
                      <Form.Label
                        htmlFor="uploadFile"
                        className="btn_blue_background"
                        color="#676879"
                        style={{
                          backgroundColor: "#00374F",
                          marginRight: "1rem",
                          padding: "7px",
                          cursor: "pointer",
                        }}
                      >
                        <EditOutlinedIcon fontSize="large" />
                      </Form.Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Form.Label
              hidden={isHideBtnUpDocPrincipal}
              htmlFor="uploadFile"
              className="btn_white_background"
              style={{
                marginTop: "1rem",
                padding: "8px 12px",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon htmlFor="uploadFile" size="lg" icon={faPlus} />
              {" Cargar documento principal"}
            </Form.Label>
            <span className="caption custom-input__error">
              {errorsUploadDocuments.file?.message}
            </span>

            <Controller
              name="file"
              control={controlUploadDocuments}
              render={({ field }) => (
                <Form.Control
                  id="uploadFile"
                  className="dropzone_upload"
                  type="file"
                  onChange={(e) => {
                    field.onChange(e.target.files);
                    setIsHideBtnUpDocPrincipal(true);
                    setFileSelectedPrincipal(e.target.files[0]);
                  }}
                />
              )}
            />
          </Modal.Body>
          <Modal.Footer style={{ padding: "1rem" }}>
            <Form.Label
              className="btn_white_background"
              style={{ padding: "12px 24px", cursor: "pointer" }}
              onClick={() => {
                setIsHideBtnUpDocPrincipal(false);
                setFileSelectedPrincipal({});
                handleClose();
              }}
            >
              Descartar
            </Form.Label>
            <button
              type="submit"
              className="btn_blue_background"
              style={{ padding: "12px 24px" }}
            >
              Guardar cambios
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={dataRequest.comments}
        id={dataRequest._id}
        setIsNewComment={setIsNewComment}
        isRequestActive={dataRequest.isRequestActive}
      />
      <ModalInfo
        open={isOpenModalError}
        onClose={() => setIsOpenModalError(false)}
        responseData={responseDataError}
        title={
          responseDataError?.status !== CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
            ? "Atención"
            : "Sin permisos suficientes"
        }
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default ViewRequestStandard;
