import GetToken from "../../utils/getToken";
import axios from "axios";
import { env } from "../../env";

export function CreateNewRequestCarvajal(data) {
  const formData = new FormData();
  formData.append("companyId", data.companyId);
  formData.append("requestSubject", data.requestSubject);
  formData.append("applicantId", data.applicantId);
  formData.append("requestType", data.requestType);
  formData.append("applicantCompany", data.applicantCompany);
  formData.append("requestSubtype", data.requestSubtype);
  formData.append("requestAnswers", JSON.stringify(data.requestAnswers));
  formData.append("corporateUnit", data.corporateUnit);
  formData.append("requestTypeRedaction", data.requestTypeRedaction);
  formData.append("startModificationDate", data.startModificationDate);

  if (data.applicantDocuments?.length) {
    data.applicantDocuments.map((file) => {
      formData.append("applicantDocuments", file);
    });
  }

  if (data.requiredDocuments?.length) {
    data.requiredDocuments.map((file) => {
      formData.append("requiredDocuments", file.file, file.name);
    });
  }

  return axios({
    method: "POST",
    url: "/thirdPartyRequest/createNewRequest",
    baseURL: env.REACT_APP_API_URL,
    data: formData,
    validateStatus: null,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      Authorization: GetToken(),
    },
  });
}

export function getRequestCarvajal(data) {
  return axios({
    method: "GET",
    url: `/thirdPartyRequest/getRequestTypes/`,
    params: {
      companyId: data.companyId,
      corporateUnitsIds: data.corporateUnitsIds,
    },
    baseURL: env.REACT_APP_API_URL,
    headers: { Authorization: GetToken() },
    validateStatus: null,
  });
}

export function getCompaniesList() {
  return axios({
    method: "GET",
    url: "/thirdPartyRequest/getCompaniesList",
    baseURL: env.REACT_APP_API_URL,
    headers: { Authorization: GetToken() },
    validateStatus: null,
  });
}

export function SendDocumentToSign(data) {
  return axios({
    method: "POST",
    url: "signatures/saveForExternalUser",
    baseURL: env.REACT_APP_API_URL,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
    validateStatus: null,
  });
}

export function findOneDataExternalRequest(data) {
  return axios({
    method: "GET",
    url: `thirdPartyRequest/byConsecutive/${data.consecutive}`,
    params: {
      companyId: data.company,
      corporateUnitId: data.corporateUnitId,
      userId: data.userId,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
  });
}

export function SendPersonalDataToSign(data) {
  return axios({
    method: "PUT",
    url: `signatures/addForExternalUser/signatory/info/${data.signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
    validateStatus: null,
  });
}

export function ShowDataExternalRequestCarvajal({ userId, optionsToFilter }) {
  const types = optionsToFilter.find(
    (option) => option.title === "Documento requerido"
  )
    ? optionsToFilter
        .find((option) => option.title === "Documento requerido")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;

  const requestStates = optionsToFilter.find(
    (option) => option.title === "Estado de la solicitud"
  )
    ? optionsToFilter
        .find((option) => option.title === "Estado de la solicitud")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;

  const laywersIds = optionsToFilter.find(
    (option) => option.title === "Usuario de servicios"
  )
    ? optionsToFilter
        .find((option) => option.title === "Usuario de servicios")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;

  const companyNames = optionsToFilter.find(
    (option) => option.title === "Empresa"
  )
    ? optionsToFilter
        .find((option) => option.title === "Empresa")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;

  const { _id } = JSON.parse(localStorage.getItem("corporateUnit"));

  return axios({
    method: "GET",
    url: `/thirdPartyRequest/external-user/requests/${userId}`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      states: requestStates,
      names: types,
      attorneysIds: laywersIds,
      companies: companyNames,
      corporateUnit: _id,
    },
    validateStatus: null,
    headers: { Authorization: GetToken() },
  });
}

export function SendfInfoToLawyerCarvajal(data) {
  const {
    applicantId,
    consecutive,
    comment,
    attornetName,
    applicantName,
    applicantDocuments,
    mainDocuments,
    requiredDocuments,
    companyId,
    corporateUnit,
  } = data;
  const formData = new FormData();
  formData.append("consecutive", consecutive);
  formData.append("applicantId", applicantId);
  formData.append("companyId", companyId);
  formData.append("corporateUnit", corporateUnit);
  if (applicantDocuments.length) {
    applicantDocuments.map((document) => {
      formData.append("applicantDocuments", document);
    });
  }

  if (mainDocuments) {
    formData.append("description", comment);
    formData.append("userRole", "EXTERNAL");
    formData.append("attorneyName", attornetName);
    formData.append("applicantName", applicantName);
    formData.append("mainDocuments", mainDocuments);
  }

  if (requiredDocuments.length) {
    requiredDocuments.map((file) => {
      formData.append("requiredDocuments", file.file, file.name);
    });
  }

  return axios({
    method: "PUT",
    url: "/thirdPartyRequest/add/documents/",
    baseURL: env.REACT_APP_API_URL,
    data: formData,
    validateStatus: null,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      Authorization: GetToken(),
    },
  });
}

export function ChangeStateCarvajal(data) {
  return axios({
    method: "PUT",
    url: "/thirdPartyRequest/changeRequestState",
    baseURL: env.REACT_APP_API_URL,
    headers: {
      Authorization: GetToken(),
    },
    validateStatus: null,
    data: {
      requestId: data.requestId,
      state: data.state,
      returnMessage: data.message,
    },
  });
}

export const getRequestSummary = ({ userId, companyId, corporateUnitId }) => {
  return axios({
    method: "GET",
    url: `thirdPartyRequest/getRequestsSummaryApplicant`,
    params: {
      companyId,
      corporateUnitId,
      userId,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};
