import axios from "axios";
import GetToken from "../utils/getToken";
import { env } from "../env";

export function permissionsUsersByEmail(email) {
    return axios({
        method: "GET",
        url: `users/getPermissions`,
        params: { email },
        baseURL: env.REACT_APP_API_URL,
        validateStatus: null,
        headers: {
            Authorization: GetToken(),
        },
    });
}

export const PARTS_LIBRARY_STATE = {
    DELETED: {
     _id: "621fdcd7e1b7dfa85f10bf46",
     name: "Eliminada",
   },
   ACTIVE: {
     _id: "621fdce7e1b7dfa85f10bf48",
     name: "Activo",
   }
 };