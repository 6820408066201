import { Col, Form, Row } from "react-bootstrap";
import changeDefaultBlankSpaceName from "../../utils/changeDefaultBlankSpaceName";

const FillableBlankSpace = ({
  blankSpace,
  handleBlankSpaces,
  flashItemSelected,
}) => {
  return (
    <article className="d-flex flex-column gap-3 py-3">
      <Row>
        <Col>
          <p className="heading__subtitle">{changeDefaultBlankSpaceName(blankSpace?.name)}</p>
          {blankSpace?.description && (
            <p className="label__description">{blankSpace?.description}</p>
          )}
        </Col>
      </Row>
      <Row>
        <Form.Group className="my-1">
          <Form.Control
            placeholder={`Escribir aquí`}
            defaultValue={blankSpace.value || ""}
            bsPrefix={"input-group-container__no-icon label"}
            onChange={handleBlankSpaces}
            onFocus={flashItemSelected}
          />
        </Form.Group>
      </Row>
    </article>
  );
};

export default FillableBlankSpace;
