import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import {
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  statusList,
  carvajalTypeList,
} from "../../../utils/statusExtenalRequest";
import { GetAttorneys } from "../../../services/ExternalUsers/ExternalRequest";
import {
  getCompaniesList,
  getRequestCarvajal,
} from "../../../services/CarvServices/CarvajalServices";
import LoadingContent from "../../../components/LoadingContent";
import { setStatusRequest } from "../../../reducers/requestSlice";
import { useDispatch } from "react-redux";

const FilterByCarvajal = ({
  openDrawer,
  setOpenDrawer,
  // listOptions,
  setOptionsFilterBar,
  folders = false,
}) => {
  const [listOptions, setListOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useDispatch();

  const handleFilter = (option, value, checked) => {
    setListOptions((listOptions) => {
      const newList = [...listOptions];
      const states = newList.map((list) => {
        if (list.title === option.title) {
          return {
            ...list,
            filters: list.filters.map((filter) => {
              if (filter.value === value) {
                return { ...filter, checked: checked };
              } else {
                return { ...filter };
              }
            }),
            expanded: true,
          };
        } else {
          return { ...list, expanded: false };
        }
      });
      return states;
    });
  };

  useEffect(() => {
    async function fetchData() {
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      const { _id: corporateUnit } = JSON.parse(
        localStorage.getItem("corporateUnit")
      );
      const { company } = payloadToken;
      try {
        setIsloading(true);
        const obj = {
          companyId: company,
          corporateUnit,
        };

        const formObj = {
          companyId: payloadToken.company,
        };

        const [
          { value: listAttorneys },
          { value: listOfCompanies },
          { value: listOfForms },
        ] = await Promise.allSettled([
          GetAttorneys(obj),
          getCompaniesList(),
          getRequestCarvajal(formObj),
        ]);

        setListOptions([
          {
            title: "Estado de la solicitud",
            filters: statusList.map((status) => ({
              label: status.Status?.toLocaleLowerCase().replace(/^\w/, (c) =>
                c.toUpperCase()
              ),
              value: status.Status,
              checked: false,
            })),
          },
          {
            title: "Documento requerido",
            filters: listOfForms.data.responseMessage.map((type) => ({
              label: type.name
                ?.toLocaleLowerCase()
                .replace(/^\w/, (c) => c.toUpperCase()),
              value: type.name,
              checked: false,
            })),
          },
          {
            title: "Usuario de servicios",
            filters: listAttorneys.data.responseMessage.map((attorney) => ({
              label: `${attorney.firstName || ""} ${
                attorney.secondName || ""
              } ${attorney.firstSurname || ""} ${attorney.secondSurname || ""}`,
              value: attorney._id,
              checked: false,
            })),
          },
          {
            title: "Empresa",
            filters: listOfCompanies.data.responseMessage.list.map(
              (company) => ({
                label: company,
                value: company,
                checked: false,
              })
            ),
          },
        ]);
      } catch (error) {
        console.log(error);
      } finally {
        setIsloading(false);
      }
    }
    fetchData();
  }, [openDrawer]);

  const filter = () => {
    dispatch(setStatusRequest({ status: "fetch" }));
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <Container fluid style={{ width: "25rem", height: "100vh" }}>
          {isLoading && <LoadingContent />}
          <Row
            style={{
              height: "10%",
              alignContent: "center",
            }}
          >
            <Col md={10} className="heading">
              Filtrar
            </Col>
            <Col xs={"auto"} style={{ cursor: "pointer" }}>
              <CloseIcon
                onClick={() => {
                  setOpenDrawer(false);
                }}
              />
            </Col>
          </Row>
          <br />
          {listOptions.map((option) => (
            <Row
              key={option.title}
              style={{
                alignContent: "center",
              }}
            >
              <Accordion defaultExpanded={option?.expanded}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="subheading">{option.title}</p>
                </AccordionSummary>
                <AccordionDetails>
                  {option.filters.map((filter) => (
                    <Form.Group
                      className="mb-1"
                      controlId={filter.label}
                      key={filter.label}
                    >
                      <Form.Check
                        type="checkbox"
                        label={filter.label}
                        checked={filter.checked}
                        onChange={(e) => {
                          handleFilter(option, filter.value, e.target.checked);
                        }}
                      />
                    </Form.Group>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Row>
          ))}
          <br />
          <Row style={{ bottom: 10 }}>
            <Col md={7}>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => {
                  setOptionsFilterBar([]);
                  setOpenDrawer(false);
                  filter();
                }}
              >
                Limpiar filtro
              </Button>
            </Col>
            <Col md={5}>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={() => {
                  setOptionsFilterBar(listOptions);
                  setOpenDrawer(false);
                  filter();
                }}
              >
                Siguiente
              </Button>
            </Col>
          </Row>
        </Container>
      </Drawer>
    </>
  );
};

export default FilterByCarvajal;
