import React, { useState, useEffect } from "react";
import { Row, Container, InputGroup, Form } from "react-bootstrap";
import { getRequestCarvajal } from "../../services/CarvServices/CarvajalServices";
import LoadingContent from "../LoadingContent";
import SectionTitle from "../sectionTitle";
import SearchIcon from "@mui/icons-material/Search";

const InfoSubtypeRequest = () => {

  const [dataSubtypeRequest, setDataSubtypeRequest] = useState([]);
  const [filterDataSubtypeRequest, setFilterDataSubtypeRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const { company } = JSON.parse(localStorage.getItem("payloadToken"));
        const obj = {
          companyId: company,
        };
        const GetDataSubtypeRequest = await getRequestCarvajal(obj);

        if (GetDataSubtypeRequest.data.responseCode === 0) {
          let subtypeList = [];
          for (const subtype of GetDataSubtypeRequest.data.responseMessage) {
            subtypeList = subtypeList.concat(subtype.subtypes);
          };
          setDataSubtypeRequest(subtypeList);
          setFilterDataSubtypeRequest(subtypeList);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const filter = (textSearch) => {
    let filtered = [];
    let empty = [{ name: "No se encontraron coincidencias" }];

    const normString = (text) => {
      return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    };

    if (textSearch === "") {
      filtered = dataSubtypeRequest;
    } else {
      filtered = dataSubtypeRequest.filter((subtype) => {
        const lookForIn = normString(subtype.name);
        const lookForOut = normString(textSearch);
        if (lookForIn.includes(lookForOut)) {
          return subtype;
        }
      });
    }

    filtered.length === 0
      ? setFilterDataSubtypeRequest(empty)
      : setFilterDataSubtypeRequest(filtered);
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <br />
      <br />
      <Row xs={"auto"}>
        <SectionTitle
          title={"Información acerca de los subtipos de solicitud"}
        />
      </Row>
      <br />
      <br />
      <Row xs={3}>
        <InputGroup bsPrefix="input-group-container">
          <Form.Control
            bsPrefix="custom-input"
            placeholder={"Buscar término"}
            onChange={(e) => filter(e.target.value)}
          />
          <InputGroup.Text bsPrefix="container-icon">
            <SearchIcon fontSize="large" />
          </InputGroup.Text>
        </InputGroup>
      </Row>
      <Row>
        {filterDataSubtypeRequest.map((item) => {
          return (
            <Row key={item.name} style={{ marginTop: "2rem" }}>
              <h1 className="label__subtitle">{item.name}</h1>
              <p className="label__description">{item.description}</p>
            </Row>
          );
        })}
      </Row>
    </Container>
  );
};

export default InfoSubtypeRequest;
