import React, { useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import Button from "@mui/material/Button";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import CustomSearchFilterButton from "../Search/customSearchFilterButton";
import CardFilter from "../Cards/CardFilter";

const FilterSignatory = ({
  sigantoryList,
  list,
  setFilteredInfo,
  handleSignatorySelected,
  classStyle = "custom-input__button__secondary-color",
  widthStyle = "auto",
  heightStyle = "auto",
  currentSignatoriesSelected = [],
}) => {
  const [show, setShow] = useState(false);
  const [countChecked, setCountChecked] = useState(0);

  const [signatories, setSignatories] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [signatorySelected, setSignatorySelected] = useState([]);

  const handleShow = () => {
    setFilteredInfo(list);
    const listArray = [...sigantoryList];
    const orderList = listArray.sort((a, b) =>
      a.firstName.localeCompare(b.firstName)
    );
    setSignatories(orderList);
    setFilteredData(orderList);
    setShow(true);
    setSignatorySelected(currentSignatoriesSelected);
    setCountChecked(currentSignatoriesSelected.length);
  };

  const handleChecked = (e) => {
    const item = e.target;
    if (item.checked) {
      setCountChecked(countChecked + 1);
      setSignatorySelected((oldArray) => [...oldArray, item]);
    } else {
      setCountChecked(countChecked - 1);
      setSignatorySelected(
        signatorySelected.filter((signatory) => signatory.id !== item.id)
      );
    }
  };

  const handleClose = () => {
    setSignatorySelected([]);
    setCountChecked(0);
    setShow(false);
  };

  const handleFilterSignatories = (signatorySelected) => {
    if (signatorySelected.length > 0) {
      const selectedList = signatorySelected.map((item) => item.id);
      let filteredSignatories = [];
      if (list.rows) {
        filteredSignatories = list.rows.filter((item) => {
          const list = item.relatedSignatories;
          return list.some((signatory) =>
            selectedList.includes(signatory.signatory?._id)
          );
        });
        setFilteredInfo({ ...list, rows: filteredSignatories });
        handleSignatorySelected(signatorySelected);
      } else if (list) {
        const filteredSignatories = list.filter((item) => {
          return item.relatedSignatories.some((signatory) =>
            selectedList.includes(signatory.signatory?._id)
          );
        });
        setFilteredInfo(filteredSignatories);
        handleSignatorySelected(signatorySelected);
      }
    } else {
      setFilteredInfo(list);
    }

    handleClose();
  };

  return (
    <>
      <Button
        data-testid="viewBySignatory-test"
        variant="contained"
        startIcon={<RateReviewOutlinedIcon />}
        className={classStyle}
        onClick={handleShow}
        style={{
          width: `${widthStyle} !important`,
          height: `${heightStyle} !important`,
        }}
      >
        <p className="button">Ver por firmante </p>
      </Button>
      <Modal show={show} fullscreen onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="label__primary-color__page-title">Ver por firmante</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="filter-tag__headers">
              <Col>
                <p className="caption">
                  Por favor, selecciona los firmantes por los que desea filtrar
                  el listado de partes:
                </p>
              </Col>
              <Col sm={{ span: "auto", offset: 3 }}>
                <p className="caption__primary-color__active">
                  {countChecked} seleccionadas
                </p>
              </Col>
              <Col>
                <CustomSearchFilterButton
                  list={signatories.map((signatory) => {
                    let name = `${signatory?.firstName || ""} ${
                      signatory?.secondName || ""
                    } ${signatory?.firstSurname || ""} ${
                      signatory?.secondSurname || ""
                    } `;
                    if (signatory?.typePart === "Juridica") {
                      name = signatory?.business?.businessName;
                    }

                    return { ...signatory, name };
                  })}
                  setFilteredData={setFilteredData}
                  placeholder="Buscar firmante"
                  validations={{ maxLength: 20 }}
                />
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <Row sm={3}>
              {filteredData?.length > 0 ? (
                filteredData.map((signatory, i) => {
                  let name = `${signatory?.firstName || ""} ${
                    signatory?.secondName || ""
                  } ${signatory?.firstSurname || ""} ${
                    signatory?.secondSurname || ""
                  } `;
                  if (signatory?.typePart === "Juridica") {
                    name = signatory?.business?.businessName;
                  }

                  return (
                    <Col
                      className="filter-tag__check"
                      style={{ marginBottom: "1.5%" }}
                      key={signatory?._id}
                    >
                      <input
                        data-testid={signatory?._id}
                        defaultChecked={signatorySelected.find(
                          (item) => item?.id === signatory?._id
                        )}
                        id={signatory?._id}
                        name={name}
                        type="checkbox"
                        onClick={handleChecked}
                      />
                      <label htmlFor={signatory?._id}>
                        <CardFilter
                          icon={
                            <LocalOfferIcon
                              fontSize="large"
                              className="filter-tag__icon"
                            />
                          }
                          title={name}
                        />
                      </label>
                    </Col>
                  );
                })
              ) : (
                <label className="caption">
                  No se encontraron resultados para tu búsqueda
                </label>
              )}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={handleClose}
              >
                <p className="button">Cancelar</p>
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                data-testid="buttonFilter-test"
                variant="contained"
                className={signatorySelected.length ? "custom-input__button__primary-color text-white" : "custom-input__button__disabled"}
                onClick={() => handleFilterSignatories(signatorySelected)}
                disabled={!signatorySelected.length}
              >
                <p className="button">Filtrar</p>
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterSignatory;
