import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
//MaterialIconsUI
import { Button, Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MDBDataTableV5 } from "mdbreact";
import CustomSearchFilterButton from "../../components/Search/customSearchFilterButton";
import { onSortHTMLElement } from "../../utils/findComponentHTML";
import PopoverActions from "../../components/popover/PopoverActions";
import Breadcrum from "../../pages/parts/breadcrum";
import SectionTitle from "../../components/sectionTitle";
import LoadingContent from "../../components/LoadingContent";
import { findSignatoriesByCompany } from "../../services/parts/partsServices";
import ModalInfo from "../../components/Modals/ModalInfo";
import { CODES } from "../../utils/codes";
import { PARTS_LIBRARY_STATE } from "../../utils/partState";

export const SelectSignatoryFromLibrary = () => {
  /**
   * Use States
   */

  const [signatoriesList, setSignatoriesList] = useState([]);

  const [part, setPart] = useState({});

  const [openModalNoSignatories, setOpenModalNoSignatories] = useState(false);

  const columns = [
    {
      label: [<label aria-hidden="true" key="0"></label>],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre del firmante.
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Identificación del firmante.
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Contacto
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Parte relacionada.
        </label>,
      ],
      field: "partName",
    },
    {
      label: [
        <label aria-hidden="true" key="5">
          Cargo del firmante en la Parte
        </label>,
      ],
      field: "partPosition",
    },
  ];

  const columnsNoParts = [
    {
      label: [<label aria-hidden="true" key="0"></label>],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre del firmante.
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Identificación del firmante.
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Contacto
        </label>,
      ],
      field: "contact",
    },
  ];
  const [data, setData] = useState({ columns, rows: [] });
  const [filteredData, setFilteredData] = useState({ columns, rows: [] });

  const [selectedSignatories, setSelectedSignatories] = useState([]);

  const [deletedSignatoryList, setDeletedSignatoryList] = useState([]);

  const [isLoading, setIsLoading] = useState(null);

  /**
   * Router
   */

  const navigate = useNavigate();

  /**
   * UseParams
   */

  const { type } = useParams();

  /**
   * UseHandlers
   */

  const getName = (item) => {
    let fullName = `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
    return fullName.replace(/  +/g, " ");
  };

  const handleCheckSignatory = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedSignatories((oldArray) => [...oldArray, item]);

      setDeletedSignatoryList(
        deletedSignatoryList.filter((itm) => itm?._id !== item?._id)
      );
    } else {
      setDeletedSignatoryList([...deletedSignatoryList, item]);
      setSelectedSignatories(
        selectedSignatories.filter((itm) => itm?._id !== item?._id)
      );
    }
  };

  const buildData = (data) => {
    if (!data.length) return [];
    const formatedData = [];
    for (const item of data) {
      const signatory = item.signatory;
      let name = getName(signatory);
      let id = `${signatory?.documentType}. ${signatory?.documentNumber}`;
      const contact = `${signatory?.email || ""}`;
      const address = signatory?.residence?.physicalAddress;
      const country = signatory?.residence?.country;
      const city = `${signatory?.residence?.province?.concat(", ") || ""} ${
        signatory?.residence?.city || ""
      }`;
      const number = `+${signatory?.mobileNumberData?.countryCode || ""} ${
        signatory?.mobileNumberData?.phone || ""
      }`;

      let telephone = `+${signatory?.countryCodeTelephone || ""} ${
        signatory?.cityCode || ""
      } ${signatory?.numberTelephone || ""}`;

      formatedData.push({
        name,

        partName: (
          <Row className="align-items-center">
            <Col xs={12}>
              <span className="caption__primary-color__active ">
                {part?.information?.name}
              </span>
            </Col>
          </Row>
        ),
        select: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <Checkbox
                checked={selectedSignatories?.some(
                  (itm) => itm?._id === item?._id
                )}
                onClick={(e) => handleCheckSignatory(e, item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            </Col>
          </Row>
        ),
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),

        ...item,
      });
    }

    return formatedData.sort((a, b) => a.name.localeCompare(b.name));
  };

  const buildDataNoPart = (data) => {
    if (!data.length) return [];
    const formatedData = [];

    for (const item of data) {
      let name = getName(item);
      let id = `${item?.documentType}. ${item?.documentNumber}`;
      const contact = `${item?.email || ""}`;
      const address = item?.residence?.physicalAddress;
      const country = item?.residence?.country;
      const city = `${item?.residence?.province?.concat(", ") || ""} ${
        item?.residence?.city || ""
      }`;
      const number = `+${item?.mobileNumberData?.countryCode || ""} ${
        item?.mobileNumberData?.phone || ""
      }`;

      let telephone = `+${item?.countryCodeTelephone || ""} ${
        item?.cityCode || ""
      } ${item?.numberTelephone || ""}`;

      formatedData.push({
        name,

        select: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <Checkbox
                checked={selectedSignatories?.some(
                  (itm) => itm?._id === item?._id
                )}
                onClick={(e) => handleCheckSignatory(e, item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            </Col>
          </Row>
        ),
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),

        ...item,
      });
    }

    return formatedData.sort((a, b) => a.name.localeCompare(b.name));
  };

  //Use effects

  useEffect(() => {
    const fetchData = async () => {
      try {
        const company = localStorage.getItem("company");
        let associatedCorporateUnits = [
          localStorage.getItem("corporateUnitId"),
        ];
        const status = PARTS_LIBRARY_STATE.ACTIVE._id;
        setIsLoading(true);
        let response = {};
        if (type === "from-request") {
          response = await findSignatoriesByCompany({
            company,
            status,
            associatedCorporateUnits,
          });
        }
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          if (
            type === "from-request" &&
            !response.data?.responseMessage?.data?.length
          ) {
            setOpenModalNoSignatories(true);
          } else {
            setSignatoriesList(response.data?.responseMessage?.data);
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log("Error => ", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let tableColumns = [];
    let tranformData = [];
    if (type === "without-part" || type === "from-request") {
      tableColumns = columnsNoParts;
      tranformData = buildDataNoPart(signatoriesList);
    } else {
      tableColumns = columns;
      tranformData = buildData(signatoriesList);
    }

    setData({ rows: tranformData, columns: tableColumns });
    setFilteredData({ rows: tranformData, columns: tableColumns });
  }, [signatoriesList, selectedSignatories]);

  return (
    <Container fluid className="custom-container-scroll ">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={`Firmantes guardados`} />
      </Row>
      <Row className="mt-4">
        <Col xs={"auto"} className="caption">
          {`Escoge el o los firmantes que quieras:`}
        </Col>
      </Row>
      <br />
      <Row style={{ rowGap: 15 }}>
        <Col xs={7} xl={5}>
          <CustomSearchFilterButton
            placeholder="Buscar"
            list={data}
            setFilteredData={setFilteredData}
            validations={{ maxLength: 20 }}
          />
        </Col>
      </Row>

      <br />

      <br />
      <Row className="dt-list-center-content " style={{ marginBottom: "7rem" }}>
        {
          <MDBDataTableV5
            noRecordsFoundLabel={
              !data?.rows?.length
                ? "Aún no tienes partes creadas"
                : "No se encontraron resultados para tu búsqueda"
            }
            hover
            pagingTop
            searchBottom={false}
            entries={10}
            data={filteredData}
            infoLabel={["Mostrando", "a", "de", "firmantes"]}
            fullPagination
            onSort={(value) => {
              onSortHTMLElement({
                excludesColumns: ["actions", "corporateUnits"],
                sort: value,
                filteredDataTable: filteredData,
                setFilteredDataTable: setFilteredData,
              });
            }}
          />
        }
      </Row>
      <Row className="sidebar__container justify-content-end">
        <Col xs={2}>
          {!selectedSignatories.length ? (
            <Button
              variant="contained"
              className="custom-input__button__disabled"
              startIcon={<CheckIcon fontSize="large" />}
              disabled
            >
              Seleccionar
            </Button>
          ) : (
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              startIcon={<CheckIcon fontSize="large" />}
              disabled={!selectedSignatories.length}
              onClick={() => {
                if (type === "from-request") {
                  const obj = {
                    selectedSignatoriesFromLibrary: selectedSignatories,
                  };
                  navigate(`/external/requests/addSignatories`, {
                    state: obj,
                    replace: true,
                  });
                }
              }}
            >
              Seleccionar
            </Button>
          )}
        </Col>
      </Row>

      <ModalInfo
        title="Sin firmantes guardados"
        onClose={() => setOpenModalNoSignatories(false)}
        open={openModalNoSignatories}
        responseData={{
          data: {
            message: "No existen firmantes guardados en biblioteca",
          },
        }}
        confirmationText="Aceptar"
        buttonClose={true}
      />
    </Container>
  );
};
