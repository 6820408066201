import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import SubModalGeneral from "./SubModalGeneral";
import { ErrorOutline } from "@mui/icons-material";

/**
 * Types of titles that can be displayed in the modal.
 * 
 * @readonly
 */
const TitleTypes = Object.freeze({
  NONE: undefined,
  WARNING: "warning",
});

const ModalDecision = ({
  title,
  message,
  agreeText,
  disagreeText,
  handleAgree,
  handleDisagree,
  onClose,
  open,
  type = TitleTypes.NONE,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <SubModalGeneral onClose={handleClose}>
          {!type && <label className="heading__primary-color">{title}</label>}
          {type === TitleTypes.WARNING && (
            <label className="heading__warning-color">
              <ErrorOutline fontSize="large" />
              {title}
            </label>
          )}
        </SubModalGeneral>
        <DialogContent className="caption" dividers>
          {message}
        </DialogContent>
        <DialogActions>
          <Row>
            <Col>
              {disagreeText && (
                <Button
                  variant="text"
                  className="custom-input__button__secondary-color__forced"
                  onClick={handleDisagree}
                >
                  {disagreeText}
                </Button>
              )}
            </Col>
            <Col>
              {agreeText && (
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color__forced"
                  onClick={handleAgree}
                >
                  {agreeText}
                </Button>
              )}
            </Col>
          </Row>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalDecision;
