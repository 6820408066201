/**
 * Change the default name of a blank space, if the name is blankSpace-N, it will be changed to ESPACIO #N
 * @param {string} name The name of the blank space
 * @returns {string} The changed name of the blank space
 */
const changeDefaultBlankSpaceName = (name) => { 
  const defaultNameRegex = /blankSpace-\d+/;
  const changedName = defaultNameRegex.test(name) ? name.replace(/^blankSpace-/, "ESPACIO #") : name;
  return changedName;
}

export default changeDefaultBlankSpaceName;