import axios from "axios";

import { env } from "../../env";
import GetToken from "../../utils/getToken";

export function findPartByCompany({
  company,
  status,
  associatedCorporateUnits,
  deletedBy,
  createdBy,
}) {
  return axios({
    method: "GET",
    url: `partsLibrary/getAllParts`,
    params: { company, status, associatedCorporateUnits, deletedBy, createdBy },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function findPartById({ partId }) {
  return axios({
    method: "GET",
    url: `partsLibrary/getPart`,
    params: { partId },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

//Signatories
export function findSignatoriesByCompany({
  company,
  status,
  associatedCorporateUnits,
  deletedBy,
  createdBy,
}) {
  return axios({
    method: "GET",
    url: `partsLibrary/getAllSignatories`,
    params: { company, status, associatedCorporateUnits, deletedBy, createdBy },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function checkCERLRequest(data) {
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append(
    "associatedCorporateUnits",
    JSON.stringify(data.associatedCorporateUnits)
  );
  formData.append("company", data.company);
  return axios({
    method: "POST",
    url: `thirdPartyRequest/checkCERLRequest`,
    data: formData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      Authorization: GetToken(),
    },
  });
}
