import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import SearchBarCarvajal from "./Components/SearchBarCarvajal";
import { MDBBadge } from "mdbreact";
import { faCircleInfo, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrum from "../../pages/parts/breadcrum";
import SectionTitle from "../../components/sectionTitle";
import MUIDataTable from "mui-datatables";
import LoadingContent from "../../components/LoadingContent";
import { handleStyleStatusRequest } from "../../utils/statusExtenalRequest";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchRequest,
  getRequestList,
  getRequestStatus,
} from "../../reducers/requestSlice";
import { v4 as uuid } from "uuid";
import ModalInfo from "../../components/Modals/ModalInfo";
import { CODES } from "../../utils/codes";
import validatePermissions from "../../services/permissions/permissionsServices";

const DataTableCarvajal = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const [optionsFilterBar, setOptionsFilterBar] = useState([]);
  const [filterMyId, setFilterMyId] = useState(false);
  const dispatch = useDispatch();
  const requestStatus = useSelector(getRequestStatus);
  const requestList = useSelector(getRequestList);

  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState({});

  const columns = [
    {
      label: "No. caso",

      name: "idCons",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "Asunto de la solicitud",
      name: "requestMatter",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "Usuario de servicios",
      name: "lawyer",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "Requiere",
      name: "requestType",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "Fecha de creación",
      name: "creationDate",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "Fecha límite",
      name: "limitDate",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "Estado",
      name: "status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "Empresa",
      name: "company",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "Ver",
      name: "example",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const [DataTable, setDataTable] = useState({
    columns: [],
    rows: [],
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: [],
    rows: [],
  });

  const handleClickViewRequest = (consecutive) => {
    navigate("/external/view/" + consecutive);
  };

  const buildRows = (data) => {
    if (!data.length) return [];
    const ProcessRows = data.map((item) => {
      const { firstName, firstSurname } = item?.attorneyInCharge || {
        firstName: "",
        firstSurname: "",
      };
      const requestType = item.requestType?.type !== "Otrosí"
        ? item.requestType?.type
        : item.requestSubtype;

      return {
        idCons: item?.consecutive || " - ",
        requestMatter: item?.requestSubject || " - ",
        lawyer: !firstName ? "-" : ` ${firstName} ${firstSurname}`,
        requestType: requestType ?? "No aplica",
        creationDate: item?.createdAt || " - ",
        limitDate: ["Solucionada", "Cancelada"].includes(
          item.requestStatus.name
        )
          ? "No aplica"
          : item.responseLimitDate,
        status: (
          <MDBBadge
            color=""
            pill
            style={{
              backgroundColor: handleStyleStatusRequest(
                item?.requestStatus?.colorId
              ),
              fontSize: "1.5rem",
              borderRadius: "0.8rem",
              padding: "0.2rem",
            }}
          >
            <span className="custom-badge">{item.requestStatus.name}</span>
          </MDBBadge>
        ),

        example: !item.example ? (
          <button
            style={{ border: "none", borderRadius: "4px" }}
            onClick={() => handleClickViewRequest(item.consecutive)}
          >
            <FontAwesomeIcon
              className="icon-navbar"
              icon={faEye}
              color="#00374F"
              size="lg"
              style={{
                padding: "0.8rem",
                backgroundColor: "#E7F9FC",
                borderRadius: "0.4rem",
                cursor: "pointer",
              }}
            />
          </button>
        ) : (
          item.example
        ),
        company: !item.applicantCompany ? "--" : item.applicantCompany,
        statusText: item?.requestStatus?.name || "",
      };
    });

    return ProcessRows;
  };

  const actionValidatePermissions = async () => {
    try {
      setIsLoadingPermissions(true);
      const { viewRequest } = await validatePermissions();
      if (!viewRequest) {
        setIsOpenModalUnauthorized(true);
        setResponseDataUnauthorized({
          status: 401,
          data: {
            message:
              "No tiene los permisos necesarios para ejecutar esta acción.",
          },
        });
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingPermissions(false);
    }
  };

  useEffect(() => {
    actionValidatePermissions();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (requestStatus === "fetch") {
          const info = JSON.parse(localStorage.payloadToken);
          const { userId } = info;
          const obj = {
            userId: userId,
            optionsToFilter: optionsFilterBar,
          };
          await dispatch(fetchRequest(obj));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [requestStatus, dispatch]);

  useEffect(() => {
    if (requestStatus === "success") {
      const formatRows = buildRows(requestList);
      setFilteredDataTable({
        columns: columns,
        rows: formatRows,
      });
      setDataTable({
        columns: columns,
        rows: formatRows,
      });
    }
  }, [requestList]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && isLoadingPermissions && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Solicitudes"} />
      </Row>
      <div
        className="data-table dt-list-items"
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <p className="label__description">
          Estas son las solicitudes de contratos y otrosí que has generado, da
          click en una solicitud para ver su contenido a detalle.
        </p>
        <br />
        <Row>
          <Col>
            <SearchBarCarvajal
              DataTable={DataTable}
              setDataTable={setDataTable}
              filteredDataTable={filteredDataTable}
              setFilteredDataTable={setFilteredDataTable}
              setOptionsFilterBar={setOptionsFilterBar}
              filterMyId={filterMyId}
              setFilterMyId={setFilterMyId}
            />
          </Col>
        </Row>

        <Row style={{ margin: "2% 0" }}>
          <MUIDataTable
            data={filteredDataTable.rows}
            columns={filteredDataTable.columns}
            options={{
              download: false,
              print: false,
              viewColumns: false,
              filter: false,
              search: false,
              selectableRowsHideCheckboxes: true,
              rowsPerPage: 8,
              rowsPerPageOptions: [8, 10, 15],
            }}
            components={{
              TableHead: TableHeadComponent,
              TableBody: TableBodyComponent,
            }}
          />
          <Row style={{ marginTop: "10px" }}>
            <p
              style={{
                color: "#676879",
                marginTop: "2.5rem",
                marginBottom: "2rem",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faCircleInfo}
                size={"lg"}
                style={{ marginRight: "5px" }}
              />
              Si quieres saber más acerca de los estados de una solicitud
              <a
                style={{
                  color: "#17B4BC",
                }}
                href={"/external/infoStatus"}
                target="_blank"
              >
                {" "}
                Haz click aquí
              </a>
            </p>
          </Row>
        </Row>
      </div>
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => {
          navigate("/external/create");
        }}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

const TableHeadComponent = (props) => {
  return (
    <thead key={props.tableId}>
      <tr>
        {props.columns.map((column) => (
          <th key={column.label}>
            <label
              style={{
                fontFamily: "Roboto,sans-serif",
                fontSize: "1.2rem",
                fontWeight: "500",
                lineHeight: "1.6rem",
                display: "flex",
                justifyContent: "center",
              }}
              className="default-text--xs text-color--dt-column"
            >
              {column.label}
            </label>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const TableBodyComponent = (props) => {
  return (
    <tbody key={props.tableId}>
      {props.data.map((row, index) => {
        if (
          index <= props.page * props.rowsPerPage + props.rowsPerPage &&
          index >= props.page * props.rowsPerPage
        ) {
          return (
            <tr key={row?.dataIndex}>
              {row.data.map((request) => {
                return (
                  <td key={uuid()}>
                    <label
                      style={{
                        fontFamily: "Roboto,sans-serif",
                        fontSize: "1.2rem",
                        fontWeight: "400",
                        lineHeight: "1.6rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {request}
                    </label>
                  </td>
                );
              })}
            </tr>
          );
        }
      })}
    </tbody>
  );
};

export default DataTableCarvajal;
