import { Col, Form } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { REGEXP } from "../../utils/regexp";
import { Checkbox, FormControl, InputBase, ListItemText, MenuItem, Select, styled } from "@mui/material";
import { useState } from "react";
import { isDefined } from "../../utils/validations";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "maxparent",
    },
  },
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    border: '0.1rem solid #c5c7d0',
    fontSize: '1.4rem',
    alignContent: "center",
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      borderRadius: 4,
      borderColor: '#045380',
    },
    fontFamily: '"Roboto", sans-serif',
    fontWeight: '100'
  },
}));

const defaultProps = {
  register: () => { },
  unregister: () => { },
  setValue: () => { },
  watch: () => { },
  setError: () => { },
  errors: {},
  field: {},
  index: null,
  idxSection: null,
};

const getErrorMessageSubType = ({errors = {}, idxSection = null, index = null}) => {
  if (isDefined(idxSection)) {
    return errors?.section?.[idxSection]?.answers?.[index]?.subType
    ?.message;
  } else {
    return errors?.fields?.[index]?.answer?.[index]?.subType?.message;
  }
};

const getErrorMessage = ({errors = {}, idxSection = null, index = null}) => {
  if (isDefined(idxSection)) {
    return errors?.section?.[idxSection]?.answers?.[index]?.answer?.message;
  } else {
    return errors?.fields?.[index]?.answer?.message;
  }
};

const getBsPrefix = ({errors = {}, idxSection = null, index = null, type = ""}) => {
  let errorMessage = "";
  if (type === "type") {
    errorMessage = getErrorMessage({errors, idxSection, index});
  } else if ("subtype") {
    errorMessage = getErrorMessageSubType({errors, idxSection, index});
  }
  return errorMessage ? "input-group-container__error form__input" : "form__input"
};

const FormFieldList = (props) => {

  const mergedProps = { ...defaultProps, ...props };

  const {
    register,
    unregister,
    setValue,
    watch,
    setError,
    errors,
    field,
    index,
    idxSection
  } = mergedProps;

  const pathToRegister = isDefined(idxSection)
    ? `section.${idxSection}.answers.${index}`
    : `fields.${index}`;
  const errorMessage = getErrorMessage(errors, idxSection, index);

  return (
    <>
      <Col md={4}>
        <Form.Label
          className="form__label"
          {...register(`${pathToRegister}.question`, {
            value: field.value,
          })}
        >
          {field.value}
          {field?.validations?.required?.value && "*"}
        </Form.Label>
      </Col>
      <Col md={5}>
        <Form.Select
          as="select"
          defaultValue={""}
          bsPrefix={getBsPrefix({errors, idxSection, index, type: "type" })}
          {...register(`${pathToRegister}.answer`, {
            required: {
              value: field?.validations?.required?.value,
              message: field?.validations?.required?.message,
            },
          })}
          onChange={(event) => {
            setValue(`${pathToRegister}.answer`, event.target.value);
            setError(`${pathToRegister}.answer`, "");
            if (event.target.value !== field.subtype?.answerToShow) {
              unregister(`${pathToRegister}.subType`);
            }
          }}
        >
          <option className="label" value="" disabled>
            Selecciona
          </option>
          {field.options.map((value) => (
            <option key={value} className="label" value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
        <span className="caption custom-input__error">{errorMessage}</span>
      </Col>
      <Col md={3}>
        {field?.subtypes?.length > 0 &&
          field?.subtypes.map((subfield) => {
            const errorMessageSubtype = getErrorMessageSubType({errors, idxSection, index});

            return (
              <div key={uuid()}>
                {watch(`${pathToRegister}.answer`) ===
                  subfield?.answerToShow && (
                    <Form.Control
                      as="textarea"
                      {...register(`${pathToRegister}.subType`, {
                        required: {
                          value: subfield?.validations?.required?.value,
                          message: subfield?.validations?.required?.message,
                        },
                        minLength: subfield?.validations?.minLength && {
                          value: subfield?.validations?.minLength?.value,
                          message: subfield?.validations?.minLength?.message,
                        },
                        maxLength: subfield?.validations?.maxLength && {
                          value: subfield?.validations?.maxLength?.value,
                          message: subfield?.validations?.maxLength?.message,
                        },
                        pattern: subfield?.validations?.pattern && {
                          value: REGEXP[subfield?.validations?.pattern?.value],
                          message: subfield?.validations?.pattern?.message,
                        },
                      })}
                      placeholder={
                        subfield?.placeholder || "Ingrese su respuesta aquí"
                      }
                      bsPrefix={getBsPrefix({ errors, idxSection, index, type: "subtype"})}
                      maxLength={
                        subfield?.validations?.maxLength?.value || "1000"
                      }
                    />
                  )}

                {watch(`${pathToRegister}.answer`) === "Otro(s)" &&
                  subfield?.validations?.maxLength?.value && (
                    <span className="caption__primary-color">{`Máximo ${subfield?.validations?.maxLength?.value} caracteres`}</span>
                  )}
                {watch(`${pathToRegister}.answer`) === "Otro(Explique)" &&
                  subfield?.validations?.maxLength?.value && (
                    <span className="caption__primary-color">{`Máximo ${subfield?.validations?.maxLength?.value} caracteres`}</span>
                  )}

                <span className="caption custom-input__error">
                  {errorMessageSubtype}
                </span>
              </div>
            );
          })}
      </Col>
    </>
  );
};

const FormFieldInput = (props) => {

  const mergedProps = { ...defaultProps, ...props };

  const {
    register,
    errors, 
    field,
    index,
    idxSection 
  } = mergedProps;

  const pathToRegister = isDefined(idxSection)
    ? `section.${idxSection}.answers.${index}`
    : `fields.${index}`;
  const errorMessage = getErrorMessage({errors, idxSection, index})

  return (
    <>
      <Col md={4}>
        <Form.Label
          className="form__label"
          {...register(`${pathToRegister}.question`, {
            value: field.value,
          })}
        >
          {field.value}
          {field?.validations?.required?.value && "*"}
        </Form.Label>
        <br></br>
        {field?.validations?.maxLength?.value && (
          <span className="caption__primary-color">
            {" "}
            {`Máximo ${field?.validations?.maxLength?.value} caracteres`}
          </span>
        )}
      </Col>
      <Col md={4}>
        <Form.Control
          as="textarea"
          bsPrefix={getBsPrefix({ errors, idxSection, index, type: "type"})}
          placeholder={
            field.placeholder
              ? field.placeholder
              : "Ingrese su respuesta aquí..."
          }
          className="form__input"
          {...register(`${pathToRegister}.answer`, {
            required: {
              value: field?.validations?.required?.value,
              message: field?.validations?.required?.message,
            },
            minLength: field?.validations?.minLength && {
              value: field?.validations?.minLength?.value,
              message: field?.validations?.minLength?.message,
            },
            maxLength: field?.validations?.maxLength && {
              value: field?.validations?.maxLength?.value,
              message: field?.validations?.maxLength?.message,
            },
            pattern: field?.validations?.pattern && {
              value: REGEXP[field?.validations?.pattern?.value],
              message: field?.validations?.pattern?.message,
            },
          })}
          maxLength={
            field?.validations?.maxLength
              ? field?.validations?.maxLength?.value
              : "1000"
          }
        />
        <span className="caption custom-input__error">{errorMessage}</span>
      </Col>
    </>
  );

};

const FormFieldListInput = (props) => {

  const mergedProps = { ...defaultProps, ...props };

  const {
    register,
    errors, 
    field,
    index,
    idxSection 
  } = mergedProps;

  const pathToRegister = isDefined(idxSection)
    ? `section.${idxSection}.answers.${index}`
    : `fields.${index}`;

  return (
    <>
      <Col md={4}>
        <Form.Label
          className="form__label"
          {...register(`${pathToRegister}.question`, {
            value: field.value,
          })}
        >
          {field.value}
          {field?.validations?.required?.value && "*"}
        </Form.Label>
      </Col>
      <Col xs={"auto"}>
        <Form.Select
          as="select"
          defaultValue={""}
          bsPrefix={getBsPrefix({errors, idxSection, index, type: "type"})}
          {...register(`${pathToRegister}.answer1`, {
            required: {
              value: field?.validations?.required?.value,
              message: field?.validations?.required?.message,
            },
          })}
        >
          <option className="label" value="" disabled>
            Selecciona
          </option>
          {field.options.map((value) => (
            <option key={value} className="label" value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
        <span className="caption custom-input__error">
          {errors?.section?.[idxSection]?.answers?.[index]?.answer1?.message || ""}
        </span>
      </Col>
      <Col md={4}>
        <Form.Control
          type="text"
          bsPrefix={
            errors?.section?.[idxSection]?.answers?.[index]?.answer2?.message
              ? "input-group-container__error form__input"
              : "form__input"
          }
          placeholder={
            field.placeholder
              ? field.placeholder
              : "Ingrese su respuesta aquí..."
          }
          className="form__input"
          {...register(`${pathToRegister}.answer2`, {
            required: {
              value: field?.validations?.required?.value,
              message: field?.validations?.required?.message,
            },
            minLength: field?.validations?.minLength && {
              value: field?.validations?.minLength?.value,
              message: field?.validations?.minLength?.message,
            },
            maxLength: field?.validations?.maxLength && {
              value: field?.validations?.maxLength?.value,
              message: field?.validations?.maxLength?.message,
            },
            pattern: field?.validations?.pattern && {
              value: REGEXP[field?.validations?.pattern?.value],
              message: field?.validations?.pattern?.message,
            },
          })}
          maxLength={field?.validations?.maxLength?.value || "1000"}
        />
        <span className="caption custom-input__error">
          {errors?.section?.[idxSection]?.answers?.[index]?.answer2?.message || ""}
        </span>
      </Col>
    </>
  );
};

const FormFieldListCheckbox = (props) => {

  const mergedProps = { ...defaultProps, ...props };

  const {
    register,
    setValue,
    watch, 
    setError,
    errors, 
    field,
    index,
    idxSection 
  } = mergedProps;

  const pathToRegister = isDefined(idxSection)
    ? `section.${idxSection}.answers.${index}`
    : `fields.${index}`;
  const errorMessage = getErrorMessage({errors, idxSection, index})

  const handleCheck = (event) => {
    if (event.target.checked) {
      setValue(`${pathToRegister}.answer`, field?.label);
      setError(`${pathToRegister}.answer`, "");
    } else {
      setValue(`${pathToRegister}.answer`, "");
    }
  };

  return (
    <>
      <Col md={4}>
        <Form.Label
          className="form__label"
          {...register(`${pathToRegister}.question`, {
            value: field.value,
          })}
        >
          {field.value}
          {field?.validations?.required?.value && "*"}
        </Form.Label>
      </Col>
      <Col xs={"auto"}>
        <Form.Select
          disabled={watch(`${pathToRegister}.check`)}
          as="select"
          defaultValue={""}
          bsPrefix={getBsPrefix({ errors, idxSection, index, type: "type"})}
          {...register(`${pathToRegister}.answer`, {
            required: {
              value:
                field?.validations?.required?.value &&
                !watch(`${pathToRegister}.check`),
              message: field?.validations?.required?.message,
            },
          })}
        >
          <option className="label" value="" disabled>
            Selecciona
          </option>
          {field.options.map((value) => (
            <option key={value} className="label" value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
        <span className="caption custom-input__error">{errorMessage}</span>
      </Col>
      <Col xs={"auto"}>
        <Form.Check
          {...register(`${pathToRegister}.check`)}
          type={"checkbox"}
          label={field?.label}
          className="form__label"
          onClick={handleCheck}
        />
      </Col>
    </>
  );
};

const FormFieldInputCheckbox = (props) => {

  const mergedProps = { ...defaultProps, ...props };

  const {
    register,
    setValue,
    watch, 
    setError,
    errors, 
    field,
    index,
    idxSection 
  } = mergedProps;

  const pathToRegister = isDefined(idxSection)
    ? `section.${idxSection}.answers.${index}`
    : `fields.${index}`;
  const errorMessage = getErrorMessage({errors, idxSection, index})

  const handleCheck = (event) => {
    if (event.target.checked) {
      setValue(`${pathToRegister}.answer`, field?.label);
      setError(`${pathToRegister}.answer`, "");
    } else {
      setValue(`${pathToRegister}.answer`, "");
    }
  };

  return (
    <>
      <Col md={4}>
        <Form.Label
          className="form__label"
          {...register(`${pathToRegister}.question`, {
            value: field.value,
          })}
        >
          {field.value}
          {field?.validations?.required?.value && "*"}
        </Form.Label>
        <br></br>
        {field?.validations?.maxLength?.value && (
          <span className="caption__primary-color">{`Máximo ${field?.validations?.maxLength?.value} caracteres`}</span>
        )}
      </Col>
      <Col md={5}>
        <Form.Control
          disabled={watch(`${pathToRegister}.check`)}
          bsPrefix={getBsPrefix({ errors, idxSection, index, type: "type"})}
          placeholder={
            field.placeholder
              ? field.placeholder
              : "Ingrese su respuesta aquí..."
          }
          className="form__input"
          {...register(`${pathToRegister}.answer`, {
            required: {
              value:
                field?.validations?.required?.value &&
                !watch(`${pathToRegister}.check`),
              message: field?.validations?.required?.message,
            },
            minLength: !watch(`${pathToRegister}.check`) &&
              field?.validations?.minLength && {
              value: field?.validations?.minLength?.value,
              message: field?.validations?.minLength?.message,
            },
            maxLength: !watch(`${pathToRegister}.check`) &&
              field?.validations?.maxLength && {
              value: field?.validations?.maxLength?.value,
              message: field?.validations?.maxLength?.message,
            },
            pattern: !watch(`${pathToRegister}.check`) &&
              field?.alidations?.pattern && {
              value: REGEXP[field?.validations?.pattern?.value],
              message: field?.validations?.pattern?.message,
            },
          })}
          maxLength={
            field?.validations?.maxLength
              ? field?.validations?.maxLength?.value
              : "1000"
          }
        />
        <span className="caption custom-input__error">{errorMessage}</span>
      </Col>
      <Col xs={"auto"}>
        <Form.Check
          {...register(`${pathToRegister}.check`)}
          type={"checkbox"}
          label={field?.label}
          className="form__label"
          onClick={handleCheck}
        />
      </Col>
    </>
  );
};

const FormFieldListInputCheckbox = (props) => {

  const mergedProps = { ...defaultProps, ...props };

  const {
    register,
    setValue,
    watch, 
    setError,
    errors, 
    field,
    index,
    idxSection 
  } = mergedProps;

  const pathToRegister = isDefined(idxSection)
    ? `section.${idxSection}.answers.${index}`
    : `fields.${index}`;

  const handleCheck = (event) => {
    if (event.target.checked) {
      setValue(`${pathToRegister}.answer`, field?.label);
      setValue(`${pathToRegister}.answer1`, "");
      setValue(`${pathToRegister}.answer2`, "");
      setError(`${pathToRegister}.answer1`, "");
      setError(`${pathToRegister}.answer2`, "");
    } else {
      setValue(`${pathToRegister}.answer`, "");
      setValue(`${pathToRegister}.answer1`, "");
      setValue(`${pathToRegister}.answer2`, "");
    }
  };

  return (
    <>
      <Col md={4}>
        <Form.Label
          className="form__label"
          {...register(`${pathToRegister}.question`, {
            value: field.value,
          })}
        >
          {field.value}
          {field?.validations?.required?.value && "*"}
        </Form.Label>
        <br></br>
        {field?.validations?.maxLength?.value && (
          <span className="caption__primary-color">{`Máximo ${field?.validations?.maxLength?.value} caracteres`}</span>
        )}
      </Col>
      <Col xs={"auto"}>
        <Form.Select
          disabled={watch(`${pathToRegister}.check`)}
          as="select"
          defaultValue={""}
          bsPrefix={
            errors?.section?.[idxSection]?.answers?.[index]?.answer1?.message
              ? "input-group-container__error form__input"
              : "form__input"
          }
          {...register(`${pathToRegister}.answer1`, {
            required: {
              value:
                field?.validations?.required?.value &&
                !watch(`${pathToRegister}.check`),
              message: field?.validations?.required?.message,
            },
          })}
        >
          <option className="label" value="" disabled>
            Selecciona
          </option>
          {field.options.map((value) => (
            <option key={value} className="label" value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
        <span className="caption custom-input__error">
          {errors?.section?.[idxSection]?.answers?.[index]?.answer1?.message}
        </span>
      </Col>
      <Col md={4}>
        <Form.Control
          disabled={watch(`${pathToRegister}.check`)}
          as="textarea"
          bsPrefix={
            errors?.section?.[idxSection]?.answers?.[index]?.answer2?.message
              ? "input-group-container__error form__input"
              : "form__input"
          }
          placeholder={
            field.placeholder
              ? field.placeholder
              : "Ingrese su respuesta aquí..."
          }
          className="form__input"
          {...register(`${pathToRegister}.answer2`, {
            required: {
              value:
                field?.validations?.required?.value &&
                !watch(`${pathToRegister}.check`),
              message: field?.validations?.required?.message,
            },
            minLength: !watch(`${pathToRegister}.check`) &&
              field?.validations?.minLength && {
              value: field?.validations?.minLength?.value,
              message: field?.validations?.minLength?.message,
            },
            maxLength: !watch(`${pathToRegister}.check`) &&
              field?.validations?.maxLength && {
              value: field?.validations?.maxLength?.value,
              message: field?.validations?.maxLength?.message,
            },
            pattern: !watch(`${pathToRegister}.check`) &&
              field?.validations?.pattern && {
              value: REGEXP[field?.validations?.pattern?.value],
              message: field?.validations?.pattern?.message,
            },
          })}
          maxLength={
            field?.validations?.maxLength
              ? field?.validations?.maxLength?.value
              : "1000"
          }
        />
        <span className="caption custom-input__error">
          {errors?.section?.[idxSection]?.answers?.[index]?.answer2?.message}
        </span>
      </Col>
      <Col xs={"auto"}>
        <Form.Check
          {...register(`${pathToRegister}.check`)}
          type={"checkbox"}
          label={field?.label}
          className="form__label"
          onClick={handleCheck}
        />
      </Col>
    </>
  );
};

const FormFieldDate = (props) => {

  const mergedProps = { ...defaultProps, ...props };

  const {
    register,
    errors, 
    field,
    index,
    idxSection 
  } = mergedProps;

  const pathToRegister = isDefined(idxSection)
    ? `section.${idxSection}.answers.${index}`
    : `fields.${index}`;
  const errorMessage = isDefined(idxSection)
    ? errors?.section?.[idxSection]?.answers?.[index]?.answer?.message
    : errors?.fields?.[index]?.answer?.message;

  return (
    <>
      <Col md={4}>
        <Form.Label
          className="form__label"
          {...register(`${pathToRegister}.question`, {
            value: field.value,
          })}
        >
          {field.value}
          {field?.validations?.required?.value && "*"}
        </Form.Label>
      </Col>
      <Col md={5}>
        <Form.Control
          type="date"
          className={
            errorMessage
              ? "input-group-container__error form__date"
              : "form__date"
          }
          {...register(`${pathToRegister}.answer`, {
            required: {
              value: field?.validations?.required?.value,
              message: field?.validations?.required?.message,
            },
          })}
          placeholder="AAAA-MM-DD"
        />
        <span className="caption custom-input__error">{errorMessage}</span>
      </Col>
    </>
  );
};

const FormFieldDateCheckbox = (props) => {

  const mergedProps = { ...defaultProps, ...props };

  const {
    register,
    setValue,
    watch, 
    setError,
    errors, 
    field,
    index,
    idxSection 
  } = mergedProps;

  const pathToRegister = isDefined(idxSection)
    ? `section.${idxSection}.answers.${index}`
    : `fields.${index}`;

  const handleCheck = (event) => {
    if (event.target.checked) {
      setValue(`${pathToRegister}.answer`, field?.label);
      setError(`${pathToRegister}.answer`, "");
    } else {
      setValue(`${pathToRegister}.answer`, "");
    }
  };

  return (
    <>
      <Col md={4}>
        <Form.Label
          className="form__label"
          {...register(`${pathToRegister}.question`, {
            value: field.value,
          })}
        >
          {field.value}
          {field?.validations?.required?.value && "*"}
        </Form.Label>
      </Col>
      <Col md={5}>
        <Form.Control
          disabled={watch(`${pathToRegister}.check`)}
          type="date"
          className={
            errors?.section?.[idxSection]?.answers?.[index]?.answer?.message
              ? "input-group-container__error form__date"
              : "form__date"
          }
          {...register(`${pathToRegister}.answer`, {
            required: {
              value: field?.validations?.required?.value,
              message: field?.validations?.required?.message,
            },
          })}
          placeholder="AAAA-MM-DD"
        />
        <span className="caption custom-input__error">
          {errors?.section?.[idxSection]?.answers?.[index]?.answer?.message}
        </span>
      </Col>
      <Col xs={"auto"}>
        <Form.Check
          {...register(`${pathToRegister}.check`)}
          type={"checkbox"}
          label={field?.label}
          className="form__label"
          onClick={handleCheck}
        />
      </Col>
    </>
  );
};

const FormFieldMulitpleListCheckbox = (props) => {

  const mergedProps = { ...defaultProps, ...props };

  const {
    register,
    errors, 
    field,
    index,
    idxSection 
  } = mergedProps;

  const pathToRegister = isDefined(idxSection)
    ? `section.${idxSection}.answers.${index}`
    : `fields.${index}`;

  const [itemsSelected, setItemsSelected] = useState([]);
  const handleChange = (event, name) => {
    const { target: { checked } } = event;
    if (checked) {
      setItemsSelected(old => [...old, name]);
    } else {
      setItemsSelected(old => old.filter(item => item !== name));
    }
  }
  return (
    <>
      <Col md={4}>
        <Form.Label
          className="form__label"
          {...register(`${pathToRegister}.question`, {
            value: field.value,
          })}
        >
          {field.value}
          {field?.validations?.required?.value && "*"}
        </Form.Label>
      </Col>
      <Col xs={"auto"}>
        <FormControl sx={{
          minWidth: "300px",
          maxWidth: "300px",
          width: '100%',
          margin: '0 !important'
        }}>
          <Select
            {...register(`${pathToRegister}.answer`, {
              required: {
                value: field?.validations?.required?.value,
                message: field?.validations?.required?.message,
              },
              validate: (value) => {
                const isValueNotValid = value?.includes("Selecciona");
                if (value === "Selecciona" || isValueNotValid) return "*Este campo es obligatorio";
              },
              onChange: ({ target: { value } }) => {
                if (value === "Selecciona") return;
              }
            })}
            variant="filled"
            id="multiple-checkbox"
            multiple
            value={itemsSelected.length ? itemsSelected : ["Selecciona"]}
            input={<BootstrapInput />}
            renderValue={(selected) => selected?.join(', ')}
            MenuProps={MenuProps}
            sx={{
              '& .MuiSelect-icon': {
                color: "#000000 !important",
                fontSize: "2rem"
              }
            }}
          >
            {field.options.map((name) => (
              <MenuItem key={name} value={name} onClick={() => { }}>
                <ListItemText
                  primaryTypographyProps={{
                    fontFamily: "Roboto",
                    fontSize: "1.4rem",
                    fontWeight: "regular",
                    lineHeight: "2rem",
                    color: "#676879",
                  }}
                  primary={name} />
                <Checkbox
                  checked={itemsSelected.some(nn => nn === name)}
                  onChange={(event) => handleChange(event, name)}
                />
              </MenuItem>
            ))}
            {field.options.map((name) => (
              <MenuItem key={name} value={name + "2"}>
                <ListItemText
                  primaryTypographyProps={{
                    fontFamily: "Roboto",
                    fontSize: "1.4rem",
                    fontWeight: "regular",
                    lineHeight: "2rem",
                    color: "#676879",
                  }}
                  primary={name + "2"} />
                <Checkbox
                  checked={itemsSelected.some(nn => nn === name + "2")}
                  onChange={(event) => handleChange(event, name + "2")}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <span className="caption custom-input__error">
          {errors?.section?.[idxSection]?.answers?.[index]?.answer?.message}
        </span>
      </Col>
    </>
  )
};

export {
  FormFieldList,
  FormFieldInput,
  FormFieldListInput,
  FormFieldListCheckbox,
  FormFieldInputCheckbox,
  FormFieldListInputCheckbox,
  FormFieldDate,
  FormFieldDateCheckbox,
  FormFieldMulitpleListCheckbox
};