import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import LoadingContent from "../../../../components/LoadingContent";
import Breadcrum from "../../../../pages/parts/breadcrum";
import SectionTitle from "../../../../components/sectionTitle";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DiscussionBoard from "../../../../components/ExternalRequest/DiscussionBoard";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { MDBDataTableV5 } from "mdbreact";
import AddIcon from "@mui/icons-material/Add";
import { findOneDataExternalRequest } from "../../../../services/CarvServices/CarvajalServices";
import { CODES } from "../../../../utils/codes";
import { useParams, useNavigate } from "react-router-dom";
import { dateNormal } from "../../../../resources/getDate";
import ClearIcon from "@mui/icons-material/Clear";
import {
  addCommentApproval,
  sendApprovalAgain,
  uploadDocumentForApproval,
  getApprovalById,
} from "../../../../services/Approvals/ApprovalServices";
import ModalDecision from "../../../../components/Modals/ModalDecision";
import ModalUploadDocs from "../../../../components/Modals/ModalUploadDocs";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { downloadDocumentByFilenameAndBucketName } from "../../../../utils/downloadDocuments";
import { filterDataRequest, getTextFollowup } from "../utils/aprovalsUtils";
import { APPROVAL_STATE } from "../../../../utils/approvalsState";

const RequestApprovalAgain = () => {
  //Hooks
  const { consecutive, type, approvalId } = useParams();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    message: yup.string().required("*Este campo es obligatorio"),
    file: yup.mixed().test("Required", "*Debe subir un documento", (value) => type !== "upload" || !!value),
    documentId: type === "history" ? yup.string().nullable().required("*Este campo es obligatorio") : yup.string().nullable().default(""),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //State
  const [isLoading, setIsLoading] = useState(false);
  const [isHideDiscussion, setIsHideDiscussion] = useState(true);
  const [documentList, setDocumentsList] = useState("");
  const [isModalRequest, setIsModalRequest] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [requestExternalData, setRequestExternalData] = useState({});
  const [openModalDoc, setOpenModalDoc] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const [approvalData, setApprovalData] = useState({});
  const [requestCommentaries, setRequestCommentaries] = useState({
    _id: "",
    comments: [],
  });
  const [dataTableDocumentPrincipal, setDataTableDocumentPrincipal] = useState({
    columns: [],
    rows: [],
  });
  const [isOpenModalTypeDocument, setIsOpenModalTypeDocument] = useState(false);

  //Handler
  const handlePreRequest = (data) => {
    setDataForm(data);
    if (type !== "upload" && documentList) {
      const {
        documentPrincipal,
        documentsAttachments,
        documentsOtherApplicant,
        documentsOtherAttorney,
      } = documentList;
      const docs = [
        documentPrincipal,
        documentsAttachments,
        documentsOtherApplicant,
        documentsOtherAttorney,
      ].flat();
      const docFilter = docs.find((doc) => doc._id === data.documentId);
      const typeDocument = docFilter.filenameInBucket.split(".").pop();
      if (typeDocument !== "pdf") {
        setIsOpenModalTypeDocument(true);
        return;
      }
    }
    setIsModalRequest(true);
  };
  const handleClickDownload = async ({ filenameInBucket, bucketName }) => {
    try {
      setIsLoading(true);
      await downloadDocumentByFilenameAndBucketName({
        filenameInBucket,
        bucketName,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSearchName = () => {
    if (documentList) {
      for (const document of documentList.documentPrincipal) {
        if (document._id === getValues("documentId")) {
          return document.originalFilename;
        }
      }
      for (const document of documentList.documentsAttachments) {
        if (document._id === getValues("documentId")) {
          return document.originalFilename;
        }
      }
    }
  };
  const handleRequestApprovalAgain = async () => {
    try {
      setIsLoading(true);
      setIsOpenModalTypeDocument(false);
      setIsModalRequest(false);
      const user = JSON.parse(localStorage.getItem("payloadToken"));
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      let userName = "";
      if (user.secondName && user.secondSurname) {
        userName = `${user.firstName} ${user.secondName} ${user.firstSurname} ${user.secondSurname}`;
      } else if (user.secondName) {
        userName = `${user.firstName} ${user.secondName} ${user.firstSurname}`;
      } else {
        userName = `${user.firstName} ${user.firstSurname} ${user.secondSurname}`;
      }
      const userRole = localStorage.getItem("role");
      const updateApproval = {
        associatedDocument: dataForm.documentId,
        state: APPROVAL_STATE.PENDING._id,
      };
      const requestApprovalAgain = await sendApprovalAgain({
        consecutive,
        approvalId,
        updateApproval,
        companyId,
        corporateUnit,
      });
      setIsModalRequest(false);
      if (
        requestApprovalAgain.status === CODES.COD_RESPONSE_HTTP_OK ||
        requestApprovalAgain.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        const approvalComment = {
          userName: userName,
          aplicantName:
            requestApprovalAgain.data.responseMessage.data.aplicantName,
          approverName: dataForm.name,
          userPosition: userRole === "EXTERNAL" ? "Solicitante" : "Abogado",
          message: dataForm.message,
          associatedDocument: dataForm.documentId,
          isCommentOnly: false,
          isSendMail: true,
        };
        const requestCreateCommentApproval = await addCommentApproval({
          consecutive,
          approvalId: requestApprovalAgain.data.responseMessage.data._id,
          approvalComment,
          isDiscussionComment: true,
          companyId,
          corporateUnit
        });
        if (
          requestCreateCommentApproval.status === CODES.COD_RESPONSE_HTTP_OK ||
          requestCreateCommentApproval.status === CODES.COD_RESPONSE_HTTP_CREATED
        ) {
          navigate(-1);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleRequestApprovalAgainFromPC = async () => {
    try {
      setIsLoading(true);
      setIsOpenModalTypeDocument(false);
      setIsModalRequest(false);
      const user = JSON.parse(localStorage.getItem("payloadToken"));
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      let userName = `${user?.firstName || ""} ${user?.secondName || ""} ${
        user?.firstSurname || ""
      } ${user?.secondSurname || ""}`;
      const requestUploadDocs = await uploadDocumentForApproval({
        companyId,
        applicantId: approvalId,
        consecutive,
        principalDocument: documentData,
        attorneyName: requestExternalData.attorneyName,
        applicantName: requestExternalData.aplicantName,
        comment: "Aprobación",
        corporateUnit
      });
      if (
        requestUploadDocs.status === CODES.COD_RESPONSE_HTTP_OK ||
        requestUploadDocs.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        const updateApproval = {
          associatedDocument:
            requestUploadDocs.data.responseMessage.data.lastMainDocumentId,
          state: APPROVAL_STATE.PENDING._id,
        };
  
        const requestApprovalAgain = await sendApprovalAgain({
          consecutive,
          approvalId,
          updateApproval,
          corporateUnit,
          companyId
        });
        setIsModalRequest(false);
        if (
          requestApprovalAgain.status === CODES.COD_RESPONSE_HTTP_OK ||
          requestApprovalAgain.status === CODES.COD_RESPONSE_HTTP_CREATED
        ) {
          const approvalComment = {
            userName: userName,
            aplicantName:
              requestApprovalAgain.data.responseMessage.data.aplicantName,
            approverName:
              requestApprovalAgain.data.responseMessage.data.approverName,
            userPosition: "Solicitante",
            message: dataForm.message,
            associatedDocument: requestUploadDocs?.data?.responseMessage?.data,
            isCommentOnly: false,
            isSendMail: true,
          };
          const requestCreateCommentApproval = await addCommentApproval({
            consecutive,
            approvalId: requestApprovalAgain.data.responseMessage.data._id,
            approvalComment,
            isDiscussionComment: true,
            companyId,
            corporateUnit
          });
          if (
            requestCreateCommentApproval.status === CODES.COD_RESPONSE_HTTP_OK ||
            requestCreateCommentApproval.status ===
              CODES.COD_RESPONSE_HTTP_CREATED
          ) {
            setIsModalRequest(false);
            navigate(-1);
          }
        }
      } else {
        setIsModalRequest(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFullName = ({
    firstName = "",
    firstSurname = "",
    secondName = "",
    secondSurname = "",
  }) => `${firstName} ${secondName} ${firstSurname} ${secondSurname}`;

  //UseEffect
  useEffect(() => {
    const handleRequestDocument = async () => {
      try {
        setIsLoading(true);
        const { company, userId } = JSON.parse(
          localStorage.getItem("payloadToken")
        );
        const { _id } = JSON.parse(localStorage.getItem("corporateUnit"));
        const obj = {
          consecutive: consecutive,
          company: company,
          corporateUnitId: _id,
          userId: userId,
        };
        const GetDataRequest = await findOneDataExternalRequest(obj);
        if (GetDataRequest.status) {
          if (GetDataRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
            const data = GetDataRequest.data.responseMessage;
            const aplicantName = getFullName({
              firstName: data?.applicant?.firstName,
              secondName: data?.applicant?.secondName,
              firstSurname: data?.applicant?.firstSurname,
              secondSurname: data?.applicant?.secondSurname,
            });
            const attorneyName = getFullName({
              firstName: data?.attorneyInCharge?.firstName,
              secondName: data?.attorneyInCharge?.secondName,
              firstSurname: data?.attorneyInCharge?.firstSurname,
              secondSurname: data?.attorneyInCharge?.secondSurname,
            });
            setRequestExternalData({ aplicantName, attorneyName });
            const dataFilter = filterDataRequest(data);
            setDocumentsList(dataFilter);
            setDataTableDocumentPrincipal({
              columns: [
                {
                  label: [
                    <label aria-hidden="true" key="1">
                      Seleccionar documento
                    </label>,
                  ],
                  field: "select",
                },
                {
                  label: [
                    <label aria-hidden="true" key="1">
                      Seguimiento
                    </label>,
                  ],
                  field: "followup",
                },
                {
                  label: [
                    <label aria-hidden="true" key="2">
                      Descripción
                    </label>,
                  ],
                  field: "description",
                },
                {
                  label: [
                    <label aria-hidden="true" key="3">
                      Creado por
                    </label>,
                  ],
                  field: "createdBy",
                },
                {
                  label: [
                    <label aria-hidden="true" key="4">
                      Dirigido a
                    </label>,
                  ],
                  field: "assignedTo",
                },
                {
                  label: [
                    <label aria-hidden="true" key="5">
                      Fecha de creación
                    </label>,
                  ],
                  field: "createdAt",
                },
                {
                  label: [
                    <label aria-hidden="true" key="6">
                      Documento Adjunto
                    </label>,
                  ],
                  field: "originalFilename",
                },
              ],

              rows: dataFilter?.documentPrincipal
                .filter((document) => {
                  return !document.isRejected;
                })
                .reverse()
                .map((filterDocument) => {
                  return {
                    select: (
                      <input
                        {...register("documentId", { required: true })}
                        type="radio"
                        name="documentId"
                        value={filterDocument._id}
                      />
                    ),
                    description: filterDocument.description,
                    createdAt: dateNormal(filterDocument.createdAt),
                    createdBy: filterDocument.createdBy,
                    assignedTo: filterDocument.assignedTo,
                    followup: getTextFollowup(filterDocument),
                    originalFilename: (
                      <label
                        onClick={() => {
                          handleClickDownload(filterDocument);
                        }}
                        style={{
                          color: "#17B4BC",
                          cursor: "pointer",
                          fontSize: "1.5rem",
                          borderRadius: "0.8rem",
                          padding: "0.4rem",
                        }}
                      >
                        {filterDocument.originalFilename}
                      </label>
                    ),
                  };
                }),
            });
            setIsLoading(false);
            setRequestCommentaries({
              _id: GetDataRequest.data.responseMessage._id,
              comments: GetDataRequest.data.responseMessage.comments,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    handleRequestDocument();
  }, []);
  useEffect(() => {
    const requestApprovalById = async () => {
      const requestApprovalByIdResponse = await getApprovalById({ approvalId });
      if (
        requestApprovalByIdResponse.status === CODES.COD_RESPONSE_HTTP_OK ||
        requestApprovalByIdResponse.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        setApprovalData(requestApprovalByIdResponse.data.responseMessage.data);
      }
    };
    requestApprovalById();
  }, []);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}  >
          <SectionTitle title={`Cargar documento principal`} />
        </Col>
        <Col />
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<ArticleOutlinedIcon />}
            className="custom-input__button__secondary-color"
            size="medium"
            onClick={() => setIsHideDiscussion(false)}
          >
            Mensajes
          </Button>
        </Col>
      </Row>
      <div className="custom-accordion__container-document">
        <Row className="heading" style={{ margin: "2% 0" }}>
          Datos del aprobador
        </Row>
        <Row style={{ margin: "2% 0" }}>
          <Col xs={2} className="subheading">
            Aprobador:
          </Col>
          <Col className="caption">{approvalData.approverName}</Col>
        </Row>
        <Row style={{ margin: "2% 0" }}>
          <Col xs={2} className="subheading">
            Cargo:
          </Col>
          <Col className="caption">{approvalData.approverPosition}</Col>
        </Row>
        <Form onSubmit={handleSubmit(handlePreRequest)}>
          <Row className="heading" style={{ margin: "1% 0" }}>
            Solicitar
          </Row>
          <Row className="caption" style={{ margin: "1% 0" }}>
            Escriba un mensaje para enviar junto con el archivo adjunto al
            aprobador:
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  {...register("message")}
                  as="textarea"
                  placeholder="Cordial Saludo...*"
                  bsPrefix={
                    errors.message
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.message?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          {type === "upload" ? (
            <>
              <Row className="heading" style={{ margin: "0.5% 0" }}>
                Cargar documento
              </Row>
              <Row style={{ padding: "2% 0" }}>
                <Col xs={"auto"}>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    className="custom-input__button__secondary-color"
                    onClick={() => {
                      setOpenModalDoc(true);
                    }}
                  >
                    Cargar nuevo documento
                  </Button>
                </Col>
                <div
                  className="caption custom-input__error"
                >
                  {errors?.file?.message}
                </div>
                {documentData.name && (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span className="caption custom-badges__disabled">
                      {`${documentData.name} `}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => {
                          setValue("file", null);
                          setDocumentData({});
                        }}
                      />
                    </span>
                  </Col>
                )}
              </Row>
            </>
          ) : (
            <>
              <Row className="caption" style={{ margin: "1% 0" }}>
                Elija el documento del cual solicita aprobación:
              </Row>
              <Row className="dt-list-items"> 
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.documentId?.message}
                </div>
                <MDBDataTableV5
                  hover
                  pagingTop
                  entriesOptions={[5, 20, 25]}
                  entries={9}
                  pagesAmount={4}
                  data={dataTableDocumentPrincipal}
                  noRecordsFoundLabel="No se encontraron resultados"
                  entriesLabel=""
                  infoLabel={["Mostrando", "a", "de", "comentarios"]}
                  searchBottom={false}
                />
              </Row>
            </>
          )}
          <Row className="justify-content-md-end">
            <Col xs={2}>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                size="medium"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Descartar
              </Button>
            </Col>
            <Col xs={2}>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
                size="medium"
              >
                Solicitar aprobación
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <ModalUploadDocs
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF o Word."
        }
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => {
          setOpenModalDoc(false);
        }}
        onClose={() => {
          setOpenModalDoc(false);
        }}
        open={openModalDoc}
        documentData={documentData}
        setDocumentData={(document) => {
          if (!document?.name && !document?.filename) return;
          setValue("file", document);
          clearErrors("file");
          setDocumentData(document)
        }}
      />
      <ModalDecision
        title={
          type === "upload"
            ? "Subir el documento para solicitar su aprobación"
            : "Confirmar solicitud de aprobación"
        }
        message={
          type === "upload"
            ? "Tu solicitud de aprobación se actualizará con el documento cargado."
            : `¿Estás seguro que quieres pedir la aprobación del documento ${handleSearchName()}?`
        }
        agreeText={type === "upload" ? "Continuar" : "Solicitar aprobación"}
        disagreeText={"Cancelar"}
        handleAgree={
          type === "upload"
            ? handleRequestApprovalAgainFromPC
            : handleRequestApprovalAgain
        }
        handleDisagree={() => {
          setIsModalRequest(false);
        }}
        open={isModalRequest}
        onClose={setIsModalRequest}
      />
      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={requestCommentaries.comments}
        id={requestCommentaries._id}
      />
      <ModalDecision
        title={
          <Row
            style={{ alignItems: "center" }}
            className="heading__warning-color"
          >
            <Col>
              <WarningAmberOutlinedIcon fontSize="large" />
            </Col>
            <Col>
              <span>Aprobaciones</span>
            </Col>
          </Row>
        }
        message={`El documento es un formato diferente a .PDF por lo que podrás consultar si fue aprobado en la plataforma. Si quieres ver la marca de aprobación en el documento cárgalo en PDF. ¿Seguro quieres continuar?`}
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        handleAgree={
          type === "upload"
            ? handleRequestApprovalAgainFromPC
            : handleRequestApprovalAgain
        }
        handleDisagree={() => {
          setIsOpenModalTypeDocument(false);
        }}
        open={isOpenModalTypeDocument}
        onClose={() => setIsOpenModalTypeDocument(false)}
      />
    </Container>
  );
};

export default RequestApprovalAgain;
