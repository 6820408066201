import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import CardSelectCorp from "../components/IconCard/CardSelectCorp";

const SelectCorp = () => {
  const navigate = useNavigate();
  const tokenInfo = JSON.parse(localStorage.payloadToken);
  const { corporateUnits } = tokenInfo;
  useEffect(() => {
    if (corporateUnits.length <= 1) {
      if (corporateUnits[0]) {
        localStorage.setItem(
          "corporateUnit",
          JSON.stringify(corporateUnits[0])
        );
        localStorage.setItem("corporateUnitId", corporateUnits[0]._id);
      }
      navigate("/external/home", { replace: true });
    }
  }, [corporateUnits]);

  const handleCorporateUnit = (corporateUnit) => {
    localStorage.setItem("corporateUnit", JSON.stringify(corporateUnit));
    localStorage.setItem("corporateUnitId", corporateUnit._id);
    navigate("/external/home", { replace: true });
  };

  return (
    <>
      <Container fluid style={{ padding: "40px" }}>
        <Row>
          <Col className="home-title-row">Bienvenido</Col>
        </Row>
        <br />
        <Row>
          <Col className="home-init-cards-row__title-row">
            {" "}
            Selecciona una unidad corporativa para comenzar a trabajar...{" "}
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          {corporateUnits.map((corporateUnit) => (
            <Col
              md={3}
              onClick={() => handleCorporateUnit(corporateUnit)}
              key={corporateUnit.name}
              style={{ cursor: "pointer" }}
            >
              <CardSelectCorp
                description={corporateUnit.description}
                title={corporateUnit.name}
                icon={
                  <img
                    alt="icon corporate unit"
                    className="home-img__clickable"
                    src={require("../assets/svg/buildingIcon.svg").default}
                  />
                }
              />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default SelectCorp;
