import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../../../../components/sectionTitle";
import Breadcrum from "../../../../pages/parts/breadcrum";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { CustomToolTip } from "../../../../components/Tooltip/Tooltip";
import { findOneDataExternalRequest } from "../../../../services/CarvServices/CarvajalServices";
import { CODES } from "../../../../utils/codes";
import { saveAs } from "file-saver";
import ModalInfo from "../../../../components/Modals/ModalInfo";
import {
  getApprovals,
  sendReminder,
  getFileDocument,
} from "../../../../services/Approvals/ApprovalServices";
import LoadingContent from "../../../../components/LoadingContent";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DiscussionBoard from "../../../../components/ExternalRequest/DiscussionBoard";
import { APPROVAL_STATE } from "../../../../utils/approvalsState";
import { useSelector } from "react-redux";
import { getCompanyPlan } from "../../../../reducers/storageSlice";

const ApprovalsService = () => {
  /**
   * Table format
   */
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre del usuario aprobador
        </label>,
      ],
      field: "approver",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Documentos
        </label>,
      ],
      field: "documents",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Estado de la aprobación
        </label>,
      ],
      field: "state",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Cargo
        </label>,
      ],
      field: "position",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];

  const rows = [];

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Params
   */

  const { consecutive } = useParams();

  /**
   * UseSelector
   */
  const companyPlan = useSelector(getCompanyPlan);

  /**
   * Token
   */

  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  /**
   * UseState
   */

  //Tabla
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });

  const [requestCommentaries, setRequestCommentaries] = useState({
    _id: "",
    comments: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const [isHideDiscussion, setIsHideDiscussion] = useState(true);

  //Modals

  const [openReminderConfirmed, setOpenReminderConfirmed] = useState(false);
  const [responseDataReminder, setResponseDataReminder] = useState({});

  const [isStorageEnable, setIsStorageEnable] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    try {
      //PETICIÓN
      const requestData = async () => {
        const { company, userId } = JSON.parse(
          localStorage.getItem("payloadToken")
        );
        const corporateUnitId = localStorage.getItem("corporateUnitId");
        const getApproval = await getApprovals({
          requestConsecutive: consecutive,
          companyId: company,
          corporateUnitId
        });
        const obj = {
          consecutive: consecutive,
          company,
          corporateUnitId,
          userId,
        };
        const getRequest = await findOneDataExternalRequest(obj);

        if (
          getApproval.status === CODES.COD_RESPONSE_HTTP_OK &&
          getApproval.data.success &&
          getRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
          getRequest.data.success
        ) {
          const approvals = getApproval.data.responseMessage.data;
          const request = getRequest.data.responseMessage;
          setRequestCommentaries({
            _id: request._id,
            comments: request.comments,
          });
          const processedRows = approvals.map((approval, index) => {
            const isStateInactive =
              approval.state._id === APPROVAL_STATE.INACTIVE._id;
            const isRememberApproval = isStorageEnable && isStateInactive;
            return {
              approver: approval.approverName,
              documents: (
                <label
                  onClick={() => {
                    handleDownload(approval.associatedDocument);
                  }}
                  style={{
                    color: "#17B4BC",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                    borderRadius: "0.8rem",
                    padding: "0.4rem",
                  }}
                >
                  {approval?.associatedDocument?.originalFilename ||
                    "Sin nombre"}
                </label>
              ),
              state: approval.state.name,
              //state: "hola",
              position: approval.approverPosition,
              actions: (
                <Row style={{ rowGap: 10 }}>
                  <Col xs={"auto"}>
                    {!isRememberApproval ? (
                      <CustomToolTip
                        direction="left"
                        message={
                          !isStorageEnable
                            ? "La compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el usuario administrador"
                            : "Podrás solicitar de nuevo la aprobación pasado dos días desde la creación de está"
                        }
                      >
                        <Button
                          disabled
                          className={"button-primary-folders__disabled"}
                          startIcon={<LightbulbOutlinedIcon fontSize="large" />}
                        >
                          Recordar aprobación
                        </Button>
                      </CustomToolTip>
                    ) : (
                      <Button
                        disabled={!isRememberApproval}
                        className={"custom-input__button__secondary-color"}
                        startIcon={<LightbulbOutlinedIcon fontSize="large" />}
                        onClick={() => {
                          if (!isRememberApproval) return;
                          requestReminder({
                            _id: approval._id,
                            comments: approval.approvalComments,
                            associatedDocument: approval.associatedDocument,
                          });
                        }}
                      >
                        Recordar aprobación
                      </Button>
                    )}
                  </Col>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      className="custom-input__button__primary-color"
                      startIcon={<VisibilityOutlinedIcon fontSize="large" />}
                      onClick={() => {
                        navigate(`viewApproval/${approval._id}`);
                      }}
                    >
                      Ver detalles
                    </Button>
                  </Col>
                </Row>
              ),
            };
          });

          setDataTable({ columns: columns, rows: processedRows });
        }
        setIsLoading(false);
      };
      setIsLoading(true);
      requestData();
    } catch (e) {
      console.log(e);
    }
  }, [isStorageEnable]);

  useEffect(() => {
    if (companyPlan) {
      setIsStorageEnable(companyPlan.isStorageEnable);
    }
  }, [companyPlan]);

  /**
   * Handles
   */

  const handleDownload = async ({ filenameInBucket, bucketName }) => {
    try {
      setIsLoading(true);
      const file = await getFileDocument(filenameInBucket, bucketName, false);
      const bf = file.data.responseMessage.buffer.data;
      const blob = new Blob([new Uint8Array(bf).buffer]);
      let fileName = file.data.responseMessage.filename;
      const contentType =
        fileName.split(".").length === 1 &&
        file.data.responseMessage.contentType;
      if (
        contentType &&
        contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        fileName = fileName + ".docx";
      } else if (contentType && contentType === "application/pdf") {
        fileName = fileName + ".pdf";
      }
      saveAs(blob, fileName);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const requestReminder = async (approval) => {
    //BACK

    setIsLoading(true);
        
    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");

    const len = approval.comments.length;
    const comment =
      len > 0
        ? approval.comments[len - 1].message
        : "Recuerde revisar esta aprobación.";
    const requestReminder = await sendReminder({
      consecutive: consecutive,
      approvalId: approval._id,
      userName: `${payloadToken.firstName} ${payloadToken.firstSurname}`,
      userPosition: "Abogado",
      message: comment,
      associatedDocument: approval.associatedDocument._id,
      company,
      corporateUnit
    });

    setResponseDataReminder({
      status: requestReminder.status,
      data: requestReminder.data,
    });
    setIsLoading(false);

    setOpenReminderConfirmed(true);
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row style={{ height: "9%" }} className="col-flex">
        <Breadcrum />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <Col xs={"auto"}>
          <SectionTitle title={`Vistos buenos - Solicitud ${consecutive}`} />
        </Col>
        <Col />
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<ArticleOutlinedIcon />}
            className="custom-input__button__secondary-color"
            size="medium"
            onClick={() => setIsHideDiscussion(false)}
          >
            Mensajes
          </Button>
        </Col>
      </Row>
      <br />
      <Row className="caption" style={{ margin: "inherit", padding: "1% 0" }}>
        Para iniciar una nueva solicitud de aprobación, haga clic sobre el botón
        de “Solicitar aprobación”. Si requiere que una misma persona apruebe dos
        documentos diferentes, entonces deberá iniciar varios flujos de
        aprobación diferentes, uno por cada documento que requiera aprobar
      </Row>
      <Row>
        <Col xs={"auto"}>
          <Button
            type="button"
            className="custom-input__button__primary-color text-white"
            startIcon={<AddIcon fontSize="large" />}
            onClick={() => {
              navigate("create-approval");
            }}
          >
            Solicitar aprobación
          </Button>
        </Col>
      </Row>
      <Row className="dt-list-items-change-last-child">
        <MDBDataTableV5
          hover
          pagingTop
          searchBottom={false}
          entries={7}
          data={dataTable}
          entriesLabel=""
          infoLabel={["Mostrando", "a", "de", "aprobaciones"]}
          fullPagination
          noRecordsFoundLabel="No se encontraron resultados"
        />
      </Row>

      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={requestCommentaries.comments}
        id={requestCommentaries._id}
      />
      <ModalInfo
        title={"Recordatorio enviado"}
        responseData={responseDataReminder}
        open={openReminderConfirmed}
        onClose={() => {
          setOpenReminderConfirmed(false);
        }}
        confirmationText="Continuar"
      />
    </Container>
  );
};

export default ApprovalsService;
