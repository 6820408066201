import { Button, IconButton, Tooltip } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../../../../components/sectionTitle";
import Breadcrum from "../../../../pages/parts/breadcrum";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import dateFormat from "dateformat";
import { saveAs } from "file-saver";
import { useNavigate, useParams } from "react-router-dom";
import ModalInputText from "../../../../components/Modals/modalInpuText";
import ModalInfo from "../../../../components/Modals/ModalInfo";
import { CODES } from "../../../../utils/codes";
import ModalDecision from "../../../../components/Modals/ModalDecision";

import LoadingContent from "../../../../components/LoadingContent";
import {
  addComment,
  desistApproval,
  getApprovalById,
  getFileDocument,
} from "../../../../services/Approvals/ApprovalServices";
import { APPROVAL_STATE } from "../../../../utils/approvalsState";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DiscussionBoard from "../../../../components/ExternalRequest/DiscussionBoard";
import { findOneDataExternalRequest } from "../../../../services/CarvServices/CarvajalServices";
import { useSelector } from "react-redux";
import { getCompanyPlan } from "../../../../reducers/storageSlice";
import moment from "moment";

const ViewApproval = () => {
  /**
   * Tabla
   */

  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Usuario
        </label>,
      ],
      field: "user",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Cargo
        </label>,
      ],
      field: "position",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Comentario
        </label>,
      ],
      field: "comment",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Documento relacionado
        </label>,
      ],
      field: "document",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Fecha de creación
        </label>,
      ],
      field: "createdAt",
    },
  ];

  const rows = [];
  /**
   * Use Seletors
   */

  const companyPlan = useSelector(getCompanyPlan);

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Params
   */

  const { consecutive, approvalId } = useParams();

  /**
   * Use State
   */

  const [dataTable, setDataTable] = useState({ columns: columns, rows: rows });

  const [currentApproval, setCurrentApproval] = useState({
    approverPosition: "",
    approverName: "",
    state: {
      name: "",
    },
    associatedDocument: {
      name: "",
    },

    approvalComments: [],
  });

  const [requestCommentaries, setRequestCommentaries] = useState({
    _id: "",
    comments: [],
  });

  //Modales

  const [openDesistModal, setOpenDesistModal] = useState(false);

  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [openConfirmationComment, setOpenConfirmationComment] = useState(false);
  const [responseDataComment, setResponseDataComment] = useState({});

  const [openRequestAgainMainModal, setOpenRequestAgainMainModal] =
    useState(false);

  const [openRequestAgainOtherModal, setOpenRequestAgainOtherModal] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isHideDiscussion, setIsHideDiscussion] = useState(true);

  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);

  //Open tooltip company plan is full
  const [isOpenTooltipPlan, setIsOpenTooltipPlan] = useState(false);
  const [isStoragePlanLimit, setIsStoragePlanLimit] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    requestApproval();
  }, []);

  /**
   * Handles
   */
  const requestApproval = async () => {
    try {
      setIsLoading(true);
      const requestData = await getApprovalById({ approvalId: approvalId });
      const { company, userId } = JSON.parse(
        localStorage.getItem("payloadToken")
      );
      const { _id } = JSON.parse(localStorage.getItem("corporateUnit"));
      const obj = {
        consecutive: consecutive,
        company: company,
        corporateUnitId: _id,
        userId: userId,
      };
      const getRequest = await findOneDataExternalRequest(obj);

      if (
        requestData.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestData.data.success &&
        getRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        getRequest.data.success
      ) {
        const request = getRequest.data.responseMessage;

        setRequestCommentaries({
          _id: request._id,
          comments: request.comments,
        });
        const approvalInfo = requestData.data.responseMessage.data;
        setCurrentApproval(approvalInfo);

        const processedRows = approvalInfo.approvalComments.map(
          (comment, index) => {
            const created = moment(comment.createdAt).format("DD/MM/YYYY");
            return {
              user: comment.userName,
              position: comment.userPosition,
              comment: comment.message,
              document: (
                <label
                  onClick={() => {
                    handleDownload(comment.associatedDocument);
                  }}
                  style={{
                    color: "#17B4BC",
                    cursor: "pointer",
                    borderRadius: "0.8rem",
                    padding: "0.4rem",
                  }}
                >
                  {comment?.associatedDocument?.originalFilename}
                </label>
              ),
              createdAt: created,
            };
          }
        );
        setDataTable({ columns: columns, rows: processedRows });
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  //Download documents
  const handleDownload = async ({filenameInBucket, bucketName}) => {
    try {
      setIsLoading(true);
      const file = await getFileDocument(filenameInBucket, bucketName, false);
      const bf = file.data.responseMessage.buffer.data
      const blob = new Blob([new Uint8Array(bf).buffer]);
      let fileName = file.data.responseMessage.filename;
      const contentType = fileName.split(".").length === 1 && file.data.responseMessage.contentType;
      if (contentType && contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        fileName = fileName + ".docx"
      } else if (contentType && contentType === "application/pdf") {
        fileName = fileName + ".pdf"
      }
      saveAs(blob, fileName);
    } catch (error) {
        console.error(error)
    } finally {
      setIsLoading(false);
    }
  };

  //Create comment on back
  const createComment = async (comment, desist = false) => {
    try {
      setIsLoading(true);
      const userRole = localStorage.getItem("role");
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      let obj = {
        consecutive: consecutive,
        approvalId: approvalId,
        newState: desist ? APPROVAL_STATE.DESIST._id : currentApproval.state._id,
        userName: `${payloadToken.firstName} ${payloadToken.firstSurname}`,
        userPosition: userRole === "EXTERNAL" ? "Solicitante" : "Abogado",
        message: comment,
        associatedDocument: currentApproval.associatedDocument._id,
        isCommentOnly: false,
        sendEmail: true,
        isDiscussionComment: true,
        corporateUnit,
        companyId
      };
      let requestData = {};
  
      if (desist) {
        requestData = await desistApproval({
          ...obj,
          newState: APPROVAL_STATE.DESIST._id,
        });
      } else {
        requestData = await addComment({
          ...obj,
          newState: currentApproval.state._id,
          companyId,
          corporateUnit
        });
      }
  
      if (requestData.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (desist) {
          setCurrentApproval({
            ...currentApproval,
            state: { name: "Desistida" },
          });
        }
        handleNewCommentTable(requestData.data.responseMessage.data);
      } else if (requestData?.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
        requestData?.data?.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setIsOpenModalUnauthorized(true);
        setResponseDataComment(requestData);
      } else {
        setResponseDataComment({
          status: requestData.status,
          data: {
            responseMessage:
              requestData.status === CODES.COD_RESPONSE_HTTP_OK
                ? requestData.data.responseMessage
                : "Ha ocurrido un error. Por favor intentélo de nuevo.",
          },
        });
      }
  
      if (!desist) {
        setResponseDataComment(requestData);
        setOpenConfirmationComment(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  //Envía comentario
  const sendComment = (comment) => {
    setIsLoading(true);
    createComment(comment);
    setOpenCommentModal(false);
  };

  //Handle Desist

  const handleDesist = (comment) => {
    setIsLoading(true);
    createComment(comment, true);
    setOpenDesistModal(false);
  };

  //Agrega nuevo comentario a la tabla
  const handleNewCommentTable = (comment) => {
    const created = dateFormat(comment.createdAt, "paddedShortDate");
    const tableComment = {
      user: comment.userName,
      position: comment.userPosition,
      comment: comment.message,
      document: (
        <label
          onClick={() => {
            if (!comment.isCommentOnly) {
              handleDownload(comment.associatedDocument);
            }
          }}
          style={{
            color: "#17B4BC",
            cursor: comment.isCommentOnly ? "" : "pointer",
            borderRadius: "0.8rem",
            padding: "0.4rem",
          }}
        >
          {comment.isCommentOnly ? "" : comment.associatedDocument.name}
        </label>
      ),
      createdAt: created,
    };

    const newTable = dataTable.rows;
    newTable.unshift(tableComment);
    setDataTable({ columns: columns, rows: newTable });
  };

  const handleOpenTooltipPlan = () => {
    if (isStoragePlanLimit) return;
    setIsOpenTooltipPlan(true);
  };

  useEffect(() => {
    if (companyPlan) {
      setIsStoragePlanLimit(companyPlan.isStorageEnable);
    }
  }, [companyPlan]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row style={{ height: "9%" }} className="col-flex">
        <Breadcrum />
      </Row>
      <Row>
        <Col md={3} className="col-start">
          <SectionTitle title={`Vistos buenos - Solicitud ${consecutive}`} />
        </Col>
        <Col />
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<ArticleOutlinedIcon />}
            className="custom-input__button__secondary-color"
            size="medium"
            onClick={() => setIsHideDiscussion(false)}
          >
            Mensajes
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: "2%" }} className="heading col-start">
        Información de la aprobación
      </Row>
      <Row style={{ marginTop: "2%" }} className="label__description">
        <span>
          <span className="label__subtitle">Aprobador: </span>
          {currentApproval.approverName}
        </span>
      </Row>
      <Row className="label__description">
        <span>
          <span className="label__subtitle">Último documento cargado: </span>
          <label
            onClick={() => {
              handleDownload(currentApproval.associatedDocument);
            }}
            style={{
              color: "#17B4BC",
              cursor: "pointer",
            }}
          >
            {currentApproval.associatedDocument.originalFilename}
          </label>
        </span>
      </Row>
      <Row className="label__description">
        <span>
          <span className="label__subtitle">Estado de la aprobación: </span>
          {currentApproval.state.name}
        </span>
      </Row>

      <Row className="heading__hover" style={{ marginTop: "3%" }}>
        {" "}
        Comentarios relacionados con la aprobación
      </Row>
      <Row>
        {
          <MDBDataTableV5
            hover
            pagingTop
            searchBottom={false}
            entries={7}
            data={dataTable}
            entriesLabel=""
            infoLabel={["Mostrando", "a", "de", "comentarios"]}
            fullPagination
            noRecordsFoundLabel="No se encontraron resultados"
          />
        }
      </Row>
      {currentApproval.state.name !== "Aprobada" && (
        <Row className="justify-content-md-end" style={{ marginTop: "3%" }}>
          <Col md={"auto"}>
            {currentApproval.state.name !== "Desistida" && (
              <Button
                className="custom-input__button__secondary-color"
                onClick={() => {
                  setOpenDesistModal(true);
                }}
              >
                Desistir aprobación
              </Button>
            )}
          </Col>
          <Col md={"auto"}>
            <Button
              className="custom-input__button__secondary-color"
              onClick={() => {
                setOpenCommentModal(true);
              }}
            >
              Dejar un comentario
            </Button>
          </Col>
          <Col md={"auto"}>
            {currentApproval.state.name !== "Desistida" && (
              <Tooltip
                open={isOpenTooltipPlan}
                onOpen={handleOpenTooltipPlan}
                onClose={() => setIsOpenTooltipPlan(false)}
                title={
                  <h5>
                    La compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el usuario administrador.
                  </h5>
                }
              >
                <Button
                  variant="contained"
                  className={isStoragePlanLimit ? "custom-input__button__primary-color" : "custom-input__button__primary-color__disabled"}
                  onClick={() => {
                    if (!isStoragePlanLimit) return;
                    if (currentApproval.isMainDocument) {
                      setOpenRequestAgainMainModal(true);
                    } else {
                      setOpenRequestAgainOtherModal(true);
                    }
                  }}
                >
                  Solicitar nuevamente
                </Button>
              </Tooltip>
            )}
          </Col>
        </Row>
      )}

      <ModalInputText
        title={"Crear mensaje"}
        message={"Escribe el comentario que quieres enviar:"}
        placeholder={"Procederé a hacer los ajustes para subir el documento..."}
        open={openCommentModal}
        onClose={() => {
          setOpenCommentModal(false);
        }}
        agreeText="Enviar comentario"
        disagreeText="Regresar"
        handleAgree={(comment) => {
          sendComment(comment);
        }}
        handleDisagree={() => {
          setOpenCommentModal(false);
        }}
        validation={{
          required: {
            value: true,
            message: "*Este campo es obligatorio",
          },
          validate: (value) => {
            if (!value.trim()) return "*Este campo es obligatorio"
          }
        }}
      />
      <ModalInfo
        title={"Crear comentario"}
        responseData={responseDataComment}
        open={openConfirmationComment}
        onClose={() => {
          setOpenConfirmationComment(false);
        }}
        confirmationText="Continuar"
      />

      <ModalDecision
        title={"Solicitar nuevamente la aprobación"}
        message={
          <>
            <p>
              Para solicitar nuevamente la aprobación, debes seleccionar un
              documento del historial de documentos solicitados. Recuerda que el
              documento debe de estar corregido según los ajustes sugeridos por
              los aprobadores.
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>
                ¿Tu documento corregido ya se encuentra en el historial de
                documentos solicitados? o ¿Deseas subir el documento desde tu
                computador?{" "}
              </span>
              (Los documentos subidos desde el computador se incorporarán
              automáticamente en el historial de documentos solicitados)
            </p>
          </>
        }
        open={openRequestAgainMainModal}
        onClose={() => {
          setOpenRequestAgainMainModal(false);
        }}
        agreeText="Subir archivo desde mi PC"
        disagreeText="Subir archivo desde la traza"
        handleAgree={() => {
          navigate("request-again-approval/upload");
        }}
        handleDisagree={() => {
          navigate("request-again-approval/history");
        }}
      />
      <ModalDecision
        title={"Solicitar nuevamente la aprobación"}
        message={
          "Para solicitar nuevamente la aprobación, debes cargar nuevamente el documento anexo. Recuerda que el documento debe de estar corregido según los ajustes sugeridos por los firmantes."
        }
        open={openRequestAgainOtherModal}
        onClose={() => {
          setOpenRequestAgainOtherModal(false);
        }}
        agreeText="Subir archivo desde mi PC"
        disagreeText="Cancelar"
        handleAgree={() => {
          navigate("request-again-approval/upload");
        }}
        handleDisagree={() => {
          setOpenRequestAgainOtherModal(false);
        }}
      />

      <ModalInputText
        title={"Desistir aprobación"}
        message={
          <>
            <p>
              ¿Quieres desistir de esta aprobación? Si desistes, tu aprobación
              se marcará como desistida y se le notificará al firmante.
            </p>
            <p>¿Por qué deseas desistir de la aprobación?</p>
          </>
        }
        placeholder={"Escribe la razón para desistir de la aprobación"}
        open={openDesistModal}
        onClose={() => {
          setOpenDesistModal(false);
        }}
        agreeText="Desistir aprobación"
        disagreeText="Cancelar"
        handleAgree={(comment) => {
          handleDesist(comment);
          setOpenDesistModal(false);
        }}
        handleDisagree={() => {
          setOpenDesistModal(false);
        }}
      />

      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={requestCommentaries.comments}
        id={requestCommentaries._id}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataComment}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default ViewApproval;
