import React, { useEffect, useState } from "react";
import { Fade, Button } from "@mui/material";
import {
  Col,
  Container,
  Row,
  Spinner,
  Form,
  InputGroup,
} from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import jwt_decode from "jwt-decode";
import ModalForgotPassword from "../Modals/ModalForgotPassword";
import {
  GetIdentityProvider,
  LoginServiceADFS,
  Logout,
  refreshToken,
  userLogin,
} from "../../services/Login/Login";
import { CODES } from "../../utils/codes";
import ModalInfo from "../Modals/ModalInfo";
import ModalDecision from "../Modals/ModalDecision";
import ReactHtmlParser from "react-html-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ModalSupport from "../Modals/ModalSupport";
import { REGEXP } from "../../utils/regexp";
import ModalInputText from "../Modals/modalInpuText";
import Cookies from "js-cookie";
import { env } from "../../env";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import recaptcha from "../../assets/svg/recaptcha.png";
import { Mixpanel } from "../../utils/mixPanel";

const ButtonLoaderContent = ({ isLoading, text }) =>
  isLoading ? (
    <Spinner animation="border" className="spinner__white-background" />
  ) : (
    text
  );

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const codeLogin = searchParams.get("code");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [responseData, setResponseData] = useState("");
  const [isOpenModalNewPassword, setIsOpenModalNewPassword] = useState(false);
  const [isOpenModalIncorrectCredentials, setIsOpenModalIncorrectCredentials] =
    useState(false);
  const [isOpenModalDisabledUser, setIsOpenModalDisabledUser] = useState(false);
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isOpenModalDecisionLogged, setIsOpenModalDecisionLogged] =
    useState(false);
  const [isOpenModalDecisionLoggedLocal, setIsOpenModalDecisionLoggedLocal] =
    useState(false);
  const [tokensLogout, setTokensLogout] = useState({});
  const [isCodeRequired, setIsCodeRequired] = useState(false);
  const [dataLogin, setDataLogin] = useState({});
  const [isOpenModalEmailAD, setIsOpenModalEmailAD] = useState(false);
  const [isOpenModalEmailADCurrent, setIsOpenModalEmailADCurrent] =
    useState(false);
  const [elementEmailFedered, setElementEmailFedered] = useState("");
  const [isOpenModalInfoIdentityProvider, setIsOpenModalInfoIdentityProvider] =
    useState(false);
  const [isOpenModalMaxMFA, setIsOpenModalMaxMFA] = useState(false);
  const [isMayus, setIsMayus] = useState(false);
  const [newAccessToken, setNewAccessToken] = useState("");
  const [isOpenModalRegisterDevice, setIsOpenModalRegisterDevice] =
    useState(false);

  const [dataFieldsLogin, setDataFieldsLogin] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const role = "EXTERNAL";

  const CODES_MODAL_UNAUTHORIZED = [
    CODES.COD_RESPONSE_VALIDATION_MFA_BAD,
    CODES.COD_RESPONSE_ERROR_UNAUTHORIZED,
    CODES.COD_RESPONSE_ERROR_LOGIN_FEDERATED_IN_PLATFORM,
  ];

  // schema for form validation
  const schema = yup.object().shape({
    password: yup.string().required("*Este campo es obligatorio"),
    email: yup
      .string()
      .email("*Este campo debe ser un email válido")
      .required("*Este campo es requerido"),
  });

  // schema for form validation code
  const schemaCode = yup.object().shape({
    code: yup
      .string()
      .required("*Este campo es requerido")
      .max(6, "*Debe contener máximo 6 números")
      .matches(REGEXP.ONLY_NUMBERS, {
        message: "*Solo debe incluir números.",
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: registerCode,
    handleSubmit: handleSubmitCode,
    setValue: setValueCode,
    formState: { errors: errorsCode },
  } = useForm({
    resolver: yupResolver(schemaCode),
  });

  const handleVerifyCode = async ({ code }) => {
    handleLogin({
      email: dataLogin?.email,
      password: dataLogin?.password,
      code,
    });
  };

  const handleReSendCode = async () => {
    try {
      setIsLoading(true);
      const captchaToken = await executeRecaptcha("login");
      const obj = {
        email: dataLogin.email,
        password: dataLogin.password,
        role: role,
        path: "/login/external",
        token: captchaToken,
      };

      const loginServiceResponse = await userLogin(obj);

      if (
        loginServiceResponse.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
        loginServiceResponse.data.responseCode ===
          CODES.COD_RESPONSE_VALIDATION_MFA_REQUIRED
      ) {
        setResponseData({
          ...loginServiceResponse,
          data: {
            responseMessage: "Se ha reenviado el código con exito.",
          },
        });
        setIsOpenModalUnauthorized(true);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseNewPassword = () => {
    navigate(`/createPassword/${email}`);
    setIsOpenModalNewPassword(false);
  };

  const handleCloseIncorrectCredentials = () => {
    setIsOpenModalIncorrectCredentials(false);
  };

  const handleDisabledUser = () => {
    setIsOpenModalDisabledUser(false);
  };

  const handleUnathorized = () => {
    setIsOpenModalUnauthorized(false);
  };

  const handleAgreeModalDecisionLogged = () => {
    setIsCodeRequired(false);
    setValueCode("code", "");
    setIsOpenModalDecisionLogged(false);
    setIsOpenModalDecisionLoggedLocal(false);
    handleLogout();
  };

  const handleDisagreeModalDecisionLogged = () => {
    setIsOpenModalDecisionLogged(false);
  };

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const { refreshToken, accessToken } = tokensLogout;
      const responseLogout = await Logout({ accessToken, refreshToken, email });
      if (
        responseLogout.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseLogout.data.success
      ) {
        setIsCodeRequired(false);
        localStorage.clear();
        if (dataFieldsLogin) {
          await handleLogin(dataFieldsLogin);
        }
      } else {
        setIsOpenModalDecisionLogged(true);
      }
    } catch (error) {
      console.log("======== Start Error Handle Logout ========");
      console.error(error);
      console.log("======== Finish Error Handle Logout ========");
    } finally {
      setIsLoading(false);
    }
  };

  const handleMaxMFAClose = () => {
    setIsOpenModalMaxMFA(false);
    setIsCodeRequired(false);
  };

  const handleAddInfoLocalStorage = (loginServiceResponse) => {
    const jwtTokenInfo =
      loginServiceResponse?.data?.responseMessage?.idToken?.jwtToken ||
      loginServiceResponse?.data?.responseMessage?.data?.idToken?.id_token;

    const jwtPayloadToken =
      loginServiceResponse?.data?.responseMessage?.idToken?.payload ||
      loginServiceResponse?.data?.responseMessage?.data?.idToken?.payload;

    const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);

    /* Valida que el usuario de servicios o administrador no entre desde el modulo external */

    if (jwtPayloadToken["custom:role"] === role) {
      localStorage.setItem("infoToken", jwtTokenInfo);

      const accessToken =
        loginServiceResponse?.data?.responseMessage?.accessToken?.jwtToken ||
        loginServiceResponse?.data?.responseMessage?.data?.accessToken;
      localStorage.setItem("accessToken", accessToken);

      const refreshToken =
        loginServiceResponse?.data?.responseMessage?.refreshToken?.token ||
        loginServiceResponse?.data?.responseMessage?.data?.refreshToken;
      localStorage.setItem("refreshToken", refreshToken);

      localStorage.setItem("payloadToken", JSON.stringify(jwtPayloadToken));
      localStorage.setItem("role", jwtPayloadToken["custom:role"]);
      localStorage.setItem("company", jwtPayloadToken?.company);
      localStorage.setItem("firstTime", jwtDecodeTokenInfo["custom:firstTime"]);

      navigate("/selectCorp", { replace: true });
    } else {
      setResponseData({
        data: {
          responseMessage: "Su cuenta no ha sido registrada para este modulo",
        },
        status: 401,
      });
      setIsOpenModalUnauthorized(true);
    }
  };

  const handleResponseLoginUnauthorized = ({
    loginServiceResponse,
    data,
    obj,
  }) => {
    setResponseData(loginServiceResponse);

    if (
      loginServiceResponse.data.responseCode ===
      CODES.COD_RESPONSE_REGISTER_NEW_DEVICE
    ) {
      setDataLogin(obj);
      setIsOpenModalRegisterDevice(true);
    }

    if (
      loginServiceResponse.data.responseCode ===
      CODES.COD_RESPONSE_VALIDATION_MFA_REQUIRED
    ) {
      setDataLogin(obj);
      setIsCodeRequired(true);
      return;
    }

    if (
      loginServiceResponse.data.responseCode ===
      CODES.COD_RESPONSE_ERROR_NEW_PASSWORD_REQUIRED
    ) {
      setEmail(data.email);
      setIsOpenModalNewPassword(true);
      return;
    }

    if (
      loginServiceResponse.data.responseCode ===
      CODES.COD_RESPONSE_ERROR_INCORRECT_CREDENTIALS
    ) {
      setIsOpenModalIncorrectCredentials(true);
      return;
    }

    if (
      loginServiceResponse.data.responseCode ===
      CODES.COD_RESPONSE_ERROR_DISABLED_USER
    ) {
      setIsOpenModalDisabledUser(true);
      return;
    }

    if (
      loginServiceResponse.data.responseCode ===
      CODES.COD_RESPONSE_ERROR_MAX_ATTEMP_MFA_CODE
    ) {
      setIsOpenModalMaxMFA(true);
      return;
    }

    if (
      CODES_MODAL_UNAUTHORIZED.includes(loginServiceResponse.data.responseCode)
    ) {
      setIsOpenModalUnauthorized(true);
    }
  };

  const handleResponseLoginOk = ({ loginServiceResponse, data }) => {
    if (
      loginServiceResponse.data.responseCode ===
      CODES.COD_RESPONSE_VALIDATION_USER_LOGGED
    ) {
      setEmail(data?.email);
      setTokensLogout(loginServiceResponse.data.responseMessage.data);
      setIsOpenModalDecisionLogged(true);
    } else if (
      loginServiceResponse.data.responseCode === CODES.COD_RESPONSE_SUCCESS
    ) {
      handleAddInfoLocalStorage(loginServiceResponse);
    }
  };

  const handleLogin = async (data) => {
    try {
      setIsLoading(true);
      const captchaToken = await executeRecaptcha("login");
      const obj = {
        email: data.email,
        password: data.password.trim(),
        role: role,
        code: data.code,
        path: "/login/external",
        token: captchaToken,
      };

      Mixpanel.identify(data.email);

      Mixpanel.track("Login Solicitante", {
        email: data.email,
      });

      setDataFieldsLogin(obj);

      const loginServiceResponse = await userLogin(obj);

      if (
        loginServiceResponse.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        handleResponseLoginUnauthorized({ data, loginServiceResponse, obj });
      }
      if (loginServiceResponse.status === CODES.COD_RESPONSE_HTTP_OK) {
        handleResponseLoginOk({ data, loginServiceResponse });

        const userInfo =
          loginServiceResponse?.data?.responseMessage?.idToken?.payload ||
          loginServiceResponse?.data?.responseMessage?.data?.idToken?.payload;

        Mixpanel.people.set({
          $first_name: userInfo?.firstName || userInfo?.companyName,
          $last_name: userInfo?.firstSurname || "",
          $email: data?.email,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirectLoginFedered = async (value) => {
    try {
      if (typeof value === "string") {
        const options = {
          secure: true,
          expires: 999,
        };
        Cookies.set("email_federed", value, options);
      }

      const domain = value.split("@")[1];

      const responseIdentityProvider = await GetIdentityProvider(domain);

      if (
        responseIdentityProvider.status !== CODES.COD_RESPONSE_HTTP_OK ||
        responseIdentityProvider.data.responseCode !==
          CODES.COD_RESPONSE_SUCCESS
      ) {
        setResponseData({
          ...responseIdentityProvider,
          status: 401,
        });
        setIsOpenModalInfoIdentityProvider(true);
        Cookies.remove("email_federed");
        throw new Error(
          "Ha ocurrido un error consultando el provedor de indetidad de este correo"
        );
      }
      const companyIdentityProvider =
        responseIdentityProvider?.data?.responseMessage?.data
          ?.companyIdentityProvider;

      const clientId =
        responseIdentityProvider?.data?.responseMessage?.data?.clientId;

      const identityProviderName =
        companyIdentityProvider?.identityProvider?.identityProviderName;

      const redirectUri = window.location.origin;

      const url = `${env.REACT_APP_URL_LOGIN_AD_FS}&client_id=${clientId}&identity_provider=${identityProviderName}&redirect_uri=${redirectUri}/`;

      window.location.replace(url);

      setIsOpenModalEmailAD(false);

      setIsOpenModalEmailADCurrent(false);
    } catch (error) {
      console.log("========= Start Handle Redirect Login Federed =========");
      console.error(error);
      console.log("========= End Handle Redirect Login Federed =========");
    } finally {
      setIsLoading(false);
    }
  };

  const hanldeLoginEmailFedered = () => {
    const emailFedered = Cookies.get("email_federed");
    Mixpanel.identify(emailFedered);
    if (emailFedered && emailFedered !== "undefined") {
      setElementEmailFedered(emailFedered);
      setIsOpenModalEmailADCurrent(true);
    } else {
      setIsOpenModalEmailAD(true);
    }
  };

  const handleLoginADFS = async () => {
    try {
      setIsLoading(true);

      const obj = {
        role,
        code: codeLogin,
        redirectUri: `${window.location.origin}/`,
      };

      const loginServiceResponse = await LoginServiceADFS(obj);

      if (
        loginServiceResponse.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        handleResponseLoginUnauthorized({
          data: { email: "" },
          loginServiceResponse,
          obj,
        });
      }
      if (loginServiceResponse.status === CODES.COD_RESPONSE_HTTP_OK) {
        handleResponseLoginOk({ data: { email: "" }, loginServiceResponse });
        const userInfo =
          loginServiceResponse?.data?.responseMessage?.idToken?.payload ||
          loginServiceResponse?.data?.responseMessage?.data?.idToken?.payload;

        Mixpanel.identify(userInfo?.email);

        Mixpanel.people.set({
          $first_name: userInfo?.firstName || userInfo?.companyName,
          $last_name: userInfo?.firstSurname || "",
          $email: userInfo?.email,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeCompanyLogin = async () => {
    try {
      setIsLoading(false);

      const email = Cookies.get("email_federed");

      const domain = email.split("@")[1];

      const responseIdentityProvider = await GetIdentityProvider(domain);

      if (
        responseIdentityProvider.status !== CODES.COD_RESPONSE_HTTP_OK ||
        responseIdentityProvider.data.responseCode !==
          CODES.COD_RESPONSE_SUCCESS
      ) {
        setResponseData({
          ...responseIdentityProvider,
          status: 401,
        });
        setIsOpenModalInfoIdentityProvider(true);
        Cookies.remove("email_federed");
        throw new Error(
          "Ha ocurrido un error consultando el provedor de indetidad de este correo"
        );
      }

      const clientId =
        responseIdentityProvider?.data?.responseMessage?.data?.clientId;

      Cookies.remove("email_federed");

      setIsOpenModalEmailAD(true);
      setIsOpenModalEmailADCurrent(false);
      const redirect_uri = window.location.origin;

      window.open(
        `${env.REACT_APP_URL_LOGOUT_AD_FS}&client_id=${clientId}&redirect_uri=${redirect_uri}`
      );
    } catch (error) {
      console.log(
        "==================== Start Handle Change Company Indetity Provider ===================="
      );
      console.error(error);
      console.log(
        "==================== End Handle Change Company Indetity Provider ===================="
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isMayusUp = (event) => {
    const caps = event?.getModifierState("CapsLock");
    setIsMayus(!!caps);
  };

  const handleDisagreeModalDecisionLoggedLocal = () => {
    setIsOpenModalDecisionLoggedLocal(false);
    localStorage.setItem("accessToken", newAccessToken);
    navigate("/selectCorp", { replace: true });
  };

  const verifyToken = async () => {
    try {
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      if (!payloadToken?.email) return;
      const obj = {
        email: payloadToken?.email,
        refreshToken: localStorage.getItem("refreshToken"),
      };
      setIsLoading(true);
      const refreshTokenService = await refreshToken(obj);
      if (refreshTokenService.status === CODES.COD_RESPONSE_HTTP_OK) {
        setNewAccessToken(refreshTokenService.data.accessToken.jwtToken);
      }
      return refreshTokenService;
    } catch (error) {
      console.log(
        "=============== Error Start Function Verify Token ==============="
      );
      console.error(error);
      console.log(
        "=============== Error End Function Verify Token ==============="
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegisterNewDevice = async () => {
    try {
      setIsLoading(true);
      /**
       * Agregar lógica de registro de nuevo dispositivo.
       */
      await handleLogin(dataLogin);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (codeLogin) {
      handleLoginADFS();
    }
  }, [codeLogin]);

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    const handleReCaptchaVerify = async () => {
      await executeRecaptcha("Login");
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  useEffect(() => {
    const isValidToken = async () => {
      const verifyTokenResponse = await verifyToken();
      return verifyTokenResponse?.status === CODES.COD_RESPONSE_HTTP_OK;
    };
    const cleanStorage = async () => {
      const isValidTokenResponse = await isValidToken();
      if (!isValidTokenResponse) {
        localStorage.clear();
        return;
      }
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      const refreshToken = localStorage.getItem("refreshToken");
      const accessToken = localStorage.getItem("accessToken");
      setEmail(payloadToken?.email);
      setTokensLogout({ accessToken, refreshToken });
      setIsOpenModalDecisionLoggedLocal(true);
    };
    cleanStorage();
  }, []);

  return (
    <Container fluid>
      <Fade
        in={true}
        style={{
          transitionDuration: "2s",
        }}
      >
        <div className="login-page-form">
          <Container fluid className="container-login">
            <Row style={{ height: "100%" }}>
              <Col
                md={5}
                className="background-principal-icon white-icon__center"
              >
                <img
                  alt="logo legalai"
                  className="white-icon"
                  src={require("../../assets/svg/logoWhite.svg").default}
                />
              </Col>
              <Col>
                {!isCodeRequired ? (
                  <Container style={{ padding: "3% 0%" }}>
                    <Row className="align-center">
                      <p className="display-x-large__primary-color">
                        Bienvenido
                      </p>
                    </Row>
                    <Row className="align-center">
                      <p className="display-large__primary-color-hover">
                        Usuario solicitante
                      </p>
                    </Row>
                    <br />
                    <br />
                    <Row className="form-container-login">
                      <Form
                        style={{ height: "100%" }}
                        onSubmit={handleSubmit(handleLogin)}
                      >
                        <Form.Group className="mb-5">
                          <InputGroup
                            bsPrefix={
                              errors.email
                                ? "input-group-container__error"
                                : "input-group-container"
                            }
                          >
                            <InputGroup.Text bsPrefix="container-icon">
                              <AlternateEmailIcon fontSize="large" />
                            </InputGroup.Text>
                            <Form.Control
                              {...register("email")}
                              bsPrefix="custom-input"
                              placeholder="Correo electrónico"
                            />
                          </InputGroup>
                          <div className="caption custom-input__error">
                            {errors.email?.message}
                          </div>
                        </Form.Group>

                        <Form.Group className="mb-5">
                          <InputGroup
                            bsPrefix={
                              errors.password
                                ? "input-group-container__error"
                                : "input-group-container"
                            }
                          >
                            <InputGroup.Text bsPrefix="container-icon">
                              <LockOutlinedIcon fontSize="large" />
                            </InputGroup.Text>
                            <Form.Control
                              {...register("password")}
                              type={!isPasswordVisible ? "password" : "text"}
                              bsPrefix="custom-input"
                              placeholder="Contraseña"
                              onKeyDown={isMayusUp}
                            />
                            <FontAwesomeIcon
                              size="lg"
                              icon={!isPasswordVisible ? faEye : faEyeSlash}
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              onClick={() =>
                                setIsPasswordVisible(!isPasswordVisible)
                              }
                            />
                          </InputGroup>

                          {isMayus && (
                            <p className="caption custom-input__error">
                              Bloq. mayus activado
                            </p>
                          )}
                          <div className="caption custom-input__error">
                            {errors.password?.message}
                          </div>
                          <br />
                        </Form.Group>
                        <Form.Group
                          style={{ textAlign: "center", padding: "0% 10%" }}
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            className="custom-input__button__primary-color"
                            disabled={isLoading}
                          >
                            <ButtonLoaderContent
                              isLoading={isLoading}
                              text={"Iniciar sesión"}
                            />
                          </Button>
                        </Form.Group>
                      </Form>
                    </Row>
                    <br />
                    <Row style={{ textAlign: "center" }}>
                      <span className="caption">
                        También puedes iniciar sesión con:
                      </span>
                    </Row>
                    <br />
                    <Row style={{ justifyContent: "center" }}>
                      <Col xs={4}>
                        <Button
                          type="button"
                          onClick={() => hanldeLoginEmailFedered()}
                          startIcon={
                            <img
                              width="20"
                              alt="logo.svg"
                              src={
                                require("../../assets/svg/outlookIcon.svg")
                                  .default
                              }
                            />
                          }
                          variant="contained"
                          className="custom-input__button__default-white"
                          color="primary"
                        >
                          Iniciar sesión con outlook
                        </Button>
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <Row style={{ textAlign: "center" }}>
                      <Col md={4}>
                        <ModalForgotPassword />
                      </Col>
                      <Col md={{ span: 4, offset: 4 }}>
                        <ModalSupport />
                      </Col>
                    </Row>
                    <Row className="justify-content-center align-items-center pt-2">
                      <a
                        style={{ width: "auto" }}
                        href="https://policies.google.com/terms"
                      >
                        <img src={recaptcha} alt="recaptcha badge" />
                      </a>
                    </Row>
                  </Container>
                ) : (
                  <Container
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: "0% 20%",
                    }}
                  >
                    <Row className="align-justify">
                      <p className="display-x-large__primary-color">
                        Código de doble factor de verificación
                      </p>
                    </Row>
                    <br />
                    <Row className="align-justify">
                      <p className="caption">
                        Hemos enviado a tu celular un código de verificación.
                      </p>
                    </Row>
                    <br />
                    <Form onSubmit={handleSubmitCode(handleVerifyCode)}>
                      <Row>
                        <Form.Group className="mb-5">
                          <InputGroup
                            bsPrefix={
                              errorsCode?.code
                                ? "input-group-container__error"
                                : "input-group-container"
                            }
                          >
                            <Form.Control
                              {...registerCode("code")}
                              type="number"
                              bsPrefix="custom-input"
                              autoComplete="off"
                              placeholder="Ingresa el código"
                              style={{
                                height: "4.8rem",
                              }}
                            />
                          </InputGroup>
                          <div className="caption custom-input__error">
                            {errorsCode?.code?.message}
                          </div>
                        </Form.Group>
                        <Row
                          style={{
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <Col xs={"auto"}>
                            <Button
                              className="custom-input__button__link"
                              style={{
                                fontSize: "12px",
                              }}
                              onClick={handleReSendCode}
                            >
                              Solicitar un nuevo código
                            </Button>
                          </Col>
                          <Col xs={"auto"}>
                            <Form.Group
                              style={{ textAlign: "center", padding: "0% 10%" }}
                            >
                              <Button
                                type="submit"
                                variant="contained"
                                className={
                                  "custom-input__button__primary-color"
                                }
                              >
                                {isLoading ? (
                                  <Spinner
                                    animation="border"
                                    className="spinner__white-background"
                                  />
                                ) : (
                                  "Verificar"
                                )}
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Row>
                    </Form>
                  </Container>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </Fade>
      <ModalInfo
        title={"Información"}
        responseData={responseData}
        open={isOpenModalNewPassword}
        onClose={handleCloseNewPassword}
      />
      <ModalInfo
        title={"Información"}
        responseData={responseData}
        open={isOpenModalIncorrectCredentials}
        onClose={handleCloseIncorrectCredentials}
      />
      <ModalInfo
        title={"Información"}
        responseData={{
          status: responseData.status,
          data: {
            responseMessage: ReactHtmlParser(
              responseData.data?.responseMessage
            ),
          },
        }}
        open={isOpenModalDisabledUser}
        onClose={handleDisabledUser}
      />
      <ModalInfo
        title={"Información"}
        responseData={responseData}
        open={isOpenModalUnauthorized}
        onClose={handleUnathorized}
      />
      <ModalDecision
        title="Inicio de sesión"
        message={
          "Tienes activa otra sesión ¿Seguro quieres cerrar la otra sesión para ingresar con este usuario?"
        }
        agreeText={"Cerrar e iniciar sesión"}
        disagreeText={"Cancelar"}
        handleAgree={handleAgreeModalDecisionLogged}
        handleDisagree={handleDisagreeModalDecisionLogged}
        open={isOpenModalDecisionLogged}
        onClose={() => {
          console.error("No se puede ejecutar esta acción");
        }}
      />
      <ModalDecision
        title="Inicio de sesión"
        message={
          "Tienes activa otra sesión ¿Seguro quieres cerrar la otra sesión para ingresar con este usuario?"
        }
        agreeText={"Cerrar sesiones"}
        disagreeText={"Seguir en la misma sesión "}
        handleAgree={handleAgreeModalDecisionLogged}
        handleDisagree={handleDisagreeModalDecisionLoggedLocal}
        open={isOpenModalDecisionLoggedLocal}
        onClose={() => setIsOpenModalDecisionLoggedLocal(false)}
      />
      <ModalInfo
        title={"Información"}
        responseData={responseData}
        open={isOpenModalMaxMFA}
        onClose={handleMaxMFAClose}
      />
      <ModalInputText
        title={"Iniciar sesión con outlook"}
        agreeText={"Continuar"}
        handleAgree={handleRedirectLoginFedered}
        handleDisagree={() => {
          setIsOpenModalEmailAD(false);
        }}
        message={
          "Por favor, escribe el correo de tu organización con el que deseas inicar sesión"
        }
        onClose={() => {
          setIsOpenModalEmailAD(false);
        }}
        open={isOpenModalEmailAD}
        placeholder={"Usuario@ejemplo.com"}
        disableDisagreeText={true}
        inputType={"input"}
        validation={{
          required: {
            value: true,
            message: "*Este campo es obligatorio",
          },
          pattern: {
            value: REGEXP.EMAIL,
            message: "*El campo debe ser un email válido",
          },
        }}
      />
      <ModalDecision
        title={"Iniciar sesión con outlook"}
        message={`Ya has agregado el correo ${elementEmailFedered} ¿deseas seguir usando este correo?`}
        agreeText={"Continuar"}
        disagreeText={"Cambiar organización"}
        handleAgree={() => handleRedirectLoginFedered(elementEmailFedered)}
        handleDisagree={handleChangeCompanyLogin}
        open={isOpenModalEmailADCurrent}
        onClose={() => setIsOpenModalEmailADCurrent(false)}
      />
      <ModalInfo
        title={"Inicio de sesión con outlook"}
        onClose={() => setIsOpenModalInfoIdentityProvider(false)}
        open={isOpenModalInfoIdentityProvider}
        responseData={responseData}
        confirmationText={"Aceptar"}
      />
      <ModalDecision
        title={"Registrar dispositivo"}
        message={`¿Quieres registrar este dispositivo de uso?`}
        agreeText={"Registrar dispositivo"}
        disagreeText={"No registrar"}
        handleAgree={handleRegisterNewDevice}
        handleDisagree={() => console.log("no register")}
        open={isOpenModalRegisterDevice}
        onClose={() => setIsOpenModalRegisterDevice(false)}
      />
    </Container>
  );
};
export default Login;
