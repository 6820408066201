import GetToken from "../../utils/getToken";
import axios from "axios";
import { env } from "../../env";

export const getFileDocument = (filenameInBucket, bucketName, isWord) => {
  return axios({
    method: "GET",
    url: `versions/getfileToExternal`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { filenameInBucket, bucketName, isWord },
  });
};
export const getDocById = (id) => {
  return axios({
    method: "GET",
    url: `versions/getbyid`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { id },
  });
};
export const getApprovals = ({ requestConsecutive, companyId, corporateUnitId }) => {
  return axios({
    method: "GET",
    url: `thirdPartyRequest/getApprovals/${requestConsecutive}`,
    params: {
      consecutive: requestConsecutive,
      companyId,
      corporateUnitId
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};
export const sendReminder = ({
  consecutive,
  approvalId,
  userName,
  userPosition,
  message,
  associatedDocument,
  company,
  corporateUnit,
}) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/rememberApproval`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      consecutive: consecutive,
      approvalId: approvalId,
      userName: userName,
      userPosition: userPosition,
      message: message,
      associatedDocument: associatedDocument,
      company,
      corporateUnit,
    },
  });
};

export const addCommentApproval = ({
  consecutive,
  approvalId,
  approvalComment,
  isDiscussionComment,
  companyId,
  corporateUnit
}) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/createApprovalComment`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      consecutive,
      addCommentDto: { approvalId, approvalComment },
      isDiscussionComment,
      companyId,
      corporateUnit
    },
  });
};
export const createApproval = ({
  consecutive,
  aplicantName,
  approverName,
  approverPosition,
  approverMail,
  message,
  associatedDocument,
  company,
  isMainDocument,
  state,
  corporateUnit
}) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/addApprovalToRequest`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      consecutive: consecutive,
      approval: {
        aplicantName,
        approverName,
        approverPosition,
        approverMail,
        message,
        associatedDocument,
        company,
        isMainDocument,
        state,
        corporateUnit
      },
      companyId: company,
      corporateUnit
    },
  });
};
export const getApprovalById = ({ approvalId }) => {
  return axios({
    method: "GET",
    url: `approvals/getOne/${approvalId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};
export const addComment = ({
  consecutive,
  approvalId,
  newState,
  userName,
  userPosition,
  message,
  associatedDocument,
  isCommentOnly,
  sendEmail,
  isDiscussionComment,
  companyId,
  corporateUnit
}) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/createApprovalComment`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      consecutive: consecutive,
      isDiscussionComment: isDiscussionComment,
      addCommentDto: {
        approvalId: approvalId,
        newState: newState,
        approvalComment: {
          approvalId: approvalId,
          userName: userName,
          userPosition: userPosition,
          message: message,
          associatedDocument: associatedDocument,
          isCommentOnly: isCommentOnly,
          isSendMail: sendEmail,
        },
      },
      companyId,
      corporateUnit
    },
  });
};
export const desistApproval = ({
  consecutive,
  approvalId,
  newState,
  userName,
  userPosition,
  message,
  associatedDocument,
  isCommentOnly,
  sendEmail,
  isDiscussionComment,
  corporateUnit,
  companyId
}) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/desistApprovalComment`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      consecutive: consecutive,
      isDiscussionComment: isDiscussionComment,
      addCommentDto: {
        approvalId: approvalId,
        newState: newState,
        approvalComment: {
          approvalId: approvalId,
          userName: userName,
          userPosition: userPosition,
          message: message || "Aprobación desistida",
          associatedDocument: associatedDocument,
          isCommentOnly: isCommentOnly,
          isSendMail: sendEmail,
        },
      },
      corporateUnit,
      companyId
    },
  });
};
export const sendApprovalAgain = ({consecutive, approvalId, updateApproval, companyId, corporateUnit }) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/updateApprovalwithDocument`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      consecutive,
      approvalId,
      updateApproval,
      companyId,
      corporateUnit
    },
  });
};
export const uploadDocumentForApproval = ({
  companyId,
  applicantId,
  consecutive,
  principalDocument,
  attorneyName,
  applicantName,
  comment,
  corporateUnit
}) => {
  const formData = new FormData();
  formData.append("companyId", companyId);
  formData.append("applicantId", applicantId);
  formData.append("consecutive", consecutive);
  formData.append("mainDocuments", principalDocument);
  formData.append("attorneyName", attorneyName);
  formData.append("applicantName", applicantName);
  formData.append("comment", comment);
  formData.append("corporateUnit", corporateUnit);
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/add/documentsForApproval`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: formData,
  });
};
export const getSignedURLFronEmail = (filenameInBucket, bucketName) => {
  return axios({
    method: "GET",
    url: `utils/signedURLFronEmail`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { filenameInBucket, bucketName },
  });
};
