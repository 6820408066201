import { Avatar, Button, Divider } from "@mui/material";
import { Col, Container, Nav, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ModalDecision from "../Modals/ModalDecision";
import { useNavigate, NavLink } from "react-router-dom";
import { Logout } from "../../services/Login/Login";
import { Mixpanel } from "../../utils/mixPanel";
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { IconButton } from "@mui/material";

const Sidebar = () => {
  const navigate = useNavigate();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const roleShortName = localStorage.getItem("role");
  const [isExpanded, setIsExpanded] = useState(true);
  const [isMediumMobile, setIsMediumMobile] = useState(false);

  const role = roleShortName === "EXTERNAL" ? "Solicitante" : "---";

  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const corporateUnitName = JSON.parse(localStorage.getItem("corporateUnit"));
  const corporateUnit = corporateUnitName?.name || "";

  const handleClose = () => {
    setOpenLogoutModal(false);
  };

  const handleLogoutAgree = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      await Logout({ refreshToken, email: payloadToken.email });
      setOpenLogoutModal(false);
      window.location.replace("/");
      Mixpanel.reset();
    } catch (error) {
      console.log("==============Error handleLogout======================");
      console.log(error);
      console.log("====================================");
      setOpenLogoutModal(false);
    }
  };

  const handleLogoutDisagree = () => {
    setOpenLogoutModal(false);
  };

  const externalMenu = [
    {
      content: "Inicio",
      icon: (
        <HomeOutlinedIcon className="heading__primary-color" fontSize="large" />
      ),
      route: "home",
      prefix: "external"
    },
    {
      content: "Crear",
      icon: (
        <EmailOutlinedIcon className="heading__primary-color" fontSize="large" />
      ),
      route: "create",
      prefix: "external",
    },
  ];

  const handleMixPanelAnalytics = (item) => {
    Mixpanel.track(`Btn Sidebar Solicitante ${item.content}`, {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  };

  useEffect(() => {
    const updateMediaQuery = () => {
      const isMedium = window.innerWidth <= 768;
      setIsExpanded(!isMedium);
      setIsMediumMobile(isMedium);
    };
    updateMediaQuery();
    window.addEventListener('resize', updateMediaQuery);
    return () => {
      window.removeEventListener('resize', updateMediaQuery);
    };
  }, []);

  return (<div
    className={
      isExpanded ? "sidebar-base__expanded" : "sidebar-base__contracted"
    }
  >
    <Container className="sidebar-base__container">
      {/**HEADER */}
      <Row className="sidebar-base__header-row">
        <Container
          fluid
          className="sidebar-base__header-row__header-container "
        >
          <Row className="align-items-center ">
            <Col xs={isExpanded ? 9 : 12} className="text-center pb-3">
              <Avatar
                sx={{
                  width: "5rem",
                  height: "5rem",
                  backgroundColor: "#00374F",
                  fontSize: "2rem",
                  fontFamily: "Roboto",
                }}
              >
                {payloadToken?.firstName?.toUpperCase().charAt(0) || "P"}{" "}
                {payloadToken?.firstSurname?.toUpperCase().charAt(0) || "J"}
              </Avatar>
            </Col>
            <Col md={isExpanded ? 3 : 12} className="text-center pb-3">
                <IconButton
                  className="custom-input__button__disabled "
                  sx={{
                    borderRadius: "16px",
                    height: "40px",
                    width: "40px",
                    fill: "#00374F",
                  }}
                  onClick={() => {
                    if (isMediumMobile && !isExpanded) {
                      setIsExpanded(false);
                      return;
                    }
                    setIsExpanded(!isExpanded);
                  }}
                >
                  {isExpanded ? (
                    <MenuOpenOutlinedIcon
                      className="text__primary-color"
                      fontSize="large"
                    />
                  ) : (
                    <ChevronRightOutlinedIcon
                      className="text__primary-color"
                      fontSize="large"
                    />
                  )}
                </IconButton>
            </Col>
          </Row>
          {isExpanded && (
            <Row className="sidebar-base__header-row__header-container__name">
              <p className="heading">
                {payloadToken.firstName} {payloadToken.firstSurname}
              </p>
            </Row>
          )}

          <Row className="sidebar-base__header-row__header-container__role align-items-center">
            {isExpanded && (
              <Col md={isExpanded ? 9 : 12}>
                <p className="caption">{role}</p>
                <p className="caption">{corporateUnit}</p>
              </Col>
            )}
          </Row>
        </Container>
      </Row>

      <Divider variant="fullWidth" />

      {/**NAVBAR */}
      <Row className="sidebar-base__navbar-row">
        <Container fluid>
          <Nav className="flex-column">
            {externalMenu.map((item) => {
              return (
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "sidebar-base__navbar-row__navbar-container__nav-row__selected mt-4"
                      : "sidebar-base__navbar-row__navbar-container__nav-row mt-4"
                  }
                  key={item.content}
                  to={item.route}
                  style={{ textDecoration: "none" }}
                  onClick={() => handleMixPanelAnalytics(item)}
                >
                  <Row
                    className="justify-content-center align-items-center "
                    style={{ height: "inherit" }}
                  >
                    <Col
                      md={isExpanded ? 2 : 12}
                      className={
                        !isExpanded
                          ? "sidebar-base__navbar-row__navbar-container__icon-container"
                          : "sidebar-base__navbar-row__navbar-container__icon-container__padding"
                      }
                    >
                      {item.icon}
                    </Col>
                    {isExpanded && (
                      <Col className="sidebar-base__navbar-row__navbar-container__nav-element">
                        {item.content}
                      </Col>
                    )}
                  </Row>
                </NavLink>
              );
            })}
          </Nav>
        </Container>
      </Row>

      {/**LOGOUT */}
      <Row>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "sidebar-base__navbar-row__navbar-container__nav-row__selected mt-4"
                : "sidebar-base__navbar-row__navbar-container__nav-row mt-4"
            }
            key={"help"}
            to={"help"}
            style={{ textDecoration: "none" }}
            onClick={() => {
              Mixpanel.track("Btn Sidebar Soporte técnico", {
                email: payloadToken?.email,
                companyName: payloadToken?.companyName,
              });
            }}
          >
            <Row
              className="justify-content-center align-items-center"
              style={{ height: "inherit" }}
            >
              <Col
                md={isExpanded ? 2 : "auto"}
                className={
                  !isExpanded
                    ? "sidebar-base__navbar-row__navbar-container__icon-container"
                    : "sidebar-base__navbar-row__navbar-container__icon-container__padding"
                }
              >
                <SupportAgentOutlinedIcon className="heading__primary-color" fontSize="large" />
              </Col>
              {isExpanded && (
                <Col className="sidebar-base__navbar-row__navbar-container__nav-element">
                  Soporte técnico
                </Col>
              )}
            </Row>
          </NavLink>
      </Row>
      <Row className="sidebar-base__header-row__header-container  container-fluid">
        <Button
          variant="text"
          className="sidebar-base__logout-row__button"
          onClick={() => {
            setOpenLogoutModal(true);
            Mixpanel.track("Btn Sidebar Solicitante Cerrar Sesión", {
              email: payloadToken?.email,
              companyName: payloadToken?.companyName,
            });
          }}
        >
            <Row
              className="justify-content-center align-items-center "
              style={{ height: "inherit" }}
            >
            <Col
              md={isExpanded ? 2 : 12}
              className="sidebar-base__logout-row__logout-container"
            >
              <img
                className="sidebar-base__logout-row__logout-icon"
                src={require("../../assets/svg/logoutIcon.svg").default}
              />
            </Col>
            {isExpanded && (
              <Col className="sidebar-base__logout-row__logout-text">
                Cerrar sesión
              </Col>
            )}
          </Row>
        </Button>
      </Row>

      <Divider variant="fullWidth" />

      {/**FOOTER */}

      <Row className="sidebar-base__logo-row">
        <Col 
          md={isExpanded ? 2 : 12} 
          className="sidebar-base__logo-row__logo-container"
        >
          <img
            alt="singleIcon"
            className="sidebar-base__logo-row__logo-svg"
            src={require("../../assets/svg/singleLogo.svg").default}
          />
        </Col>
        {isExpanded && (
          <Col className="sidebar-base__logo-row__logo-title">
            <p>Legal AI</p>
          </Col>
        )}
      </Row>

      <ModalDecision
        title={"Cerrar sesión"}
        message={"¿Está seguro?"}
        open={openLogoutModal}
        onClose={handleClose}
        agreeText="Cerrar sesión"
        disagreeText="Cancelar"
        handleAgree={handleLogoutAgree}
        handleDisagree={handleLogoutDisagree}
      />
    </Container>
  </div>
  );
};

export default Sidebar;
