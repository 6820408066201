import React, { useState } from "react";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TextRun,
  LevelFormat,
  convertInchesToTwip,
  LineRuleType,
  Table,
  TableRow,
  TableCell,
  WidthType,
  LevelSuffix,
  convertMillimetersToTwip,
} from "docx";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";

import { FileDownloadOutlined } from "@mui/icons-material";

import { Buffer } from "buffer";
import { NUMBERINGWORDSA, NUMBERINGWORDSO } from "./numberings";
import { getFileDocumentForExternal } from "../services/ExternalUsers/ExternalRequest";
import { previewHeadingDocument } from "../services/headings/HeadingServices";
import { CODES } from "./codes";
import { permissionsUsersByEmail } from "../services/utils";
import ModalInfo from "../components/Modals/ModalInfo";
import validatePermissions from "../services/permissions/permissionsServices";
import { Mixpanel } from "./mixPanel";

const sizeText = 24;

const colorhighlighted = "yellow";

const fontFamily = "Times New Roman";

const numberingWordsA = NUMBERINGWORDSA;

const numberingWordsO = NUMBERINGWORDSO;

const regexDeleteHtmlTags = /(<([^>]+)>)/gi;

const configDocument = [
  {
    reference: "romanNumbering",
    levels: [
      {
        level: 0,
        text: "%1.",
        format: LevelFormat.UPPER_ROMAN,
        alignment: AlignmentType.START,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.5),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 1,
        text: "%1.%2",
        alignment: AlignmentType.START,
        format: LevelFormat.UPPER_ROMAN,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(1),
              hanging: convertInchesToTwip(0.36),
            },
          },
        },
      },
      {
        level: 2,
        text: "%1.%2.%3",
        alignment: AlignmentType.START,
        format: LevelFormat.UPPER_ROMAN,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.5),
              hanging: convertInchesToTwip(0.54),
            },
          },
        },
      },
    ],
  },
  {
    reference: "decimalNumbering",
    levels: [
      {
        level: 0,
        text: "%1. ",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertMillimetersToTwip(4),
              hanging: convertMillimetersToTwip(4),
            },
          },
        },
      },
      {
        level: 1,
        text: "%1.%2",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertMillimetersToTwip(10),
              hanging: convertMillimetersToTwip(6),
            },
          },
        },
      },
      {
        level: 2,
        text: "%1.%2.%3",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertMillimetersToTwip(19),
              hanging: convertMillimetersToTwip(9),
            },
          },
        },
      },
    ],
  },
  {
    reference: "decimalNumberingHeaders",
    levels: [
      {
        level: 0,
        text: "%1)",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.5),
              hanging: convertInchesToTwip(0.25),
            },
          },
        },
      },
      {
        level: 1,
        text: "%1.%2)",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(1),
              hanging: convertInchesToTwip(0.5),
            },
          },
        },
      },
    ],
  },
  {
    reference: "lowerRomanNumbering",
    levels: [
      {
        level: 0,
        text: "(%1)",
        format: LevelFormat.LOWER_ROMAN,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.5),
              hanging: convertInchesToTwip(0.25),
            },
          },
        },
      },
    ],
  },
  {
    reference: "decimalNumberingText",
    levels: [
      {
        level: 0,
        text: "",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
        },
      },
      {
        level: 1,
        text: "%1.%2",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertMillimetersToTwip(10),
              hanging: convertMillimetersToTwip(6),
            },
          },
        },
      },
      {
        level: 2,
        text: "%1.%2.%3",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertMillimetersToTwip(19),
              hanging: convertMillimetersToTwip(9),
            },
          },
        },
      },
    ],
  },
];

const DocStyles = {
  paragraphStyles: [
    {
      id: "headerContentWithNumbering",
      name: "header Content With Numbering",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
      },
    },
    {
      id: "headerContent",
      name: "header Content",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
      },
    },
    {
      id: "clauseContent",
      name: "clause Content",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
        numbering: {
          reference: "decimalNumbering",
          level: 0,
        },
      },
    },
    {
      id: "subclauseContent",
      name: "subclause Content",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        numbering: {
          reference: "decimalNumbering",
          level: 1,
        },
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
      },
    },
    {
      id: "childsContent",
      name: "child Content",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        numbering: {
          reference: "decimalNumbering",
          level: 2,
        },
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
      },
    },
    {
      id: "signatoriesBullets",
      name: "signatories Bullets",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
      },
    },
  ],
  default: {
    document: {
      run: { size: sizeText },
    },
  },
};

export const WordCreator = ({
  title,
  clauses,
  parts,
  headers,
  partHeader,
  message,
  numberingType,
  otherSignatories = [],
  selectedHeader,
  setIsLoading,
  mixPanelTrack,
}) => {
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState({});

  const generateDocx = async () => {
    try {
      if (mixPanelTrack) {
        const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
        Mixpanel.track(mixPanelTrack, {
          email: payloadToken?.email,
          companyName: payloadToken?.companyName,
        });
      }
      setIsLoading(true);
      const { isAllowDownload } = await validatePermissions();
      if (!isAllowDownload) {
        setIsOpenModalUnauthorized(true);
        setResponseDataUnauthorized({
          status: 401,
          data: {
            message:
              "No tiene los permisos necesarios para ejecutar esta acción.",
          },
        });
        return;
      }
      const finalTextParts = partHeader || "";
      const document = new Document({
        // creator: creator,
        numbering: {
          config: configDocument,
        },
        title: title,
        styles: DocStyles,
        sections: sectionsDocument({
          title,
          clauses,
          parts,
          headers,
          finalTextParts,
          numberingType,
          otherSignatories,
        }),
      });
      if (!selectedHeader) {
        Packer.toBlob(document).then((blob) => {
          saveAs(blob, title + ".docx");
        });
      } else {
        Packer.toBlob(document).then(async (blob) => {
          const obj = {
            file: blob,
            ...(selectedHeader.documentDocxId && {
              documentDocxTemplate: selectedHeader.documentDocxId._id,
            }),
            documentPdfTemplate: selectedHeader.documentPdfId._id,
            originalname: title + ".docx",
            contentType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          };

          setIsLoading(true);
          const response = await previewHeadingDocument(obj);
          if (
            response.status === CODES.COD_RESPONSE_HTTP_OK &&
            response.data.success
          ) {
            handleDownloadDocument(
              response.data.responseMessage.data,
              false,
              title + ".docx"
            );
          }
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadDocument = async (actualDocument, isWord, name) => {
    try {
      const file = await getFileDocumentForExternal(
        actualDocument.Path,
        actualDocument.BucketName,
        isWord
      );
      setIsLoading(false);
      const bf = Buffer.from(file.data.responseMessage.buffer.data);

      const blob = new Blob([new Uint8Array(bf).buffer]);
      saveAs(blob, name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        startIcon={<FileDownloadOutlined />}
        variant="contained"
        className={"custom-input__button__primary-color"}
        size="medium"
        onClick={() => generateDocx()}
      >
        {message}
      </Button>
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </>
  );
};

const clausesList = ({ clauses, numberingType }) =>
  clauses
    .map((item, idx) => {
      const arr = [];
      const subclauses = item?.clause?.subclauses || item?.subclauses || [];
      const paragraphs = item?.clause?.paragraphs || item?.paragraphs || [];
      arr.push(
        clauseContent(
          item?.clause?.title || item?.title || "Sin título",
          idx,
          item?.clause?.text || item?.text || "",
          numberingType
        )
      );
      subclauses?.forEach((element) => {
        arr.push(
          subclauseContent(
            element.subclause.text,
            element.subclause.title,
            numberingType
          )
        );
        if (element.subclause.childs.length > 0) {
          element.subclause.childs.forEach((child) => {
            arr.push(childsContent(child.text, child?.title, numberingType));
          });
        }
      });

      paragraphs?.forEach((element, idx) => {
        const text = element?.paragraph?.text || "";
        arr.push(paragraphContent(text, idx, numberingType));
      });
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);

const breakLine = () => {
  return new Paragraph({
    children: [new TextRun({ size: sizeText })],
  });
};

const principalDocTitle = (text) => {
  return new Paragraph({
    heading: HeadingLevel.TITLE,
    alignment: AlignmentType.CENTER,
    children: [textDoc(text.toUpperCase(), fontFamily, sizeText, true)],
  });
};

const docTitle = (text) => {
  return new Paragraph({
    heading: HeadingLevel.TITLE,
    alignment: AlignmentType.CENTER,
    numbering: {
      reference: "romanNumbering",
      level: 0,
      instance: 2,
    },
    children: [textDoc(text.toUpperCase(), fontFamily, sizeText, true)],
  });
};

const textDoc = (text, font, size, bold, colorhighlighted) => {
  return new TextRun({
    text: text,
    font: font,
    size: size,
    bold: bold,
    highlight: colorhighlighted,
  });
};

const paragraphDoc = (text) => {
  return new Paragraph({
    alignment: AlignmentType.LEFT,
    children: [textDoc(text, fontFamily, sizeText, false)],
  });
};

const identifyBlankSpaces = (blankspaces) => {
  const blankspacesArray = [];

  for (const [index, blankspace] of blankspaces.entries()) {
    if (index % 2 !== 0) {
      if (blankspace.includes("ESPACIO #")) {
        blankspacesArray.push(
          textDoc(
            `${blankspace}`,
            fontFamily,
            sizeText,
            false,
            colorhighlighted
          )
        );
      } else {
        blankspacesArray.push(
          textDoc(`${blankspace}`, fontFamily, sizeText, true)
        );
      }
    } else {
      blankspacesArray.push(
        textDoc(`${blankspace}`, fontFamily, sizeText, false)
      );
    }
  }

  return blankspacesArray;
};

const isTableInClause = (textHtml) => {
  const parser = new DOMParser();
  const htmlTableElement = parser
    .parseFromString(textHtml, "text/html")
    .querySelector(".contract_table");
  return !!htmlTableElement;
};

const NUMBER_TYPE = {
  number: "decimalNumbering",
  letter: "decimalNumberingText",
};

const indentifyJumpLine = (textHtml, level, indentTable, numberingType) => {
  const referenceDefault = NUMBER_TYPE[numberingType];
  const textHtmlWithJumpLines = textHtml.replace(
    /<br\s*\/?>\s*<br\s*\/?>/gi,
    "<p><br></p>"
  );
  const textArray = textHtmlWithJumpLines
    .split(/<p>(.*?)<\/p>/gi)
    .filter((item) => item);

  let resultText = [];

  for (const [index, text] of textArray.entries()) {
    if (isTableInClause(text)) {
      const buildTable = createTable(text, indentTable);
      resultText.push(buildTable);
    } else {
      let textResult = text;
      let styleParagraph = {};

      let numbering;

      if (index === 0 && level !== 3) {
        numbering = {
          reference: referenceDefault,
          level,
        };
      }

      if (index !== 0 && level !== 3) {
        styleParagraph = {
          indent: {
            left: convertInchesToTwip(level / 2),
          },
        };
      }

      textResult = textResult.replace(regexDeleteHtmlTags, "");
      textResult = textResult.replace(/°/g, "");
      textResult = textResult.replace(/((&nbsp;))/gim, " ");
      textResult = textResult.replace(/\s+/g, " ");
      const blankspacesClauseText = textResult.split("¬");

      const blankspacesArray = identifyBlankSpaces(blankspacesClauseText);

      textResult = textResult.replace(/¬/g, "");

      let children = [textDoc(textResult, fontFamily, sizeText, false)];

      if (blankspacesClauseText.length >= 2) {
        children = [...blankspacesArray];
      }
      resultText.push(
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          numbering,
          ...styleParagraph,
          children,
          spacing: {
            line: 276,
          },
        })
      );
    }
  }

  return resultText;
};

const clauseContent = (title, index, clauseText, numberingType) => {
  const indentTable = numberingType === "number" ? 230 : 0;

  const result = indentifyJumpLine(clauseText, 0, indentTable, numberingType);

  return new Paragraph({
    children: [
      textDoc(
        numberingType === "number"
          ? `${title}: `
          : `Cláusula ${numberingWordsA[index + 1]} - ${title}: `,
        fontFamily,
        sizeText,
        true
      ),
      ...result,
    ],
  });
};

const subclauseContent = (subclauseText, title, numberingType) => {
  const result = indentifyJumpLine(subclauseText, 1, 570, numberingType);

  return new Paragraph({
    children: [
      textDoc(` ${title ? title + ": " : ""}`, fontFamily, sizeText, true),
      ...result,
    ],
  });
};

const paragraphContent = (paragraphText, index, numberingType) => {
  const result = indentifyJumpLine(paragraphText, 3, 0, numberingType);

  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    children: [
      textDoc(
        "Parágrafo " + numberingWordsO[index + 1] + ": ",
        fontFamily,
        sizeText,
        true
      ),
      ...result,
    ],
  });
};

const childsContent = (childText, title, numberingType) => {
  const result = indentifyJumpLine(childText, 2, 1075, numberingType);

  return new Paragraph({
    children: [
      textDoc(` ${title ? title + ": " : ""}`, fontFamily, sizeText, true),
      ...result,
    ],
  });
};

const signatoriesBullets = (item, instance) => {
  item = item.replaceAll(/¬/g, "");
  return new Paragraph({
    numbering: {
      reference: "lowerRomanNumbering",
      level: 0,
      instance: instance,
    },
    style: "signatoriesBullets",
    children: [textDoc(item, fontFamily, sizeText, false)],
  });
};

const partsContent = (part) => {
  return new Paragraph({
    alignment: AlignmentType.LEFT,
    children: [textDoc(part.name + ":", fontFamily, sizeText, true)],
  });
};

const finalTextPart = (text) => {
  text = text.replaceAll(/¬/g, "");
  text = text.replace(regexDeleteHtmlTags, "");
  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    children: [textDoc(text, fontFamily, sizeText, false)],
  });
};

const signatoriesContent = (signatories, instance) => {
  return signatories
    .map((item) => {
      const arr = [];
      let text = item?.text?.replace(/\s+/g, " ");
      text = text.replace(regexDeleteHtmlTags, "");
      arr.push(signatoriesBullets(text, instance));
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

const signatureTitle = (signature) => {
  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    children: [textDoc(`${signature},`, fontFamily, sizeText, false)],
  });
};

const signatureList = (part) => {
  return part.flatMap((item) => {
    return item.signatories.flatMap((signatory) => {
      const arr = [];

      arr.push(paragraphDoc("_________________________________________"));
      arr.push(paragraphDoc(signatory?.blankspaces[0]?.value || "Espacio #1"));
      arr.push(paragraphDoc(signatory?.blankspaces[1]?.value || "Espacio #2"));
      const id = `${signatory?.blankspaces[2]?.value || "Espacio #3"} ${
        signatory?.blankspaces[3]?.value || "Espacio #4"
      }`;
      arr.push(paragraphDoc(id));
      arr.push(breakLine(), breakLine());

      return arr;
    });
  });
};

const otherSignatureList = (signatoriesList) => {
  return signatoriesList
    .map((signatories) => {
      const arr = [];
      arr.push(paragraphDoc("_________________________________________"));

      arr.push(
        paragraphDoc(signatories?.blankspaces[1]?.value || "Espacio #2")
      );
      const id = `${signatories?.blankspaces[2]?.value || "Espacio #3"} ${
        signatories?.blankspaces[3]?.value || "Espacio #4"
      }`;
      arr.push(paragraphDoc(id));
      arr.push(breakLine(), breakLine());
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

const otherSignatoriesList = (others) => {
  if (!others.length) return [];
  return others
    .map((item) => {
      const arr = [];
      arr.push(
        signatureTitle(item?.blankspaces[0]?.value || "Espacio #1"),
        breakLine(),
        breakLine(),
        ...otherSignatureList([item])
      );
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

const getPartTransform = (part, instance) => {
  if (!part) return [];

  return [
    partsContent(part),
    breakLine(),
    ...signatoriesContent(part?.parts || [], instance),
    breakLine(),
    breakLine(),
  ];
};

const getSignatureListTransform = (part) => {
  if (!part) return [];
  return [
    signatureTitle(part?.name || ""),
    breakLine(),
    breakLine(),
    ...signatureList(part?.parts || []),
  ];
};

const getTable = (htmlTable) => {
  const parser = new DOMParser();
  const htmlTableElement = parser
    .parseFromString(htmlTable, "text/html")
    .querySelector(".contract_table");

  // Extraer la información de la tabla
  const rows = htmlTableElement.querySelectorAll("tr");
  const tableData = [];

  const findBlankSpace = (text) => {
    let textResult = text.replace(/¬/g, "");
    const blankspacesClauseText = textResult.includes("ESPACIO #");
    let textColorLight = null;
    textColorLight = textDoc(
      `${textResult.trim()}`,
      fontFamily,
      sizeText,
      false,
      blankspacesClauseText ? colorhighlighted : null
    );
    return textColorLight;
  };

  rows.forEach((row) => {
    const cells = row.querySelectorAll("td");
    const headers = row.querySelectorAll("th");
    const rowData = [];
    if (headers.length) {
      headers.forEach((cell) => {
        const colspan = cell.attributes.getNamedItem("colspan")?.value;
        const rowspan = cell.attributes.getNamedItem("rowspan")?.value;
        const data = {
          colspan,
          content: findBlankSpace(cell.innerText),
          rowspan,
        };
        rowData.push(data);
      });
      tableData.push({ header: true, rowData });
    } else if (cells.length) {
      cells.forEach((cell) => {
        const colspan = cell.attributes.getNamedItem("colspan")?.value;
        const rowspan = cell.attributes.getNamedItem("rowspan")?.value;
        const data = {
          colspan,
          content: findBlankSpace(cell.innerText),
          rowspan,
        };
        rowData.push(data);
      });
      tableData.push({ header: false, rowData });
    }
  });

  return tableData;
};

const createTable = (htmlTable, indent) => {
  const tableData = getTable(htmlTable);
  return new Table({
    indent: {
      size: indent,
      type: WidthType.DXA,
    },
    rows: tableData.map(({ header, rowData }) => {
      return new TableRow({
        tableHeader: header,
        children: rowData.map((cellData) => {
          return new TableCell({
            children: [new Paragraph({ children: [cellData.content] })],
            margins: {
              top: convertInchesToTwip(0.15),
              bottom: convertInchesToTwip(0.15),
              left: convertInchesToTwip(0.15),
              right: convertInchesToTwip(0.15),
            },
            columnSpan: cellData?.colspan,
            rowSpan: cellData?.rowspan,
            shading: {
              fill: header ? "#A3A3A3" : "auto",
              color: "auto",
            },
          });
        }),
      });
    }),
  });
};

const sectionsDocument = ({
  title,
  clauses,
  parts,
  headers,
  numberingType,
  otherSignatories,
}) => {
  return [
    {
      properties: {},
      children: [
        principalDocTitle(title || ""),
        breakLine(),
        //Parts
        docTitle("PARTES"),
        breakLine(),
        ...getPartTransform(parts[0] || parts["partA"], 1),
        ...getPartTransform(parts[1] || parts["partB"], 2),
        ...getPartTransform(parts[2] || parts["partC"], 3),
        ...getPartTransform(parts[3] || parts["partD"], 4),
        // finalTextPart(finalTextParts),

        //CLAUSES
        docTitle("CLÁUSULAS"),
        breakLine(),
        breakLine(),
        ...clausesList({ clauses, numberingType }),
        breakLine(),
        breakLine(),
        breakLine(),
        //SIGNATURES
        ...getSignatureListTransform(parts[0] || parts["partA"]),
        ...getSignatureListTransform(parts[1] || parts["partB"]),
        ...getSignatureListTransform(parts[2] || parts["partC"]),
        ...getSignatureListTransform(parts[3] || parts["partD"]),
        ...otherSignatoriesList(otherSignatories),
      ],
    },
  ];
};

const indentifyJumpLinePreview = (textHtml) => {
  const textArray = textHtml.split(/<p>(.*?)<\/p>/gi).filter((item) => item);

  let resultText = [];

  for (const text of textArray) {
    let textResult = text;

    textResult = textResult.replace(regexDeleteHtmlTags, "");
    textResult = textResult.replace(/°/g, "");
    textResult = textResult.replace(/((&nbsp;))/gim, " ");

    const blankspacesClauseText = textResult.split("¬");

    const blankspacesArray = identifyBlankSpaces(blankspacesClauseText);

    textResult = textResult.replace(/¬/g, "");

    if (blankspacesClauseText.length >= 2) {
      resultText.push(...blankspacesArray);
    } else {
      resultText.push(textDoc(textResult, fontFamily, sizeText, false));
    }
  }

  return resultText;
};

const clauseContentPreview = (title, index, clauseText, numberingType) => {
  const result = indentifyJumpLinePreview(clauseText);
  const referenceDefault =
    numberingType === "number" ? "decimalNumbering" : "decimalNumberingText";

  return new Paragraph({
    style: "clauseContent",
    numbering: {
      reference: referenceDefault,
    },
    children: [
      textDoc(
        numberingType === "number"
          ? `${title}: `
          : `Cláusula ${numberingWordsA[index + 1]} - ${title}: `,
        fontFamily,
        sizeText,
        true
      ),
      ...result,
    ],
  });
};

const subclauseContentPreview = (subclauseText, title, numberingType) => {
  const result = indentifyJumpLinePreview(subclauseText);
  const referenceDefault =
    numberingType === "number" ? "decimalNumbering" : "decimalNumberingText";
  return new Paragraph({
    style: "subclauseContent",
    numbering: {
      reference: referenceDefault,
      level: 1,
    },
    children: [
      textDoc(` ${title ? title + ": " : ""}`, fontFamily, sizeText, true),
      ...result,
    ],
  });
};

const paragraphContentPreview = (paragraphText, index) => {
  const result = indentifyJumpLinePreview(paragraphText);

  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    children: [
      textDoc(
        "Parágrafo " + numberingWordsO[index + 1] + ": ",
        fontFamily,
        sizeText,
        true
      ),
      ...result,
    ],
  });
};

const childsContentPreview = (childText, title, numberingType) => {
  const result = indentifyJumpLinePreview(childText);
  const referenceDefault =
    numberingType === "number" ? "decimalNumbering" : "decimalNumberingText";

  return new Paragraph({
    style: "childsContent",
    numbering: {
      reference: referenceDefault,
      level: 2,
    },
    children: [
      textDoc(` ${title ? title + ": " : ""}`, fontFamily, sizeText, true),
      ...result,
    ],
  });
};

const clausesListPreview = ({ clauses, numberingType }) =>
  clauses
    .map((item, idx) => {
      const arr = [];
      const subclauses = item?.clause?.subclauses || item?.subclauses || [];
      const paragraphs = item?.clause?.paragraphs || item?.paragraphs || [];
      arr.push(
        clauseContentPreview(
          item?.clause?.title || item?.title || "",
          idx,
          item?.clause?.text || item?.text || "",
          numberingType
        ),
        breakLine()
      );
      subclauses.forEach((element) => {
        const title = `${
          element?.subclause?.title?.trim() || item?.title?.trim() || ""
        }`;
        arr.push(
          subclauseContentPreview(
            element?.subclause?.text || item?.text || "",
            title,
            numberingType
          ),
          breakLine()
        );
        if (element.subclause.childs && element.subclause.childs.length > 0) {
          element.subclause.childs.forEach((child) => {
            const text = child?.text || "";
            const title = `${child?.title?.trim() || ""}`;
            arr.push(
              childsContentPreview(text, title, numberingType),
              breakLine()
            );
          });
        }
      });

      paragraphs.forEach((element, idx) => {
        const text = element?.paragraph?.text || element?.text || "";
        arr.push(paragraphContentPreview(text, idx), breakLine());
      });
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);

const sectionsDocumentPreview = ({
  title,
  clauses,
  parts,
  headers,
  finalTextParts,
  numberingType,
}) => {
  return [
    {
      properties: {},
      children: [
        principalDocTitle(title || ""),
        breakLine(),
        //Parts
        docTitle("PARTES"),
        breakLine(),
        ...getPartTransform(parts[0] || parts["partA"], 1),
        ...getPartTransform(parts[1] || parts["partB"], 2),
        ...getPartTransform(parts[2] || parts["partC"], 3),
        ...getPartTransform(parts[3] || parts["partD"], 4),

        //CLAUSES
        docTitle("CLÁUSULAS"),
        breakLine(),
        breakLine(),
        breakLine(),
        ...clausesListPreview({ clauses, numberingType }),
        breakLine(),
        breakLine(),
        breakLine(),
        breakLine(),
        //SIGNATURES
        ...getSignatureListTransform(parts[0] || parts["partA"]),
        ...getSignatureListTransform(parts[1] || parts["partB"]),
        ...getSignatureListTransform(parts[2] || parts["partC"]),
        ...getSignatureListTransform(parts[3] || parts["partD"]),
      ],
    },
  ];
};

export const wordCreatorFile = ({
  title,
  clauses,
  parts,
  partHeader,
  headers,
  numberingType,
  otherSignatories = [],
  selectedHeader,
}) => {
  const generateDocx = () => {
    const finalTextParts = partHeader || "";
    const document = new Document({
      // creator: creator,
      numbering: {
        config: configDocument,
      },
      title: title,
      styles: DocStyles,
      sections: sectionsDocument({
        title,
        clauses,
        parts,
        headers,
        finalTextParts,
        numberingType,
        otherSignatories,
      }),
    });
    const file = Packer.toBlob(document).then((blob) => blob);
    return file;
  };

  const file = generateDocx();

  return file;
};
