import axios from "axios";
import { env } from "../../env";
import GetToken from "../../utils/getToken";

export const getUser = async (email) => {
  try {
    const authenticator = await axios.get(
      `${env.REACT_APP_API_URL}/users/userCreatedByAnAdmin/${email}`
    );
    return authenticator.data;
  } catch (error) {
    console.log(error);
  }
};

export function userLogin(data) {
  return axios({
    method: "post",
    url: "users/login",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      email: data.email,
      password: data.password,
      role: data.role,
      code: data.code,
      path: data.path,
      token: data.token,
    },
  });
}

export function ForgotPasswordService(data) {
  return axios({
    method: "POST",
    url: `users/forgotPassword`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
  });
}

export function ForgotPasswordConfirmationService(data) {
  return axios({
    method: "post",
    url: "users/confirmForgotPassword",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      email: data.email,
      password: data.password,
      confirmationCode: data.confirmationCode,
    },
  });
}

export function refreshToken(data) {
  return axios({
    method: "post",
    url: "users/refreshToken",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      email: data.email,
      refreshToken: data.refreshToken,
    },
  });
}

export function Logout({ accessToken, refreshToken, email }) {
  return axios({
    method: "POST",
    url: "users/logout",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      email,
    },
    headers: {
      Authorization: accessToken || GetToken(),
      refreshToken,
    },
  });
}

export function LoginServiceADFS({ role, code, redirectUri }) {
  return axios({
    method: "POST",
    url: "users/loginFederatedIdentities",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      role,
      code,
      redirectUri,
    },
  });
}

export function GetIdentityProvider(domain) {
  return axios({
    method: "get",
    url: `users/getIdentityProvider`,
    params: { domain },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
  });
}
